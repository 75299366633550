// TODO: Ask scott where to put these.
if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    const matches = (this.document || this.ownerDocument).querySelectorAll(s)
    let i
    let el = this
    do {
      i = matches.length
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement))
    return el
  }
}

export const addCSSFileToDoc = (doc, file) => {
  // Get HTML head element
  const head = doc.getElementsByTagName('HEAD')[0]

  // Create new link Element
  const link = doc.createElement('link')

  // set the attributes for link element
  link.rel = 'stylesheet'
  link.type = 'text/css'
  link.href = file

  // Append link element to HTML head
  head.appendChild(link)
}

const isDomParserSupported = (function () {
  if (!window.DOMParser) return false
  const parser = new DOMParser()
  try {
    parser.parseFromString('x', 'text/html')
  } catch (err) {
    return false
  }
  return true
})()

/**
 * Convert a template string into HTML DOM nodes
 * @param  {String} str The template string
 * @return {Node}       The template HTML
 */
export const stringToHTML = (str) => {
  if (str) {
    if (isDomParserSupported) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(str, 'text/html')
      return doc.body
    }

    // Otherwise, fallback to old-school method
    const dom = document.createElement('div')
    dom.innerHTML = str
    return dom
  }
  else {
    return null
  }
}
