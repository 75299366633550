import { kolDebugTable } from "./debugging";
import { kolDebug as debug } from "../utils/debugging";

const ResponseException = function (response, message) {
  this.response = response;
  this.message = message;
  this.error = true;
};

const handleErrors = (response, x, y) => {
  if (!response.ok) {
    const errorMessage = `${response.statusText} (${response.status})`;
    console.error(errorMessage);
    throw new ResponseException(response, errorMessage);
  }
  return response;
};

const logOutput = (response) => {
  kolDebugTable(response);
  return response;
};

const appendQueryStringToUrl = (url, data = {}) => {
  const newUrl = new URL(url);
  for (const key in data) {
    const item = data[key];
    if (item) {
      newUrl.searchParams.append(key, item);
    }
  }
  return newUrl.toString();
};

export function postData(url, data) {
  debug(`POST URL: ${url}`);
  debug(`POST Params`, data);
  return fetch(url, {
    body: JSON.stringify(data), // must match 'Content-Type' header
    cache: "no-cache",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
    },
    method: "POST",
    mode: "cors",
  })
    .then(logOutput)
    .then(handleErrors)
    .then((response) => response.json())
    .then(logOutput);
}

export const extractFormFields = (elements) =>
  [].reduce.call(
    elements,
    (data, element) => {
      if (
        element.name &&
        element.name !== "" &&
        element.value &&
        element.value !== ""
      ) {
        data[element.name] = element.value; // eslint-disable-line no-param-reassign
      }
      return data;
    },
    {}
  );

export function getData(url, data = {}, mode = "cors") {
  debug(`GET URL: ${url}`);
  debug(`GET Data:`, data);

  const newUrl = appendQueryStringToUrl(url, data);
  debug(`GET URL with Data`, newUrl);

  return fetch(newUrl, {
    cache: "no-cache",
    headers: {
      "content-type": "application/json",
      accept: "application/json",
    },
    method: "GET",
    mode: mode,
  })
    .then(logOutput)
    .then(handleErrors)
    .then((response) => response.json())
    .then(logOutput);
}

export const minimumCacheTime = (cacheSeconds = 0) => {
  return Math.max(5, cacheSeconds || 0);
};
