import { kolDebug as debug } from './../utils/debugging'

// .kol-no-lead-found = elements to show when no lead is found
// .kol-lead-found = elements to show when lead is found

// TODO
// .kol-hidden .kol-lead-show  // DONE
// .kol-lead-hide // DONE
// .kol-hidden .kol-lead-not-verified-show
// .kol-hidden .kol-lead-verified:show //DONE
// .kol-hidden .kol-share-lead-show

// .kol-lead-anonymous = elements to show when lead is anonymous
//
//

export default class KOLLeadHideShowClasses {
  constructor (writeDoc) {
    this.doc = writeDoc
    const myself = this
    this._handleLead = function () {
      if (!_kol.lead) {
        myself.noLeadDisplay()
        return
      }
      if (myself.doc && myself.doc.body) {
        myself.doc.body.classList.remove('kol-lead-anonymous-true', 'kol-lead-anonymous-false')
        myself.doc.body.classList.add('kol-lead-anonymous-' + _kol.lead.anonymous)
      }
      if (_kol.lead.anonymous == true) {
        myself.noLeadDisplay()
      } else {
        myself.withLeadDisplay()
      }
    }

    this._handleShareLead = function () {
      if (_kol.shareLead) {
        myself.withShareLeadDisplay()
      }
    }

    this._handleShareLead()
    this._handleLead()
    // setup event handlers for activities.
    //
    this.setupEventListeners()
  }

  noLeadDisplay () {
    for (const el of this.doc.querySelectorAll('.kol-no-lead-found, .kol-display-all, .kol-lead-hide')) { this.showElement(el) }
    for (const el of this.doc.querySelectorAll('.kol-lead-found, .kol-lead-show, .kol-lead-unverified-show , .kol-lead-verified-show')) { el.style.display = 'none' }
  }

  showElement (el) {
    if (el.classList.contains('is-section')) {
      el.style.display = 'table'
    } else {
      el.style.display = 'block'
    }
  }

  withLeadDisplay () {
    for (const el of this.doc.querySelectorAll('.kol-no-lead-found, .kol-lead-hide')) { el.style.display = 'none' }
    for (const el of this.doc.querySelectorAll('.kol-lead-found, .kol-display-all, .kol-lead-show')) {
      this.showElement(el)
    }
    if (_kol.lead.verified == true) {
      for (const el of this.doc.querySelectorAll('.kol-lead-verified-show')) {
        this.showElement(el)
      }
      for (const el of this.doc.querySelectorAll('.kol-lead-unverified-show')) {
        el.style.display = 'none'
      }
    } else {
      for (const el of this.doc.querySelectorAll('.kol-lead-unverified-show')) {
        this.showElement(el)
      }
      for (const el of this.doc.querySelectorAll('.kol-lead-verified-show')) {
        el.style.display = 'none'
      }
    }
  }

  withShareLeadDisplay () {
    for (const el of this.doc.querySelectorAll('.kol-share-lead-show')) {
      this.showElement(el)
    }
  }

  setupEventListeners () {
    debug('KOLLeadHideShowClasses > setupEventListeners')
    window._kol.container.removeEventListener(
      'kol:leadchange',
      this._handleLead,
      true
    )
    window._kol.container.addEventListener('kol:leadchange', this._handleLead)

    window._kol.container.removeEventListener('kol:knownsharelead', this._handleShareLead, true)
    window._kol.container.addEventListener('kol:knownsharelead', this._handleShareLead)

    window._kol.container.removeEventListener('kol:unknownlead', this._handleLead, true)
    window._kol.container.addEventListener('kol:unknownlead', this._handleLead)

    window._kol.container.removeEventListener('kol:knownlead', this._handleLead, true)
    window._kol.container.addEventListener('kol:knownlead', this._handleLead)
  }
}
