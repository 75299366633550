import { extend, extendOverwrite, tryColor } from './../utils/objects'
import KOLFormBuilder from './../templates/formbuilder'
import KOLStrings from './../utils/strings'

export default class KOLOneTemplates {
  getEmbedLoadingSpinner (options) {
    return `
      <div id="kol-embed-spinner" class="kol-embed-load-spinner">
      <style>
      .kol-embed-load-spinner{
        height: 100%;
        width: 100%;
        border:1px solid ${options.borderColor};
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: white;
      }
      .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-ripple div {
        position: absolute;
        border: 4px solid ${options.spinColor};
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
      }
      @keyframes lds-ripple {
        0% {
          top: 36px;
          left: 36px;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 0px;
          left: 0px;
          width: 72px;
          height: 72px;
          opacity: 0;
        }
      }
    </style>
    <div class="lds-ripple"><div></div><div></div></div>
    </div>
    `
  }

  getLoadingSpinner (options, id) {
    return `
      <div id="${id}" class="kol-box-load-spinner">
      <style>
      .kol-box-load-spinner{
        position: absolute;
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background: white;
      }
      .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-ripple div {
        position: absolute;
        border: 4px solid ${options.colors_primaryColor};
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
      }
      @keyframes lds-ripple {
        0% {
          top: 36px;
          left: 36px;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 0px;
          left: 0px;
          width: 72px;
          height: 72px;
          opacity: 0;
        }
      }
    </style>
    <div class="lds-ripple"><div></div><div></div></div>
    </div>
    `
  }

  getFormHTML (formData, pageId, showFormLabels) {
    const formBuilder = new KOLFormBuilder({
      form_elements: formData,
      page_id: pageId
    })

    return formBuilder.render_inline()
  }

  getFooterCountdownColumn (widgetOptions) {
    if (widgetOptions.display_countdown) {
      return `                 <div class="flex-1"><div class="footer-number-wrapper">
              <div id="kol-footer-countdown" class="kol-countdown-container minimal" data-kol-snippet='countdown' data-layout='oneSignificantTime'>

              </div></div>
  </div> `
    }
    return ''
  }

  getFooterTotalLeadsColumn (widgetOptions) {
    if (widgetOptions.display_totalLeads) {
      return ` <div class="flex-1"><div class="footer-number-wrapper">
          <div id="total-points" class="footer-number"><span class="kol-data-replace" data-replace-with-lead-data="lead_count">-</span></div><div class="footer-number-description">${widgetOptions.content_totalPointsText}</div>
        </div></div>`
    }
    return ''
  }

  getFooterRankColumn (widgetOptions) {
    if (widgetOptions.display_rank) {
      return ` <div class="flex-1">
        <div class="footer-number-wrapper">
          <div id="kol-footer-rank" class="footer-number">
            <span class="kol-data-replace" data-replace-with-lead-data="contest_score_rank">-</span>
          </div>
        <div class="footer-number-description">${_kol.options.localization.rankWord}</div>
        </div>
        </div>`
    }
    return ''
  }

  getFooterLeadPoints (widgetOptions) {
    if (widgetOptions.display_leadPoints) {
      return `<div class="flex-1"><div class="footer-number-wrapper"><div id="current-points" class="footer-number"><span class="kol-data-replace" data-replace-with-lead-data="contest_score">-</span></div><div class="footer-number-description"> ${KOLStrings.getPointsWord(
        _kol.lead ? _kol.lead.contest_score : 0
      )}</div></div>

          </div>
      `
    }
    return ''
  }

  getFooterPointsToNextRewardColumn (widgetOptions) {
    
    if (widgetOptions.display_pointsToNextReward) {
      return ` <div class="flex-1"><div class="footer-number-wrapper">
          <div id="kol-next-reward-number" class="footer-number"><span class="kol-data-replace" data-replace-with-lead-data="points_to_next_reward">-</span></div><div class="footer-number-description">${
        widgetOptions.content_unlockNextRewardText
      }</div></div>
        </div>`
    }
    return ''
  }

  getSignupPointText () {
    if (_kol.options.scoring && _kol.options.scoring.points_jump_start > 0) {
      return `<span class="label label-points">+ ${_kol.options.scoring.points_jump_start}</span>`
    }
    return ''
  }

  getLeaderBoard (widgetOptions) {
    if (widgetOptions.display_leaderboard) {
      return `            <div class="row is-no-col-resizing" style="margin-top:20px;">
          <div class="col-md-12">
            <div id="kol-contestbox-leaderboard" data-kol-snippet="leaderboard"  class="kol-leaderboard default" data-leaderboard='{"leadLimit":"5", "template":"minimal_avatar_compact_template"}' >
            </div>
          </div>
        </div>
      `
    }
    return ''
  }

  getTopOfBoxWithCTAText (widgetOptions) {
    if (widgetOptions.content_topOfBoxText || widgetOptions.content_aboveHeroText) {
      return ` <div class="kol-widget-hero-top is-align-center  is-light-text  " style=" background-color: ${
              widgetOptions.colors_primaryColor
            };">
            <div class="is-container ">
              <div class="row " style="margin: 0px -15px 0px; padding: 5px 0px; 5px;">
                <div class="col-sm-12 kol-widget-hero-top-text"><p><span style="font-size: 18px;">${
                        widgetOptions.content_topOfBoxText || widgetOptions.content_aboveHeroText
                      }</span></p>
                </div>
              </div>
            </div>
          </div>`
    }
    return ''
  }

  getformCallToAction (widgetOptions) {
    if (widgetOptions.content_formCallToAction) {
      return `          <div class="row kol-no-lead-found" style=" margin: 10px -15px 0px;">
                      <div class="col-sm-12 " role="application"><p class="kol-section-headline-text">${
                        widgetOptions.content_formCallToAction
                      }${this.getSignupPointText()}</p></div>
          </div>`
    }
    return ''
  }

  getHeroImage (widgetOptions) {
    if (widgetOptions.content_heroImage && widgetOptions.template !== 'horizontal') {
      return `<div id="kol-box-image" class="is-builder is-container-inner" style="zoom: 1;"><div class="row">
          <div class="col-sm-12 kol-hero-image-wrapper" style="padding-left:0px;padding-right:0px;" role="application"><img src="${
            widgetOptions.content_heroImage
          }" style="width: 100%;"></div>
        </div></div>`
    } else if (widgetOptions.content_heroImage) {
      return `<div id="kol-box-image" class="is-builder is-container-inner" style="zoom: 1; min-height: 250px; background-image: url('${
            widgetOptions.content_heroImage
          }');"> <div class="col-sm-12 kol-hero-image-wrapper" style="padding-left:0px;padding-right:0px;" role="application"></div></div>`
    } else {
      return ''
    }
  }

  getLeadWelcomeArea (widgetOptions) {
    if (widgetOptions.content_notificationSignedIn) {
      return `<div class="row kol-lead-found kol-lead-found-welcome  is-light-text" style="background-color: ${
            widgetOptions.colors_primaryColor
          }; margin: 0px -15px 0px; padding: 5px 0px 3px;display:none;">
             <div class="col-sm-12 fr-box kickoff-theme fr-inline fr-basic text-center"  role="application"><p style="margin-bottom:0px; color: #ffffff;"><span class='kol-lead-welcome-text'>${
               widgetOptions.content_notificationSignedIn
             }</span>   <span class="kol-data-replace"
                                  data-replace-with-lead-data="given_name"></span></p> </div>
          </div>`
    }
    return ''
  }

  getAboveActionsText (widgetOptions, showActivitiesClass) {
    if (widgetOptions.content_aboveActionsText) {
      return `<div class="row clearfix kol-paragraph ${showActivitiesClass}" id="kol-box-actions" style="margin: 10px -15px 0px;">
            <div class="col-md-12 " role="application"><p class="kol-section-headline-text">${
              widgetOptions.content_aboveActionsText
            }</p></div>
          </div>`
    }
    return ''
  }

  getAboveRewardsText (widgetOptions, showRewardsClass) {
    if (widgetOptions.content_aboveRewardsText) {
      return `<div class="row clearfix kol-paragraph ${showRewardsClass}" style="margin: 10px -15px 0px;">
            <div  class="col-md-12 " role="application"><p class="kol-section-headline-text">${
              widgetOptions.content_aboveRewardsText
            }</p></div>
          </div>`
    }
    return ''
  }

  showingFooterBar (widgetOptions) {
    if (widgetOptions.display_leadPoints || widgetOptions.display_pointsToNextReward || widgetOptions.display_rank || widgetOptions.display_countdown || widgetOptions.display_totalLeads) {return true} else { return false }
  }

  getiContestFrameContent (boxType) {
    // TODO CLeanup Default Options
    const defaultOptions = {
      colors_primaryColor: 'rgb(165, 147, 224)',
      colors_backgroundColor: 'rgb(240, 243, 246)',
      content_heroImage:
        '//d1y0v6ricksqp.cloudfront.net/images/backgrounds/pictures/heart-paper.jpg',
      display_powered_by: true,
      content_topOfBoxText: '', // defeaults to the above hero text.
      position: 'bottomright',
      content_notificationSignedIn: 'Hi',
      content_introduction: 'Introduce the contest here.',
      content_formCallToAction: 'Signup - Earn Points - Earn Rewards',
      content_aboveActionsText: '',
      content_aboveRewardsText: 'Rewards!',
      content_unlockNextRewardText: 'Unlock Next Reward',
      content_contestRulesText: 'Contest Rules',
      content_contestRules: '<p>Insert or link to great rules here.</p>',
      content_totalPointsText: 'Total Entries',
      content_button_text: 'Start Earning!',
      rewards_style: 'modern',
      colors_defaultFontColor: '#111111',
      font: 'Open+Sans',
      show_anonymous_actions: true,
      show_anonymous_rewards: true,
      show_anonymous_leaderboard: true,
      display_countdown: true,
      display_totalLeads: true,
      display_leadPoints: true,
      display_pointsToNextReward: true,
      form_data: [
        {
          name: 'first_name',
          showLabel: false,
          required: false,
          placeholder: 'First Name',
          options: [],
          type: 'text',
          label: 'First Name'
        },
        {
          name: 'email',
          required: true,
          placeholder: 'Email',
          options: [],
          type: 'email',
          label: 'Email',
          showLabel: false
        }
      ]
    }

    // set options to pass to the frame.

    const options = _kol.options

    const widgetOptions = extendOverwrite(
      {},
      defaultOptions,
      options.onPageActions.contestBox
    )

    // force remove recursion.
    // options["onPageActions"] = null;

    const formHTML = this.getFormHTML(widgetOptions.form_data, 'pageID', false)
    const customHtmlHead = widgetOptions.customHtmlHead
      ? widgetOptions.customHtmlHead
      : ''
    const showRewardsClass = options.rewards.length > 0 ? 'visible' : 'hidden'
    const showActivitiesClass = options.actions.length > 0 ? 'visible' : 'hidden'
    const darkerPrimaryColor = tryColor(
      widgetOptions.colors_primaryColor,
      'rgb(165, 147, 224)'
    )
      .darken(0.1)
      .hex()
    const transparentTextColor = tryColor(
      widgetOptions.colors_defaultFontColor,
      '#111111'
    )
      .fade(0.75)

    const transparentTextColorLighter = tryColor(
      transparentTextColor,
      '#111111'
    )
      .fade(0.5)  
    /*
     *  To make this I...
     * - Created a rough landing page.
     * - Copied it here.
     * - Used the replacement variables in the template
     * - Changed styles that needed adjusting like the fixed style of the footer.
     * - Removed froala z-index:  style="z-index: 800;"
     * - Replace HTML built by script on the page with defaults from snippets5.html file for that snippet.
     *
     */
    return `
      <!DOCTYPE html>
      <html xmlns="http://www.w3.org/1999/xhtml">
        <head>   
          <base target="_parent">
          <meta name="generator" content="KickoffLabs.com - Viral Contests Made Easy">
          <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
          <!-- Loading custom fonts -->
          <link href='https://fonts.googleapis.com/css?family=${widgetOptions.font}' rel='stylesheet' type='text/css'>
          <link href="https://ka-p.fontawesome.com/releases/v6.4.2/css/pro.min.css?token=413baa29c3" rel='stylesheet' type='text/css'>
          <link href="https://d1y0v6ricksqp.cloudfront.net/css/bootstrap/3.4.0_simple/bootstrap.min.css" rel="stylesheet" type="text/css">
          <link href="${
            options.cssPath
          }" media="screen" rel="stylesheet" type="text/css">
    
          <style >
            h1,h2,h3,h4,h5,h6 { 
              font-family: '${widgetOptions.font.replace(/\+/g, ' ')}', Helvetica, Arial, sans-serif;
              font-weight: 700;
            }

            body, p, input { 
              font-family: '${widgetOptions.font.replace(/\+/g, ' ')}', Helvetica, Arial, sans-serif;
              font-size: 14px;
            }

            body, p, .is-default-text, .is-default-text h1, .is-default-text h2, .is-default-text h3, .is-default-text, h4, .is-default-text h5, .is-default-text h6, .is-default-text p, .is-default-text li { color: ${widgetOptions.colors_defaultFontColor}; }

            body, p, .is-dark-text, .is-dark-text h1, .is-dark-text h2, .is-dark-text h3, .is-dark-text, h4, .is-dark-text h5, .is-dark-text h6, .is-dark-text p, .is-dark-text li { color: #111111; }


            a { color: ${widgetOptions.colors_primaryColor}; }

            .kol-activities-wrapper .kol-copy-and-paste-sharelink-container .kol-copy-and-paste-icon{color: ${
              widgetOptions.colors_primaryColor
            }; }



            a:hover,  { color:  ${darkerPrimaryColor}; }

            body { background-color:${widgetOptions.colors_backgroundColor};}

            .btn.btn-primary{
              background-color: ${widgetOptions.colors_primaryColor};

            }
            .btn.btn-primary:hover{
              background-color: ${darkerPrimaryColor};
            }

           .kol-section-headline-text .label-points{

            color: ${widgetOptions.colors_primaryColor}
          }

          .kol-activity.is-dark-text{
            color: ${widgetOptions.colors_defaultFontColor} !important;
          }
          .kol-activity{
            background: ${widgetOptions.colors_backgroundColor} !important;
            border: .5px solid ${transparentTextColorLighter};
            box-shadow: 0 1px 3px ${transparentTextColorLighter}, 0 1px 2px ${transparentTextColor};
          }
          .kol-reward-tracker .tracker-style-modern .reward-level{
            border: .5px solid ${transparentTextColorLighter};
            box-shadow: 0 1px 3px ${transparentTextColorLighter}, 0 1px 2px ${transparentTextColor};
            background: ${widgetOptions.colors_backgroundColor} !important;
          }
          .kol-reward-tracker .tracker-style-modern .reward-level-prize{
            background: ${widgetOptions.colors_backgroundColor} !important;
            color: ${widgetOptions.colors_defaultFontColor} !important;
          }

          .kol-activity-expanded .kol-activity-header, .kol-reward-tracker .tracker-style-modern .points{
            background: ${widgetOptions.colors_backgroundColor} !important;
            color: ${widgetOptions.colors_defaultFontColor} !important;
          }
          .kol-activity .fa-envelope {
             color: ${widgetOptions.colors_defaultFontColor} !important;
          }
      

          .is-section{
            display:table;
          }

        </style>

          <script></script>
          ${customHtmlHead}
        </head>
        <body class="kol-onpage-contestbox kol-contestbox-template-${widgetOptions.template} show-branding-${
        options.configuration.displayBranding
      } showing-footer-bar-${this.showingFooterBar(widgetOptions)}">

         
      ${this.getTopOfBoxWithCTAText(widgetOptions)}

      <div id="kol-box-holder" class="is-section is-section-margin-0 is-box is-section-auto is-default-text" style="background-color: ${
        widgetOptions.colors_backgroundColor
      }; padding: 0px 0px 0px; ">
        <div class="is-boxes">
          <div class="is-box-centered is-content-top">
            <div class="is-container is-stacking-rows ${widgetOptions.template != 'chat' ? '' : 'is-content-640'}" style="background-color: inherit;">
              <div class="row ">
                <div id="kol-box-content-wrapper" class="col-sm-12">
                  

                    ${this.getHeroImage(widgetOptions)}
                  
                  <div id="kol-box-content" class="is-builder is-container-inner" style="zoom: 1;">
                    <div id="kol-box-content-inner-wrapper">

                    ${this.getLeadWelcomeArea(widgetOptions)}

          <div class="row kol-no-lead-found" style=" margin: 15px -15px 0px;">
                      <div class="col-sm-12 ">${
                        widgetOptions.content_introduction
                      }</div>
          </div>
          <div class="row kol-lead-found" style=" margin: 15px -15px 0px;display:none;">
                      <div class="col-sm-12 ">${
                        widgetOptions.content_formConfirmationText
                      }</div>
          </div>

          <div class="kol-form-contestbox-wrapper">
            ${this.getformCallToAction(widgetOptions)}
            <div class="row clearfix  kol-form-row kol-form-squared kol-no-lead-found" style="margin-top: 19px; margin-bottom: 0px;" >
              <div class="col-sm-12">
                <form accept-charset="UTF-8" data-contest-box-type="${boxType}" class="kol-signup-form signup_form valid-kol-form" data-remote="true" id="kol-widget-form" method="post">
                  <fieldset class="kol-form-rounded kol-form-default" data-style="rounded" data-layout="stacked" data-labelfontsize="13px" data-checkboxfontsize="12px">
                  ${formHTML}
                  </fieldset>
                  <!-- Button -->
                  <div class="kol-no-delete kol-has-hidden-fields form-group kol-form-submit" data-input-type="submit-button" data-placeholder="" data-required="" data-input_size="btn-md" data-form_name="Default Form">
                    <input type="hidden" value="Default Form" name="__form_name">
                  </div>
                  <div class="clearfix kol-form-button-row" style="position:relative">
                    <div  class="text-center" >
                      <input type="submit" id="btn-id-0418qcgvtgag" class="btn kol-cta-btn btn-block kol-btn-md" value="${
                        widgetOptions.content_button_text
                      }">
                      <style class="button-style">#btn-id-0418qcgvtgag { font-family: ; font-weight: normal; background-color: ${
                        widgetOptions.colors_primaryColor
                      } !important; color: #ffffff !important; border: 1px solid transparent !important; -moz-border-radius: 4px !important; -webkit-border-radius: 4px !important; border-radius: 4px !important; } #btn-id-0418qcgvtgag:hover { background-color: ${darkerPrimaryColor} !important; }</style>
                    </div>
                  </div>
                </form>

              </div>
          </div>
        </div>
        
        <div class="kol-activities-contest-box-wrapper test-${widgetOptions.show_anonymous_actions} ${widgetOptions.show_anonymous_actions ? '' : 'kol-lead-found'} ">
          
        ${this.getAboveActionsText(widgetOptions, showActivitiesClass)}

          <!-- TODO: Smart Build the KOL-Editor stuff from KOL.JS based on events and data objects.  -->
          <div class="row clearfi is-dark-text kol-activities-row ${showActivitiesClass}" style="margin: 20px -15px 0px;">
            <div id="kol-activities" class="kol-activities-wrapper col-md-12" data-kol-snippet='actions' data-settings="{&quot;brandColor&quot;:&quot;${
              widgetOptions.colors_primaryColor
            }&quot;}" class="col-md-12" >


            </div>
          </div>
        </div>

        <div class="kol-rewards-contest-box-wrapper ${widgetOptions.show_anonymous_rewards ? '' : 'kol-lead-found'}">
          ${this.getAboveRewardsText(widgetOptions, showRewardsClass)}
          <div class="row is-no-col-resizing ${showRewardsClass}">
            <div class="col-md-12">
              <div id="kol-contestbox-rewards" data-kol-snippet='rewards' data-settings="{&quot;brandColor&quot;:&quot;${
                widgetOptions.colors_primaryColor
              }&quot;}"  >
            </div>
            </div>
          </div>
        </div>
        <div class="kol-leaderboard-contest-box-wrapper ${widgetOptions.show_anonymous_leaderboard ? '' : 'kol-lead-found'}">
          ${this.getLeaderBoard(widgetOptions)}
        </div>
                  <div class="row" style="margin: 15px -15px 0px;">
                      <div class="col-sm-12 " role="application">${
                        widgetOptions.content_belowRewards
                      }</div>
          </div>
    <div class="row is-align-center clearfix kol-paragraph" id="contest-rules-row" style="margin: 10px -15px 90px;">
          <div class="col-md-12" role="application"><a href="#kolTermsPopUp">${
            widgetOptions.content_contestRulesText
          }</a></div>
    </div>
        </div>
                </div>
              </div>

</div>


      </div>
          </div>
        </div>
      </div>

      <div id="contest-footer-numbers" class="show-branding-${
        options.configuration.displayBranding
      } is-section kol-editable-section is-align-center is-box is-section-auto kol-section-content-on-white-bg  is-section-margin-0 is-shadow-1" style="padding-left: 0px; padding-right:0px; position: fixed; background-color: ${widgetOptions.colors_backgroundColor}; color: ${widgetOptions.colors_defaultFontColor}; z-index: 50; ">
          <div class="is-boxes">
            <div class="is-box-centered">
              <div class="is-container is-stacking-rows is-content-800">
                <div class="flex-row flex flex-no-wrap ">
                  

          ${this.getFooterLeadPoints(widgetOptions)}
          ${this.getFooterPointsToNextRewardColumn(widgetOptions)}
          ${this.getFooterRankColumn(widgetOptions)}
          ${this.getFooterCountdownColumn(widgetOptions)}
          ${this.getFooterTotalLeadsColumn(widgetOptions)}


        <div id="branding-row" class="row show-branding-${
          options.configuration.displayBranding
        }">
          <div class="col-xs-12">
           <div id="on-page-branding">
              <a href="https://kickofflabs.com/?utm_source=kickofflabs&utm_term=customer_onpage&utm_medium=contest_box_bar&utm_content=list_id_${_kol.options.campaignId}&utm_campaign=branding" target="_blank">powered by
              <img src="//s3.amazonaws.com/static.kickoffpages.com/images/logo_on_light_262x38.png" alt="Powered by KickoffLabs" style="display:inline;margin-left: 5px;margin-top: -5px;"  height="18px"/>
              </a>
          </div>
          </div>
        </div>
            </div>
          </div>
        </div>




          </div>







        </body>

      </html>
    `
  }

  getButtonFrameContent (optionOverrides) {
    // TODO CLeanup Default widgetOptions
    optionOverrides = optionOverrides || {}
    const defaultOptions = {
      colors_primaryColor: 'rgb(165, 147, 224)',
      content_buttonImage:
        'https://d1y0v6ricksqp.cloudfront.net/images/icons/contest/kol.png'
    }
    const options = extend(
      {},
      defaultOptions,
      _kol.options.onPageActions.contestBox,
      optionOverrides
    )
    return `<style>
          body{
            margin:0px;
          }
          .kol-button-wrapper{
            width:100%;
            overflow: hidden;
            display:flex;

          }



          .kol-contest-box-icon{
            -webkit-appearance: none;
            -webkit-box-align: center;
            align-items: center;
            bottom: 0px;
            display: block;
            -webkit-box-pack: center;
            justify-content: center;
            position: relative;
            user-select: none;
            z-index: 999;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 65%;
            color: white;
            cursor: pointer;
            min-width: 55px;
            -webkit-tap-highlight-color: transparent;
            height: 55px;
            line-height: 55px;
            border-top-right-radius: 33px;
            border-bottom-right-radius: 33px;
            margin: 0px;
            outline: none;
            padding: 0px;
            border-width: initial;
            border-style: none;
            border-color: initial;
            border-image: initial;
            float:right;
            transition: background-image 200ms linear 0s, transform 200ms linear 0s;
          }

          .kol-contest-box-icon.contest-type-icon{
            background-image: url('${options.content_buttonImage}');
          }



          .kol-contest-box-icon.contest-close-icon{
            background-image: url('https://d1y0v6ricksqp.cloudfront.net/images/icons/contest/close-x.png');
            background-size: 45%;
            display:none;

          }
          .kol-contest-open .kol-contest-box-icon.contest-close-icon{
            display:block;
          }
          .kol-contest-open .kol-contest-box-icon.contest-type-icon, .kol-contest-open .kol-button-notification-text{
            display:none;
          }

          .kol-button-notification-wrapper{
            width: 100%;
            flex: 1;
            display:flex;
            height: 55px;
            cursor: pointer;
            border-radius: 33px;
            background-color: ${options.colors_primaryColor};
            margin: 0px;
            outline: none;
            padding: 0px;
            position: absolute;
            top: 0px;
            left: 0px;
            transition: background-image 900ms linear 0s, transform 900ms linear 0s, opacity linear 900ms;
          }




          .kol-button-notification-image{
            width: 55px;
            height: 55px;
            text-align:center;
          }
          .kol-button-notification-image img{
            display: inline;
            height:55px;
            min-width:100%;
            max-width:none;
            margin:0 -100%;
            width: auto;
          }
          .kol-button-notification-text{
            width: 100%;
            float: right;
            color: white;

            align-items: center;
            justify-content: center;
            display:none;
            padding: 5px 55px 5px 55px;
            white-space: nowrap;
          overflow: hidden;

          }

          .kol-show-notification .kol-button-notification-text,
          #kol-notification:hover .kol-button-notification-text
          {
             display:flex; 
          }


          .kol-button-notification-text.with-kol-image{
            padding: 5px 5px 5px 15px;
          }

          .kol-open-button-wrapper{
            width:55px;
            position: absolute;
            right: 0px;
            background-color: ${options.colors_primaryColor};
          }

          .kol-circle-image-holder{
            width: 55px;
            height: 55px;
            position: relative;
            overflow: hidden;
            border-radius: 55px;
          }


        </style>
      <div id="kol-button-wrapper" class="kol-button-wrapper">

        <div id="kol-notification" class="kol-button-notification-wrapper">
            <div class="kol-button-notification-image">
              <div class="kol-circle-image-holder">
                <img id="kol-notification-image" style="display:none" src="" alt=""/>
              </div>
            </div>
            <div id="kol-notification-text" class="kol-button-notification-text"></div>
            <div class="kol-open-button-wrapper">
              <div class='kol-contest-box-icon contest-type-icon' ></div>
              <div class='kol-contest-box-icon contest-close-icon' ></div>
            </div>
        </div>


      </div>`
  }

  getCommonContestBoxStyles () {
    return `
          .kol-contestbox-container span,
          .kol-contestbox-contest-frame{
            width: 100%;
            height: 100%;
            position: absolute;
            font-size: 100%;
            font-style: normal;
            letter-spacing: normal;
            font-stretch: normal;
            font-weight: normal;
            text-align-last: initial;
            text-indent: 0px;
            text-shadow: none;
            text-transform: none;
            alignment-baseline: baseline;
            animation-play-state: running;
            backface-visibility: visible;
            background-color: transparent;
            
            baseline-shift: baseline;
            bottom: auto;
            -webkit-box-decoration-break: slice;
            box-shadow: none;
            box-sizing: content-box;
            caption-side: top;
            clear: none;
            clip: auto;
            color: inherit;
            column-count: auto;
            column-fill: balance;
            column-gap: normal;
            column-width: auto;
            content: normal;
            counter-increment: none;
            counter-reset: none;
            cursor: auto;
            direction: ltr;

            dominant-baseline: auto;
            empty-cells: show;
            float: none;
            height: auto;
            -webkit-hyphenate-character: auto;
            hyphens: manual;
            image-rendering: auto;
            left: auto;
            line-height: inherit;
            max-height: none;
            max-width: none;
            min-height: 0px;
            min-width: 0px;
            opacity: 1;
            orphans: 2;
            outline-offset: 0px;
            page: auto;
            perspective: none;
            perspective-origin: 50% 50%;
            pointer-events: auto;
            position: static;
            quotes: none;
            resize: none;
            right: auto;
            size: auto;
            table-layout: auto;
            top: auto;
            transform: none;
            transform-origin: 50% 50% 0px;
            transform-style: flat;
            unicode-bidi: normal;
            vertical-align: baseline;
            white-space: normal;
            widows: 2;
            width: auto;
            word-break: normal;
            word-spacing: normal;
            overflow-wrap: normal;
            z-index: auto;
            text-align: start;
            -webkit-font-smoothing: antialiased;
            font-variant: normal;
            text-decoration: none;
            border-width: 0px;
            border-style: none;
            border-color: transparent;
            border-image: initial;
            border-radius: 0px;
            list-style: outside none disc;
            margin: 0px;
            overflow: visible;
            padding: 0px;
            page-break-after: auto;
            page-break-before: auto;
            page-break-inside: auto;
          }`
  }

  getCloseButtonMarkup (options) {
    if (options.template == 'vertical' || options.template == 'horizontal') {
      return '<div id="kol-close-contest-box-x" style="width: 20px;position: absolute ;top: 2px;right: 5px;" class="kol-close-contest-box-x"><a href="#kolCloseContestBox" title="close"><img style="max-width:100%" src="https://d1y0v6ricksqp.cloudfront.net/images/icons/contest/close-x.png"></a></div>'
    } else { return '' }
  }

  getContestFrameMarkup (options) {
    return `
    <div class="kol-contestbox-contest-frame-holder" style="display:none">
    ${this.getLoadingSpinner(options, 'kol-spinner-wrapper')}
    <div><style>

          #kol_contestbox_container{
            z-index:10010;
            width: 100%;
            right: 0;
          }

          #kol_contestbox_container.kol-contestbox-template-chat{
            height: 90px ;
          }



           #kol_contestbox_container.kol-contestbox-template-chat.kol-contestbox-position-bottomleft{
            left: 0px ;
            right: auto;
          }

          .kol-contestbox-template-chat.kol-contestbox-position-bottomleft .kol-contestbox-button-frame-holder{
            left: 20px ;
            right: auto ;
          }
          .kol-contestbox-template-chat.kol-contestbox-position-bottomleft #kol-contestbox-button-frame{
            float: left ;
          }


          #kol_contestbox_container.kol-contestbox-template-vertical.kol-contest-open, 
          #kol_contestbox_container.kol-contestbox-template-horizontal.kol-contest-open{
            height: 100%;
            background: rgba(0,0,0,0.5);
          }

          #kol_contestbox_container.kol-contestbox-template-chat.kol-contest-open{
            height: calc(100% - 20px) ;
          }

          .kol-contestbox-contest-frame-holder{
            z-index: 2147483000;
            position: fixed;
            bottom: 65px;
            right: 0px;
            width: 100%;
            background:white;
            display:none !important;
            align-content: center;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            min-height: 250px;
            max-height: 750px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
            opacity: 0;
            height: calc(100% - 70px);
            border-radius: 8px;
            overflow: hidden;
          }

          #kol_contestbox_container.kol-contestbox-template-vertical .kol-contestbox-contest-frame-holder{
            height: calc(100% - 40px);
            top: 20px;
          }

          @media (min-width: 576px){
            #kol_contestbox_container.kol-contestbox-template-horizontal.kol-contest-open .kol-contestbox-contest-frame-holder{
              height: 500px;
              top: 20px;
            }
          }

          @media (min-width: 768px){


            #kol_contestbox_container.kol-contestbox-template-vertical.kol-contest-open .kol-contestbox-contest-frame-holder,
            #kol_contestbox_container.kol-contestbox-template-horizontal.kol-contest-open .kol-contestbox-contest-frame-holder{
              right: 50%;
              top: 20px;
              width: 720px;
              transform: translatex(50%);
            }


            
             .kol-contestbox-template-chat .kol-contestbox-contest-frame-holder{
               right:20px;
               bottom: 95px;
               width: 376px;
               height: calc(100% - 100px);
             }

             .kol-contestbox-template-chat.kol-contestbox-position-bottomleft .kol-contestbox-contest-frame-holder {
              left: 20px ;
              right: auto ;

            }
             #kol_contestbox_container.kol-contestbox-template-chat{
               width: 380px !important;
             }
          }
          .kol-contestbox-contest-frame-holder.kol-contest-open{
            display:flex !important;
          }

          ${this.getCommonContestBoxStyles()}
          </style></div>
          ${this.getCloseButtonMarkup(options)}

      <iframe id="kol-contestbox-contest-frame" allow="clipboard-write" name="kol-content-box" style="width: 100% !important;height: 100% !important;" class="kol-contestbox-contest-frame" ></iframe>
    </div>`
    // could also use option ala <h2 class="user-name">${options.name}</h2>
  }

  getButtonFrameMarkup (options) {
    switch (options.template) {
      case 'chat':
        return `<div class="kol-contestbox-button-frame-holder" scrolling="no"
          style="border-radius: 60px;height: 55px;position: fixed;transform: scale(1);z-index: 1049;right: 20px;">
          <style>
            .kol-contestbox-button-frame{
              z-index: 2147483000;

              box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 6px 0px, rgba(0, 0, 0, 0.16) 0px 2px 32px 0px;
              height: 55px;
              width: 55px;

              border-width: initial;
              border-style: none;
              border-color: initial;
              border-image: initial;
              float: right;
              border-radius: 55px;
              transition: opacity 500ms ease, width 500ms ease;
              outline: 1px solid transparent;
              -webkit-backface-visibility: hidden;
              overflow: hidden;
            }


            .kol-contestbox-button-frame-holder{
                  bottom: 5px;
                  width: 100%;
            }
            .kol-contestbox-button-frame.kol-show-notification,
            .kol-contestbox-button-frame:hover
            {
              width: calc(100% - 40px);
              margin-left: auto;
              margin-right: auto;
            }
            @media (min-width: 768px){
              .kol-contestbox-button-frame.kol-show-notification,
              .kol-contestbox-button-frame:hover
              {
                width: 335px;
              }
              .kol-contestbox-button-frame-holder{
                bottom: 20px;
                width: 380px;
              }
            }

          </style>
          <iframe id="kol-contestbox-button-frame" name="kol-content-box" class="kol-contestbox-button-frame" style="border-width: initial;border-style: none;border-color: initial;border-image: initial;"></iframe>
        </div>`
    }

    // could also use option ala <h2 class="user-name">${options.name}</h2>
  }
}
