export default class KOLShareLinkCopy {
  constructor (writeDoc) {
    this.doc = writeDoc
    const myself = this

    this.handleBuildByEvent = function (event) {
      myself.build()
    }

    this.build()
    this.setupEventListeners()
  }

  setupEventListeners () {
    window.removeEventListener(
      'kol:snippets:build:sharelinkcopy',
      this.handleBuildByEvent.bind(this),
      true
    )
    window.addEventListener(
      'kol:snippets:build:sharelinkcopy',
      this.handleBuildByEvent.bind(this)
    )

    window._kol.container.removeEventListener(
      'kol:leadchange',
      this.handleBuildByEvent.bind(this)
    )
    window._kol.container.addEventListener(
      'kol:leadchange',
      this.handleBuildByEvent.bind(this)
    )

    document.removeEventListener('kol:knownlead', this.handleBuildByEvent.bind(this))
    document.addEventListener('kol:knownlead', this.handleBuildByEvent.bind(this))
  }

  build () {
    const snippets = this.doc.querySelectorAll("[data-kol-snippet='sharelinkcopy']")

    snippets.forEach((snippet, index) => {
      const input = snippet.querySelector('input') // or .kol-copy-and-paste-sharelink
      input.setAttribute('value', _kol.lead.social_url)
    })
  }
}
