import KOLStrings from '../utils/strings'
import { kolDebug as debug } from '../utils/debugging'
export default class KOLLeadPropertiesText {
  constructor (writeDocument) {
    this.doc = writeDocument
    const myself = this
    this._handleLead = function (event) {
      myself.replaceText(myself.doc)
    }

    // setup event handlers for activities.
    // Run the first time
    this._handleLead()
    this.setupEventListeners()
  }

  replaceTextNodes (node, newText) {
    if (node.hasChildNodes()) {
      for (let i = 0, len = node.childNodes.length; i < len; ++i) {
        this.replaceTextNodes(node.childNodes[i], newText)
      }
    } else {
      if (node.nodeType == 3) {
        node.data = newText
      } else {
        node.innerHTML = newText
      }
    }
  }

  replaceText (doc) {
    debug('Replacing text nodes with lead data.')
    const snippets = doc.querySelectorAll('[data-replace-with-lead-data]')
    snippets.forEach((snippet, index) => {
      const leadDataRequest = snippet.getAttribute('data-replace-with-lead-data')
      switch (leadDataRequest) {
        case 'points_to_next_reward':
          this.replaceTextNodes(
            snippet,
            KOLStrings.getPointsToNextReward().toLocaleString()
          )
          break
        case 'given_name':
          let name = _kol?.options?.localization?.given_name_greeting || ''
          if (_kol.lead[leadDataRequest] !== undefined && _kol.lead[leadDataRequest] !== null) {
            name = _kol.lead[leadDataRequest].toLocaleString()
          }
          this.replaceTextNodes(snippet, name)
          break
        case 'contest_score_rank':
          const rank_text = _kol.lead.waitlisted
            ? _kol?.lead?.contest_score_rank || 'n.a.'
            : _kol?.options?.localization?.unwaitlisted_message || 'In!'
          this.replaceTextNodes(snippet, rank_text.toLocaleString())
          break
        case 'rank_minus_contest_score':
          let rank_minus_score = _kol.lead.rank - _kol.lead.contest_score
          rank_minus_score = Math.max(1, rank_minus_score)
          this.replaceTextNodes(snippet, rank_minus_score.toLocaleString())
          break
        default:
          const has_data =
            _kol.lead[leadDataRequest] !== undefined &&
            _kol.lead[leadDataRequest] !== null
          if (has_data) {
            this.replaceTextNodes(
              snippet,
              _kol.lead[leadDataRequest].toLocaleString()
            )
          } else {
            // there is no data - So blank it out.
            this.replaceTextNodes(snippet, '')
          }
      }
      snippet.classList.remove('pending')
    })
  }

  setupEventListeners () {
    window._kol.container.removeEventListener(
      'kol:leadchange',
      this._handleLead,
      true
    )
    window._kol.container.addEventListener('kol:leadchange', this._handleLead)
    window.removeEventListener('kol:knownlead', this._handleLead, true)
    window.addEventListener('kol:knownlead', this._handleLead)
    window.removeEventListener('kol:unknownlead', this._handleLead, true)
    window.addEventListener('kol:unknownlead', this._handleLead)

    window.removeEventListener(
      'kol:activity-marked-done',
      this._handleLead.bind(this),
      true
    )
    window.addEventListener(
      'kol:activity-marked-done',
      this._handleLead.bind(this)
    )
  }
}
