import { kolDebug as debug } from './utils/debugging'
import { applyRecaptcha, applyCaptchaIfNecessary } from './utils/captcha'
import { extend } from './utils/objects'
import { fetchPreviousForm } from './previous_form'
import { ready, on, triggerContestBox } from './utils/events'
import Leads from './leads'

export default class Form {
  constructor (optionOverrides = {}) {
    this.optionOverrides = optionOverrides
    this.formErrors = []
    this.options = extend(
      {},
      this.defaultOptions,
      optionOverrides,
      this.globalOverrides()
    )
    if (this.options.form === null) {
      this.options.form = this.defaultOptions.form
    }
    debug('Form Options', this.options)
    if (this.options.form.enabled) {
      this.setup()
    } else {
      debug('Skipped Form Setup. Form is disabled in options.')
    }
  }

  defaultOptions = {
    form: {
      captcha: false,
      reCAPTCHA_site_key: '6LeHFokUAAAAABceXPpNEEuvJcBwxtcDYB1_nVc6', // V3 KEY!
      source: 'kf',
      selector: 'form[data-kol-form=true],.kol-signup-form',
      useLocalStorageData: true,
      hideKnownFormFields: false,
      error: {
        message:
          'Sorry, please enter your email (or phone number) on the form.',
        selector: '[data-kol-errorMessage=true]'
      },
      mappings: {
        email: {
          enabled: true,
          selector: 'input[type=email],#email,input[name=emailAddress]'
        },
        phoneNumber: {
          enabled: true,
          selector: 'input[type=tel],#phone,input[name=phoneNumber]'
        }
      }
    }
  }

  handleLead (event) {
    debug('FORM LEAD CHANGE EVENT!')

    const lead = event.detail.lead
    if (lead && !lead.anonymous) {
      this.showAnyFormMessages()
    } else {
      this.hideAllMessages()
      this.showAllForms()
    }
  }

  setup () {
    debug('FORM SETUP!')
    ready(this.connectForm.bind(this))
    applyRecaptcha(this.options)

    this.populatePreviousForm()

    debug('Connecting form events!')

    window._kol.container.removeEventListener(
      'kol:leadchange',
      this.handleLead.bind(this),
      true
    )
    window._kol.container.addEventListener(
      'kol:leadchange',
      this.handleLead.bind(this)
    )

    // Don't atatch these events for preview mode.
    if (!window._kol.mode.isPreview) {
      window._kol.container.removeEventListener(
        'kol:knownlead',
        this.handleLead.bind(this),
        true
      )
      window._kol.container.addEventListener(
        'kol:knownlead',
        this.handleLead.bind(this)
      )
    }
  }

  updateFormField (form, key, value) {
  // Find the form field that matches the current key
    const fields = form.querySelectorAll('input[name="' + key + '"], textarea[name="' + key + '"], select[name="' + key + '"]')
    const myself = this
    fields.forEach(function (field) {
      debug('Setting known form field', field.name)
      if (field.type === 'checkbox' || field.type === 'radio') {
      // For checkboxes and radio buttons, set the checked property based on the field's value
        field.checked = field.value === value
      } else {
      // For other field types, set the value directly
        field.value = value
      }
      if (myself.options.form.hideKnownFormFields && field.type !== 'hidden') {
      // Create a new hidden input to replace the current field
        debug('Hiding known form field', field.name)
        const hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = field.name
        hiddenInput.value = field.value

        // Replace the current field with the hidden input
        field.parentNode.replaceChild(hiddenInput, field)
      }
    })
  }

  populatePreviousForm () {
    const formData = fetchPreviousForm()
    // Ensure window._kol and its nested properties exist to prevent errors
    if (this.options && this.options.form && this.options.form.selector && this.options.form.useLocalStorageData) {
    // Find all forms using the selector stored in window._kol.options.form.selector
      const forms = document.querySelectorAll(this.options.form.selector)
      const myself = this
      // Ensure the forms NodeList is not empty before proceeding
      if (forms.length > 0) {
        debug('Populating form fields with previous form data', formData)
        forms.forEach(function (form) {
        // Iterate over each property in the formData object
          Object.keys(formData).forEach(function (key) {
          // Update the form fields with the corresponding value
            myself.updateFormField(form, key, formData[key])
          })
        })
      } else {
        debug('No forms matching the selector were found.')
      }
    } else {
      debug('window._kol.options.form.selector is not defined.')
    }
  }

  captureFormData (form) {
    const formData = {}
    const fdObject = new FormData(form)

    fdObject.forEach((value, key) => {
      if (formData[key]) {
        // capture all the choice values.
        formData[key] = fdObject.getAll(key).toString()
      } else {
        formData[key] = value
      }
    })

    formData.__source = this.options.form.source

    if (
      !formData.email &&
      formData.email !== '' &&
      this.options.form.mappings.email.enabled
    ) {
      const emailField = form.querySelector(
        this.options.form.mappings.email.selector
      )
      if (emailField) {
        const currentName = emailField.getAttribute('name')
        delete formData[currentName]
        debug(`Kol Form Remapping ${currentName} to email`)
        formData.email = emailField.value
      }
    }

    if (
      !formData.phone_number &&
      formData.phone_number !== '' &&
      this.options.form.mappings.phoneNumber.enabled
    ) {
      const phoneNumberField = form.querySelector(
        this.options.form.mappings.phoneNumber.selector
      )
      if (phoneNumberField) {
        const currentName = phoneNumberField.getAttribute('name')
        delete formData[currentName]
        debug(`Kol Form Remapping ${currentName} to phone_number`)
        formData.phone_number = phoneNumberField.value
      }
    }

    return formData
  }

  verifyRequiredCheckboxes (form) {
    // Ensure the form parameter is a valid element
    if (!(form instanceof Element)) {
      debug('Invalid form passed to verifyCheckboxGroups')
      return false
    }

    // Select all div elements with the specified data attributes within the form
    const requiredCheckboxGroups = form.querySelectorAll('div[data-input-type="checkbox"][data-required="on"]')

    // Iterate through each group
    for (const group of requiredCheckboxGroups) {
      // Find all checkboxes within the current group
      const checkboxes = group.querySelectorAll('input[type="checkbox"]')

      // Check if at least one checkbox is checked within this group
      const isAnyChecked = Array.from(checkboxes).some(checkbox => checkbox.checked)

      // If no checkboxes are checked in this group, return false
      if (!isAnyChecked) {
        this.formErrors.push(group.dataset.label)
        return false
      }
    }

    // If all required groups have at least one checkbox checked, return true
    return true
  }

  verifyFormData (data, form) {
    return this.verifyEmailOrPhoneFormData(data) && this.verifyRequiredCheckboxes(form)
  }

  verifyEmailOrPhoneFormData (data) {
    if ((data.email || '').trim().length > 0 ||
      (data.phone_number || '').trim().length > 0) {
      return true
    } else {
      this.formErrors.push('Email / Phone')
      return false
    }
  }

  setErrorMessage (form, message) {
    const errorElement = form.querySelector(this.options.form.error.selector)
    if (errorElement) {
      errorElement.innerText = message
    }
    return errorElement
  }

  connectForm () {
    debug('Connect Form')
    on(this.options.form.selector, 'submit', (submitEvent) => {
      debug('Form Submit Event Raised')
      submitEvent.preventDefault()
      const form = submitEvent.target
      const apiData = this.captureFormData(form)
      if (this.verifyFormData(apiData, form)) {
        applyCaptchaIfNecessary(apiData).then((newData) => {
          this.addLead(newData, form)
        })
        this.formErrors = [] // clear previous errors
        this.setErrorMessage(form, '') // clear previous error in case we do not redirect
        debug('KOL Form SUBMIT FINISHED!')
      } else {
        const message = this.formErrorMessage()
        if (message) {
          this.formErrors = [] // clear previous errors to check again
          alert(message)
        }
        debug('Kol Form: Required fields were missing. Alerted user: ' + message)
      }
    })
  }

  formErrorMessage () {
    return (_kol.options &&
      _kol.options.localization &&
      _kol.options.localization.formRequiredFieldMessage) + ' ' + this.formErrors.join('\n') ||
      this.options.form.error.message + ' ' + this.formErrors.join('\n')
  }

  addLead (apiData, form) {
    return new Leads()
      .addLead(apiData)
      .then((data) => {
        // Check if the data coming back is a lead by having an ID
        if (data.id) {
          this.handleLeadResponse(data, form)
        } else {
          let message = this.formErrorMessage()
          if (data.message) {
            message += ' ' + data.message
          }
          alert(message)
          debug(
            "Kol Form: Didn't get back a lead from the server. Alerted user: " +
              message
          )
        }
      })
      .catch((err) => {
        let errorMessage =
          window._kol?.options?.localization?.formErrorMessage || err.message
        if (err.response && err.response.status == 423) {
          // Means campaign is locked bc the end date was passed and set to expire leads.
          errorMessage = window._kol?.options?.countdown?.end_message || 'This campaign has ended.'
        }
        console.error(err)
        alert(errorMessage)
      })
  }

  isPreview (previewAlert, data) {
    return previewAlert || data.original_redirect_url || data.preview
  }

  displayPreview (previewAlert, isContestBox, data) {
    data.isContestBox = isContestBox
    if (previewAlert) {
      previewAlert.show(data)
    } else {
      const message = isContestBox
        ? "Your campaign's Contest Box would have shown status here for the lead."
        : 'This lead would have been redirected to #{data.original_redirect_url}.'
      alert(message)
    }
  }

  isContestBoxConfigured (form) {
    return (
      this.options.form.confirmationType === 'contestbox' ||
      (form && form.dataset.confirmation_type === 'contestbox')
    )
  }

  isValidPopup (popupId) {
    return _kol.options.onPageActions.popups.find(popup => Number(popup.pageId) === Number(popupId))
  }

  handleLeadResponse (lead, form) {
    if (lead) {
      const isOnPage =
        this.options.form.confirmationType === 'onpage' ||
        (form && form.dataset.confirmation_type === 'onpage')
      const isRedirect = this.options.form.confirmationType === 'redirect' && this.options.form.redirectPage
      
      const isPopup = (this.options.form.confirmationType === 'popup' || (form && form.dataset.confirmation_type === 'popup')) && this.isValidPopup(form.dataset.redirect_page || this.options.form.redirectPage)
      
      const isContestBox =
        this.options.valid && this.isContestBoxConfigured(form)

      let preview_alert = window.RedirectMessage
      if (!preview_alert) {
        try {
          preview_alert = window.parent && window.parent.RedirectMessage
        } catch (error) {
          debug("Can't get preview alert dialog from parent. It's OK.", error)
        }
      }

      const event = new CustomEvent('kol:form:success', {
        bubbles: true,
        detail: {
          lead,
          form,
          statusText: 'Lead Created via Kol Form'
        }
      })

      window.document.dispatchEvent(event)
      if (isPopup) {
        const popupId = form.dataset.redirect_page || this.options.form.redirectPage
        const openEvent = new CustomEvent('kol:open:PagePopup', { detail: { popupPageId: popupId } })
        window.top._kol.container.dispatchEvent(openEvent)
        return
      }
      if (isOnPage) {
        debug(
          'Staying on page - hiding forms - showing confirmation messages.'
        )
        this.hideAllForms()
        this.showMessageForForm(form)
        return
      }

      if (isRedirect) {
        const url = new URL(this.options.form.redirectPage)
        url.searchParams.set('kolid', lead.social_id)
        debug(
          'Redirecting if a custom redirect URL:', url.toString()
        )
        window.top.location = url.toString()
        return
      }

      if (this.isPreview(preview_alert, lead)) {
        debug(
          'Was a preview after form. Just display an alert about what would have happened.'
        )
        this.displayPreview(preview_alert, isContestBox, lead)
        return
      }
      if (isContestBox) {
        debug('Showing contest box after form.')
        triggerContestBox(lead, 'Activate via Campaign AnyForm')
        return
      }
      if (lead.status_url) {
        debug('Redirecting after the form.', lead.status_url)
        window.top.location.href = lead.status_url
      } else {
        alert(this.thankyouMessage())
      }
    }

    return lead
  }

  thankyouMessage () {
    return (
      (_kol.options &&
        _kol.options.localization &&
        _kol.options.localization.thankyouMessage) ||
      'Thank you!'
    )
  }

  showAnyFormMessages () {
    document
      .querySelectorAll(`${this.options.form.selector}`)
      .forEach((form) => {
        const isOnPage = form && form.dataset.confirmation_type === 'onpage'
        if (isOnPage) {
          form.style.display = 'none'
          this.showMessageForForm(form)
        }
      })
  }

  showMessageForForm (form) {
    const message = form.dataset.confirmation_text || 'Thanks!'
    const messageContainer = this.findOrCreateMessageContainer(form)
    messageContainer.innerHTML = message
    messageContainer.style.display = 'block'
  }

  hideAllMessages () {
    document
      .querySelectorAll('[data-kol-confirmation-message=true]')
      .forEach((message) => (message.style.display = 'none'))
  }

  hideAllForms () {
    // Only hide kol-signup-form - Let customers add this if they want that.
    document
      .querySelectorAll('.kol-signup-form')
      .forEach((form) => (form.style.display = 'none'))
  }

  showAllForms () {
    document
      .querySelectorAll(`${this.options.form.selector}`)
      .forEach((form) => {
        // only display = block if display = none. Try to prevent grid going to block, etc.
        if (form.style.display === 'none') {
          form.style.display = 'block'
        }
      })
  }

  findOrCreateMessageContainer (form) {
    let messageContainer = null
    const parent = form.parentElement
    messageContainer = parent.querySelector(
      '[data-kol-confirmation-message=true]'
    )
    // if we cannot find the parent container, create one
    if (!messageContainer) {
      messageContainer = document.createElement('div')
      messageContainer.dataset.kolConfirmationMessage = true
      messageContainer.dataset.kolEditor = 'form-confirmation'
      form.parentElement.appendChild(messageContainer)
    }
    return messageContainer
  }

  globalOverrides () {
    return window.kol_form_options || {}
  }
}
