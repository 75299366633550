export default class KOLOpenContestBoxLinks {
  constructor (writeDoc) {
    const openSnippets = writeDoc.querySelectorAll("[href='#kolOpenContestBox' i]")
    openSnippets.forEach((snippet) => {
      snippet.onclick = null
      snippet.onclick = (e) => {
        e.preventDefault()
        const openEvent = new CustomEvent('kol:activate:ContestBox', {})
        // Don't run if KOL script isn't installed.
        window._kol.container.dispatchEvent(openEvent)
        return false
      }
    })
    const closeSnippets = writeDoc.querySelectorAll("[href='#kolCloseContestBox' i]")
    closeSnippets.forEach((snippet) => {
      snippet.onclick = null
      snippet.onclick = (e) => {
        e.preventDefault()
        const closeEvent = new CustomEvent('kol:close:ContestBox', {})
        // Don't run if KOL script isn't installed.
        window._kol.container.dispatchEvent(closeEvent)
        return false
      }
    })
  }
}
