export default class KOLCountdownLanguages {
  
  
  static getRegionalStrings(langauge){
    
    let lang = langauge || "en-us";
    const langStrings = KOLCountdownLanguages.getRegionalOptions()[lang];

    if (langStrings)
    {return langStrings}
    else {
      return KOLCountdownLanguages.getRegionalOptions()["en-us"]
    }


  }
  static getRegionalOptions () { 
    return {
      "en": {
        // Default regional settings - English/US
        labels: [
          "Years",
          "Months",
          "Weeks",
          "Days",
          "Hours",
          "Minutes",
          "Seconds",
        ],
        labels1: ["Year", "Month", "Week", "Day", "Hour", "Minute", "Second"],
        compactLabels: ["y", "m", "w", "d"],
        whichLabels: null,
        digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
        timeSeparator: ":",
        isRTL: false,
      },
      "en-us": {
        // Default regional settings - English/US
        labels: [
          "Years",
          "Months",
          "Weeks",
          "Days",
          "Hours",
          "Minutes",
          "Seconds",
        ],
        labels1: ["Year", "Month", "Week", "Day", "Hour", "Minute", "Second"],
        compactLabels: ["y", "m", "w", "d"],
        whichLabels: null,
        digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
        timeSeparator: ":",
        isRTL: false,
      },
      es: {
        labels: [
          "Años",
          "Meses",
          "Semanas",
          "Días",
          "Horas",
          "Minutos",
          "Segundos",
        ],
        labels1: ["Año", "Mes", "Semana", "Día", "Hora", "Minuto", "Segundo"],
        compactLabels: ["a", "m", "s", "d"],
        whichLabels: null,
        digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
        timeSeparator: ":",
        isRTL: false,
      },
      ar: {
        labels: ['سنوات', 'أشهر', 'أسابيع', 'أيام', 'ساعات', 'دقائق', 'ثواني'],
        labels1: ['سنة', 'شهر', 'أسبوع', 'يوم', 'ساعة', 'دقيقة', 'ثانية'],
        compactLabels: ['س', 'ش', 'أ', 'ي'],
        whichLabels: null,
        digits: ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'],
        timeSeparator: ':',
        isRTL: true
      },
      bg: {
        labels: ['Години', 'Месеца', 'Седмица', 'Дни', 'Часа', 'Минути', 'Секунди'],
        labels1: ['Година', 'Месец', 'Седмица', 'Ден', 'Час', 'Минута', 'Секунда'],
        compactLabels: ['l', 'm', 'n', 'd'],
        compactLabels1: ['g', 'm', 'n', 'd'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      bn: {
        labels: ['বছর', 'মাস', 'সপ্তাহ', 'দিন', 'ঘন্টা', 'মিনিট', 'সেকেন্ড'],
        labels1: ['বছর', 'মাস', 'সপ্তাহ', 'দিন', 'ঘন্টা', 'মিনিট', 'সেকেন্ড'],
        compactLabels: ['ব', 'মা', 'স', 'দি'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      bs: {
        labels: ['Godina', 'Mjeseci', 'Sedmica', 'Dana', 'Sati', 'Minuta', 'Sekundi'],
        labels1: ['Godina', 'Mjesec', 'Sedmica', 'Dan', 'Sat', 'Minuta', 'Sekunda'],
        labels2: ['Godine', 'Mjeseca', 'Sedmica', 'Dana', 'Sata', 'Minute', 'Sekunde'],
        compactLabels: ['g', 'm', 't', 'd'],
        whichLabels: function (amount) {
          return (amount === 1 ? 1 : (amount >= 2 && amount <= 4 ? 2 : 0));
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      ca: {
        labels: ['Anys', 'Mesos', 'Setmanes', 'Dies', 'Hores', 'Minuts', 'Segons'],
        labels1: ['Anys', 'Mesos', 'Setmanes', 'Dies', 'Hores', 'Minuts', 'Segons'],
        compactLabels: ['a', 'm', 's', 'g'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },

      cs: {
        labels: ['Roků', 'Měsíců', 'Týdnů', 'Dní', 'Hodin', 'Minut', 'Sekund'],
        labels1: ['Rok', 'Měsíc', 'Týden', 'Den', 'Hodina', 'Minuta', 'Sekunda'],
        labels2: ['Roky', 'Měsíce', 'Týdny', 'Dny', 'Hodiny', 'Minuty', 'Sekundy'],
        compactLabels: ['r', 'm', 't', 'd'],
        whichLabels: function (amount) {
          return (amount === 1 ? 1 : (amount >= 2 && amount <= 4 ? 2 : 0));
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      cy: {
        labels: ['Blynyddoedd', 'Mis', 'Wythnosau', 'Diwrnodau', 'Oriau', 'Munudau', 'Eiliadau'],
        labels1: ['Blwyddyn', 'Mis', 'Wythnos', 'Diwrnod', 'Awr', 'Munud', 'Eiliad'],
        compactLabels: ['b', 'm', 'w', 'd'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      da: {
        labels: ['År', 'Måneder', 'Uger', 'Dage', 'Timer', 'Minutter', 'Sekunder'],
        labels1: ['År', 'Måned', 'Uge', 'Dag', 'Time', 'Minut', 'Sekund'],
        compactLabels: ['Å', 'M', 'U', 'D'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      de: {
        labels: ['Jahre', 'Monate', 'Wochen', 'Tage', 'Stunden', 'Minuten', 'Sekunden'],
        labels1: ['Jahr', 'Monat', 'Woche', 'Tag', 'Stunde', 'Minute', 'Sekunde'],
        compactLabels: ['J', 'M', 'W', 'T'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      el: {
        labels: ['Χρόνια', 'Μήνες', 'Εβδομάδες', 'Μέρες', 'Ώρες', 'Λεπτά', 'Δευτερόλεπτα'],
        labels1: ['Χρόνος', 'Μήνας', 'Εβδομάδα', 'Ημέρα', 'Ώρα', 'Λεπτό', 'Δευτερόλεπτο'],
        compactLabels: ['Χρ.', 'Μην.', 'Εβδ.', 'Ημ.'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      et: {
        labels: ['Aastat', 'Kuud', 'Nädalat', 'Päeva', 'Tundi', 'Minutit', 'Sekundit'],
        labels1: ['Aasta', 'Kuu', 'Nädal', 'Päev', 'Tund', 'Minut', 'Sekund'],
        compactLabels: ['a', 'k', 'n', 'p'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      fa: {
        labels: ['سال', 'ماه', 'هفته', 'روز', 'ساعت', 'دقیقه', 'ثانیه'],
        labels1: ['سال', 'ماه', 'هفته', 'روز', 'ساعت', 'دقیقه', 'ثانیه'],
        compactLabels: ['س', 'م', 'ه', 'ر'],
        whichLabels: null,
        digits: ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'],
        timeSeparator: ':',
        isRTL: true
      },
      fi: {
        labels: ['vuotta', 'kuukautta', 'viikkoa', 'päivää', 'tuntia', 'minuuttia', 'sekuntia'],
        labels1: ['vuosi', 'kuukausi', 'viikko', 'päivä', 'tunti', 'minuutti', 'sekunti'],
        compactLabels: ['v', 'kk', 'vk', 'pv'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      fo: {
        labels: ['Ár', 'Mánaðir', 'Vikur', 'Dagar', 'Tímar', 'Minuttir', 'Sekund'],
        labels1: ['Ár', 'Mánaður', 'Vika', 'Dagur', 'Tími', 'Minuttur', 'Sekund'],
        compactLabels: ['Á', 'M', 'V', 'D'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      fr: {
        labels: ['Années', 'Mois', 'Semaines', 'Jours', 'Heures', 'Minutes', 'Secondes'],
        labels1: ['Année', 'Mois', 'Semaine', 'Jour', 'Heure', 'Minute', 'Seconde'],
        compactLabels: ['a', 'm', 's', 'j'],
        whichLabels: function (amount) {
          return (amount > 1 ? 0 : 1);
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      gl: {
        labels: ['Anos', 'Meses', 'Semanas', 'Días', 'Horas', 'Minutos', 'Segundos'],
        labels1: ['Ano', 'Mes', 'Semana', 'Día', 'Hora', 'Minuto', 'Segundo'],
        compactLabels: ['a', 'm', 's', 'g'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      gu: {
        labels: ['વર્ષ', 'મહિનો', 'અઠવાડિયા', 'દિવસ', 'કલાક', 'મિનિટ', 'સેકન્ડ'],
        labels1: ['વર્ષ', 'મહિનો', 'અઠવાડિયા', 'દિવસ', 'કલાક', 'મિનિટ', 'સેકન્ડ'],
        compactLabels: ['વ', 'મ', 'અ', 'દિ'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      he: {
        labels: ['שנים', 'חודשים', 'שבועות', 'ימים', 'שעות', 'דקות', 'שניות'],
        labels1: ['שנה', 'חודש', 'שבוע', 'יום', 'שעה', 'דקה', 'שנייה'],
        compactLabels: ['שנ', 'ח', 'שב', 'י'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: true
      },
      hr: {
        labels: ['Godina', 'Mjeseci', 'Tjedana', 'Dana', 'Sati', 'Minuta', 'Sekundi'], // plurals
        labels1: ['Godina', 'Mjesec', 'Tjedan', 'Dan', 'Sat', 'Minutu', 'Sekundu'], // singles
        labels2: ['Godine', 'Mjeseca', 'Tjedana', 'Dana', 'Sata', 'Minute', 'Sekunde'], // paucals
        compactLabels: ['g', 'm', 't', 'd'],
        whichLabels: function (amount) {
          amount = parseInt(amount, 10);
          if (amount % 10 === 1 && amount % 100 !== 11) {
            return 1; // singles (/.*1$/ && ! /.*11$/)
          }
          if (amount % 10 >= 2 && amount % 10 <= 4 && (amount % 100 < 10 || amount % 100 >= 20)) {
            return 2; // paucals (/.*[234]$/ && ! /.*1[234]$/
          }
          return 0; // default plural (most common case)
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      hu: {
        labels: ['Év', 'Hónap', 'Hét', 'Nap', 'Óra', 'Perc', 'Másodperc'],
        labels1: ['Év', 'Hónap', 'Hét', 'Nap', 'Óra', 'Perc', 'Másodperc'],
        compactLabels: ['É', 'H', 'Hé', 'N'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      hy: {
        labels: ['Տարի', 'Ամիս', 'Շաբաթ', 'Օր', 'Ժամ', 'Րոպե', 'Վարկյան'],
        labels1: ['Տարի', 'Ամիս', 'Շաբաթ', 'Օր', 'Ժամ', 'Րոպե', 'Վարկյան'],
        compactLabels: ['տ', 'ա', 'շ', 'օ'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      id: {
        labels: ['tahun', 'bulan', 'minggu', 'hari', 'jam', 'menit', 'detik'],
        labels1: ['tahun', 'bulan', 'minggu', 'hari', 'jam', 'menit', 'detik'],
        compactLabels: ['t', 'b', 'm', 'h'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      is: {
        labels: ['Ár', 'Mánuðir', 'Vikur', 'Dagar', 'Klukkustundir', 'Mínútur', 'Sekúndur'],
        labels1: ['Ár', 'Mánuður', 'Vika', 'Dagur', 'Klukkustund', 'Mínúta', 'Sekúnda'],
        compactLabels: ['ár.', 'mán.', 'vik.', 'dag.', 'klst.', 'mín.', 'sek.'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      it: {
        labels: ['Anni', 'Mesi', 'Settimane', 'Giorni', 'Ore', 'Minuti', 'Secondi'],
        labels1: ['Anno', 'Mese', 'Settimana', 'Giorno', 'Ora', 'Minuto', 'Secondo'],
        compactLabels: ['a', 'm', 's', 'g'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      ja: {
        labels: ['年', '月', '週', '日', '時', '分', '秒'],
        labels1: ['年', '月', '週', '日', '時', '分', '秒'],
        compactLabels: ['年', '月', '週', '日'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      kn: {
        labels: ['ವರ್ಷಗಳು', 'ತಿಂಗಳು', 'ವಾರಗಳು', 'ದಿನಗಳು', 'ಘಂಟೆಗಳು', 'ನಿಮಿಷಗಳು', 'ಕ್ಷಣಗಳು'],
        labels1: ['ವರ್ಷ', 'ತಿಂಗಳು', 'ವಾರ', 'ದಿನ', 'ಘಂಟೆ', 'ನಿಮಿಷ', 'ಕ್ಷಣ'],
        compactLabels: ['ವ', 'ತಿ', 'ವಾ', 'ದಿ'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      ko: {
        labels: ['년', '월', '주', '일', '시', '분', '초'],
        labels1: ['년', '월', '주', '일', '시', '분', '초'],
        compactLabels: ['년', '월', '주', '일'],
        compactLabels1: ['년', '월', '주', '일'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      lt: {
        labels: ['Metų', 'Mėnesių', 'Savaičių', 'Dienų', 'Valandų', 'Minučių', 'Sekundžių'],
        labels1: ['Metai', 'Mėnuo', 'Savaitė', 'Diena', 'Valanda', 'Minutė', 'Sekundė'],
        compactLabels: ['m', 'm', 's', 'd'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      lv: {
        labels: ['Gadi', 'Mēneši', 'Nedēļas', 'Dienas', 'Stundas', 'Minūtes', 'Sekundes'],
        labels1: ['Gads', 'Mēnesis', 'Nedēļa', 'Diena', 'Stunda', 'Minūte', 'Sekunde'],
        compactLabels: ['l', 'm', 'n', 'd'],
        compactLabels1: ['g', 'm', 'n', 'd'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      mk: {
        labels: ['Години', 'Месеци', 'Недели', 'Дена', 'Часа', 'Минути', 'Секунди'],
        labels1: ['Година', 'Месец', 'Недела', 'Ден', 'Час', 'Минута', 'Секунда'],
        compactLabels: ['l', 'm', 'n', 'd'],
        compactLabels1: ['g', 'm', 'n', 'd'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      ml: {
        labels: ['വര്‍ഷങ്ങള്‍', 'മാസങ്ങള്‍', 'ആഴ്ചകള്‍', 'ദിവസങ്ങള്‍', 'മണിക്കൂറുകള്‍', 'മിനിറ്റുകള്‍', 'സെക്കന്റുകള്‍'],
        labels1: ['വര്‍ഷം', 'മാസം', 'ആഴ്ച', 'ദിവസം', 'മണിക്കൂര്‍', 'മിനിറ്റ്', 'സെക്കന്റ്'],
        compactLabels: ['വ', 'മ', 'ആ', 'ദി'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        //		digits: ['൦','൧','൨','൩','൪','൫','൬','൭','൮','൯'],
        timeSeparator: ':',
        isRTL: false
      },
      ms: {
        labels: ['Tahun', 'Bulan', 'Minggu', 'Hari', 'Jam', 'Minit', 'Saat'],
        labels1: ['Tahun', 'Bulan', 'Minggu', 'Hari', 'Jam', 'Minit', 'Saat'],
        compactLabels: ['t', 'b', 'm', 'h'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      my: {
        labels: ['နွစ္', 'လ', 'ရက္သတဿတပတ္', 'ရက္', 'နာရီ', 'မိနစ္', 'စကဿကန့္'],
        labels1: ['နွစ္', 'လ', 'ရက္သတဿတပတ္', 'ရက္', 'နာရီ', 'မိနစ္', 'စကဿကန့္'],
        compactLabels: ['နွစ္', 'လ', 'ရက္သတဿတပတ္', 'ရက္'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      nb: {
        labels: ['År', 'Måneder', 'Uker', 'Dager', 'Timer', 'Minutter', 'Sekunder'],
        labels1: ['År', 'Måned', 'Uke', 'Dag', 'Time', 'Minutt', 'Sekund'],
        compactLabels: ['Å', 'M', 'U', 'D'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      nl: {
        labels: ['Jaren', 'Maanden', 'Weken', 'Dagen', 'Uren', 'Minuten', 'Seconden'],
        labels1: ['Jaar', 'Maand', 'Week', 'Dag', 'Uur', 'Minuut', 'Seconde'],
        compactLabels: ['j', 'm', 'w', 'd'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      pl: {
        labels: ['lat', 'miesięcy', 'tygodni', 'dni', 'godzin', 'minut', 'sekund'],
        labels1: ['rok', 'miesiąc', 'tydzień', 'dzień', 'godzina', 'minuta', 'sekunda'],
        labels2: ['lata', 'miesiące', 'tygodnie', 'dni', 'godziny', 'minuty', 'sekundy'],
        compactLabels: ['l', 'm', 't', 'd'],
        compactLabels1: ['r', 'm', 't', 'd'],
        whichLabels: function (amount) {
          var units = amount % 10;
          var tens = Math.floor((amount % 100) / 10);
          return (amount === 1 ? 1 : (units >= 2 && units <= 4 && tens !== 1 ? 2 : 0));
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      'pt-BR': {
        labels: ['Anos', 'Meses', 'Semanas', 'Dias', 'Horas', 'Minutos', 'Segundos'],
        labels1: ['Ano', 'Mês', 'Semana', 'Dia', 'Hora', 'Minuto', 'Segundo'],
        compactLabels: ['a', 'm', 's', 'd'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      ro: {
        labels: ['Ani', 'Luni', 'Saptamani', 'Zile', 'Ore', 'Minute', 'Secunde'],
        labels1: ['An', 'Luna', 'Saptamana', 'Ziua', 'Ora', 'Minutul', 'Secunda'],
        compactLabels: ['A', 'L', 'S', 'Z'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      ru: {
        labels: ['Лет', 'Месяцев', 'Недель', 'Дней', 'Часов', 'Минут', 'Секунд'],
        labels1: ['Год', 'Месяц', 'Неделя', 'День', 'Час', 'Минута', 'Секунда'],
        labels2: ['Года', 'Месяца', 'Недели', 'Дня', 'Часа', 'Минуты', 'Секунды'],
        compactLabels: ['л', 'м', 'н', 'д'],
        compactLabels1: ['г', 'м', 'н', 'д'],
        whichLabels: function (amount) {
          var units = amount % 10;
          var tens = Math.floor((amount % 100) / 10);
          return (amount === 1 ? 1 : (units >= 2 && units <= 4 && tens !== 1 ? 2 :
            (units === 1 && tens !== 1 ? 1 : 0)));
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      sk: {
        labels: ['Rokov', 'Mesiacov', 'Týždňov', 'Dní', 'Hodín', 'Minút', 'Sekúnd'],
        labels1: ['Rok', 'Mesiac', 'Týždeň', 'Deň', 'Hodina', 'Minúta', 'Sekunda'],
        labels2: ['Roky', 'Mesiace', 'Týždne', 'Dni', 'Hodiny', 'Minúty', 'Sekundy'],
        compactLabels: ['r', 'm', 't', 'd'],
        whichLabels: function (amount) {
          return (amount === 1 ? 1 : (amount >= 2 && amount <= 4 ? 2 : 0));
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      sl: {
        labels: ['Let', 'Mesecev', 'Tednov', 'Dni', 'Ur', 'Minut', 'Sekund'], // Plurals
        labels1: ['Leto', 'Mesec', 'Teden', 'Dan', 'Ura', 'Minuta', 'Sekunda'], // Singles
        labels2: ['Leti', 'Meseca', 'Tedna', 'Dneva', 'Uri', 'Minuti', 'Sekundi'], // Doubles
        labels3: ['Leta', 'Meseci', 'Tedni', 'Dnevi', 'Ure', 'Minute', 'Sekunde'], // 3's
        labels4: ['Leta', 'Meseci', 'Tedni', 'Dnevi', 'Ure', 'Minute', 'Sekunde'], // 4's
        compactLabels: ['l', 'm', 't', 'd'],
        whichLabels: function (amount) {
          return (amount > 4 ? 0 : amount);
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      sq: {
        labels: ['Vite', 'Muaj', 'Javë', 'Ditë', 'Orë', 'Minuta', 'Sekonda'],
        labels1: ['Vit', 'Muaj', 'Javë', 'Dit', 'Orë', 'Minutë', 'Sekond'],
        compactLabels: ['V', 'M', 'J', 'D'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      'sr-SR': {
        labels: ['Godina', 'Meseci', 'Nedelja', 'Dana', 'Časova', 'Minuta', 'Sekundi'],
        labels1: ['Godina', 'Mesec', 'Nedelja', 'Dan', 'Čas', 'Minut', 'Sekunda'],
        labels2: ['Godine', 'Meseca', 'Nedelje', 'Dana', 'Časa', 'Minuta', 'Sekunde'],
        compactLabels: ['g', 'm', 'n', 'd'],
        whichLabels: function (amount) {
          return (amount === 1 ? 1 : (amount >= 2 && amount <= 4 ? 2 : 0));
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      sr: {
        labels: ['Година', 'Месеци', 'Недеља', 'Дана', 'Часова', 'Минута', 'Секунди'],
        labels1: ['Година', 'месец', 'Недеља', 'Дан', 'Час', 'Минут', 'Секунда'],
        labels2: ['Године', 'Месеца', 'Недеље', 'Дана', 'Часа', 'Минута', 'Секунде'],
        compactLabels: ['г', 'м', 'н', 'д'],
        whichLabels: function (amount) {
          return (amount === 1 ? 1 : (amount >= 2 && amount <= 4 ? 2 : 0));
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      sv: {
        labels: ['År', 'Månader', 'Veckor', 'Dagar', 'Timmar', 'Minuter', 'Sekunder'],
        labels1: ['År', 'Månad', 'Vecka', 'Dag', 'Timme', 'Minut', 'Sekund'],
        compactLabels: ['Å', 'M', 'V', 'D'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      th: {
        labels: ['ปี', 'เดือน', 'สัปดาห์', 'วัน', 'ชั่วโมง', 'นาที', 'วินาที'],
        labels1: ['ปี', 'เดือน', 'สัปดาห์', 'วัน', 'ชั่วโมง', 'นาที', 'วินาที'],
        compactLabels: ['ปี', 'เดือน', 'สัปดาห์', 'วัน'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      tr: {
        labels: ['Yıl', 'Ay', 'Hafta', 'Gün', 'Saat', 'Dakika', 'Saniye'],
        labels1: ['Yıl', 'Ay', 'Hafta', 'Gün', 'Saat', 'Dakika', 'Saniye'],
        compactLabels: ['y', 'a', 'h', 'g'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      uk: {
        labels: ['Років', 'Місяців', 'Тижнів', 'Днів', 'Годин', 'Хвилин', 'Секунд'],
        labels1: ['Рік', 'Місяць', 'Тиждень', 'День', 'Година', 'Хвилина', 'Секунда'],
        labels2: ['Роки', 'Місяці', 'Тижні', 'Дні', 'Години', 'Хвилини', 'Секунди'],
        compactLabels: ['r', 'm', 't', 'd'],
        whichLabels: function (amount) {
          return (amount === 1 ? 1 : (amount >= 2 && amount <= 4 ? 2 : 0));
        },
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      ur: {
        labels: ['سال', 'مہينے', 'ہفتے', 'دن', 'گھنٹے', 'منٹس', 'سيکنڑز'],
        labels1: ['سال', 'ماہ', 'ہفتہ', 'دن', 'گھنٹہ', 'منٹ', 'سیکنڈز'],
        compactLabels: ['(ق)', 'سینٹ', 'ایک', 'J'],
        whichLabels: null,
        digits: ['٠', '١', '٢', '٣', '۴', '۵', '۶', '۷', '٨', '٩'],
        timeSeparator: ':',
        isRTL: true
      },
      uz: {
        labels: ['Yil', 'Oy', 'Hafta', 'Kun', 'Soat', 'Daqiqa', 'Soniya'],
        labels1: ['Yil', 'Oy', 'Hafta', 'Kun', 'Soat', 'Daqiqa', 'Soniya'],
        compactLabels: ['y', 'o', 'h', 'k'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      vi: {
        labels: ['Năm', 'Tháng', 'Tuần', 'Ngày', 'Giờ', 'Phút', 'Giây'],
        labels1: ['Năm', 'Tháng', 'Tuần', 'Ngày', 'Giờ', 'Phút', 'Giây'],
        compactLabels: ['năm', 'th', 'tu', 'ng'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      'zh-CN': {
        labels: ['年', '月', '周', '天', '时', '分', '秒'],
        labels1: ['年', '月', '周', '天', '时', '分', '秒'],
        compactLabels: ['年', '月', '周', '天'],
        compactLabels1: ['年', '月', '周', '天'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },
      'zh-TW': {
        labels: ['年', '月', '周', '天', '時', '分', '秒'],
        labels1: ['年', '月', '周', '天', '時', '分', '秒'],
        compactLabels: ['年', '月', '周', '天'],
        compactLabels1: ['年', '月', '周', '天'],
        whichLabels: null,
        digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        timeSeparator: ':',
        isRTL: false
      },




    };
  }
}

