import { tryJSON, extendOverwrite } from '../utils/objects'
import { kolDebug as debug } from '../utils/debugging'
import KOLOneTemplates from '../templates'
import { stringToHTML, addCSSFileToDoc } from '../utils/dom'
import KOLBrowsers from '../utils/browsers'
import { extend } from 'lodash-es'
import { buildSnippetUI } from '../snippetUI/snippetUI'
import BoxFormHandler from '../contestBoxShared/boxformhandler'
import { applyRecaptcha } from '../utils/captcha'

export default class KOLEmbedContestBox {
  constructor (options) {
    const myself = this

    this.options = options
    this.contestBoxOptions = this.setContestBoxOptions({})

    this.handleBuildByEvent = function (event) {
      myself.build()
      myself.updateFrameHeight()
    }

    this.handleSideBarDesignerBuild = function (event) {
      debug('Embed Designer Build Event', event)

      _kol.options.onPageActions.contestBox = myself.setContestBoxOptions(event.detail)
      myself.contestBoxOptions = _kol.options.onPageActions.contestBox

      if (_kol.options.onPageActions.contestBox.mode == 'off') {
        myself.options = _kol.options
        myself.build()
      }
    }
    this.build()
    this.setupEventListeners()
  }

  setContestBoxOptions (overrides) {
    _kol.options.onPageActions.contestBox = extendOverwrite(
      { template: 'chat' },
      _kol.options.onPageActions.contestBox,
      overrides
    )
    return _kol.options.onPageActions.contestBox
  }

  handleURLChange (event) {
    debug('Handling URL change event in embed contest box.')
    if (this) {
      this.build()
      this.updateFrameHeight()
    }
  }

  handleDOMChange (event) {
    // Should there be an embed box drawn?
    const snippets = document.querySelectorAll("[data-kol-snippet='contestbox']")
    if (snippets.length > 0) {
      // is the contest box still there?
      const contestboxWrapper = document.getElementById(
        'kol-contestbox-embed-frame'
      )
      let frameTest = false
      let built = false
      if (contestboxWrapper) {
        built = contestboxWrapper.getAttribute('data-kol-box-ready') === 'true'
        frameTest = window.frames[
          'kol-contestbox-embed-frame'
        ].contentWindow.document.getElementById('contest-footer-numbers')
      }
      if (!contestboxWrapper || (!frameTest && built)) {
        this.handleURLChange(event)
      }
    }
  }

  setupEventListeners () {
    // kol:designer:save:complete

    document.removeEventListener(
      'kol:designer:save:complete',
      this.handleBuildByEvent.bind(this),
      true
    )

    document.addEventListener(
      'kol:designer:save:complete',
      this.handleBuildByEvent.bind(this)
    )

    window.removeEventListener(
      'kol:snippets:build:contestbox',
      this.handleBuildByEvent.bind(this),
      true
    )

    window.addEventListener(
      'kol:snippets:build:contestbox',
      this.handleBuildByEvent.bind(this)
    )

    window.removeEventListener(
      'kol:onpage:contestbox:designer:build',
      this.handleSideBarDesignerBuild,
      true
    )
    window.addEventListener('kol:onpage:contestbox:designer:build',
      this.handleSideBarDesignerBuild
    )

    if (!window._kol.mode.isDesigner && !window._kol.mode.isPreview) {
      window._kol.container.removeEventListener(
        'kol:url:changed',
        this.handleURLChange,
        true
      )
      window._kol.container.addEventListener('kol:url:changed', (event) =>
        this.handleURLChange(event)
      )
      window._kol.container.removeEventListener(
        'kol:dom:changed',
        this.handleURLChange,
        true
      )
      window._kol.container.addEventListener('kol:dom:changed', (event) =>
        this.handleDOMChange(event)
      )
    }

    window._kol.container.removeEventListener(
      'kol:leadchange',
      this.handleBuildByEvent,
      true
    )
    window._kol.container.addEventListener(
      'kol:leadchange',
      this.handleBuildByEvent
    )
  }

  build () {
    if (_kol.lead.anonymous) {
      applyRecaptcha()
    }
    const snippets = document.querySelectorAll("[data-kol-snippet='contestbox']")
    const myself = this
    snippets.forEach((snippet, index) => {
      snippet.innerHTML = ''
      if (index === 0) {
        let snippetOptions = tryJSON(snippet.getAttribute('data-settings'), {
          embedHeight: 'full'
        })
        snippetOptions = extend({}, myself.defaultSettings(), snippetOptions)
        debug(
          'Writing embedded contest box frame with options:',
          snippetOptions
        )
        const contestHolder = stringToHTML(
          myself.getEmbedContestBoxFrameMarkup(
            myself.contestBoxOptions,
            snippetOptions
          )
        )

        snippet.appendChild(contestHolder.firstElementChild)
        this.writeContestFrame(snippet)
      } else {
        snippet.innerHTML =
          'Only one Contest Box embed is allowed at a time on a page for now.'
        console.log(
          'Only one contest box embed is allowed at a time on a page.'
        )
      }
    })
  }

  updateFrameHeight () {
    setTimeout(function () {
      const embedBoxFrame = document.querySelector('#kol-contestbox-embed-frame')
      if (embedBoxFrame && embedBoxFrame.getAttribute('data-height') == 'full') {
        if (embedBoxFrame.contentWindow.document.body) {
          debug('Updating Embed Frame Height to Full.')
          embedBoxFrame.contentWindow.document.body.style.height = 'auto'
          console.log(
            'Embed scroll V: ' +
              embedBoxFrame.contentWindow.document.body.scrollHeight
          )
          embedBoxFrame.style.height =
            embedBoxFrame.contentWindow.document.body.scrollHeight + 'px'
        }
      }
    }, 500)
  }

  defaultSettings () {
    return {
      embedHeight: 'full',
      showBorder: true,
      template: 'chat'
    }
  }

  getBorderStyles (options, snippetOptions) {
    if (snippetOptions.showBorder) {
      return 'box-shadow: 0 1px 3px rgba(17, 17, 17, 0.125), 0 1px 2px rgba(17, 17, 17, 0.25); border: 0.5px solid rgba(17, 17, 17, 0.125);'
    } else { return '' }
  }

  getEmbedContestBoxFrameMarkup (options, snippetOptions) {
    const kolTemplates = new KOLOneTemplates()

    return `
      <div class="kol-contestbox-contest-embed-frame-wrapper" >
        ${kolTemplates.getEmbedLoadingSpinner({ borderColor: options.colors_primaryColor, spinnerColor: options.colors_primaryColor })}
        <style>
          ${kolTemplates.getCommonContestBoxStyles()}
        </style>
        <iframe id="kol-contestbox-embed-frame" allow="clipboard-write" data-height="${
          snippetOptions.embedHeight
        }" data-heightPixels="${
      snippetOptions.embedHeightPixels
    }" name="kol-content-box-embed" style="${this.getBorderStyles(options, snippetOptions)} height: ${
      snippetOptions.embedHeightPixels
    }px; width: 100% !important;visibility:hidden;" class="kol-contestbox-contest-frame" data-kol-box-ready="false" ></iframe>

      <div>
    `
  }

  handleContestLoad (event) {
    // remove the preview.
    debug('Handling Embed Contest Frame Load', event)

    const previewSpinner = document.querySelector('#kol-embed-spinner')
    const embedBoxFrame = document.querySelector('#kol-contestbox-embed-frame')
    new BoxFormHandler('kol-contestbox-embed-frame')

    if (previewSpinner) {
      previewSpinner.remove()
    }
    if (embedBoxFrame) {
      const embedDoc = embedBoxFrame.contentWindow.document

      if (KOLBrowsers.checkBrowser() == 'Firefox') {
        addCSSFileToDoc(embedDoc, `https://fonts.googleapis.com/css?family=${this.contestBoxOptions.font}`)
        addCSSFileToDoc(embedDoc, 'https://ka-p.fontawesome.com/releases/v6.0.0/css/pro.min.css?token=413baa29c3')
        addCSSFileToDoc(embedDoc, 'https://d1y0v6ricksqp.cloudfront.net/css/bootstrap/3.4.0_simple/bootstrap.min.css')
        addCSSFileToDoc(embedDoc, _kol.options.cssPath)
      }

      embedBoxFrame.style.visibility = 'visible'
      embedBoxFrame.setAttribute('data-kol-box-ready', true)
      buildSnippetUI(null, null, embedDoc)
    }

    this.updateFrameHeight()
  }

  writeContestFrame (snippet) {
    debug('Writing embedded Contest Frame')

    const contestFrame = snippet.querySelector('#kol-contestbox-embed-frame')
    const kolTemplates = new KOLOneTemplates()
    contestFrame.addEventListener('load', (event) =>
      this.handleContestLoad(event)
    )
    contestFrame.contentWindow.document.open()

    const html = kolTemplates.getiContestFrameContent('contestBox:embed')
    contestFrame.contentWindow.document.write(html)
    contestFrame.contentWindow.document.close()
  }
}
