import { kolDebug as debug } from './debugging'

const captchaSiteKey = (localOptions) => {
  return window._kol.options.reCAPTCHA_site_key || localOptions?.form?.reCAPTCHA_site_key
}

export const applyCaptchaIfNecessary = (formData, localOptions = null) => {
  return new Promise((resolve) => {
    if ((localOptions?.form?.captcha || window._kol.options.captcha) && window.grecaptcha) {
      debug('Pre-Apply Captcha')
      window.grecaptcha.ready(() => {
        debug('Captcha Ready!')
        window.grecaptcha
          .execute(captchaSiteKey(localOptions), { action: 'submit' })
          .then((token) => {
            debug(`Captcha Data Applied: ${token}`)
            formData.__kol_captcha_response = token
            formData.__kol_captcha_version = 3
            resolve(formData)
          })
      })
    } else {
      debug('Captch Not Applied.')
      return resolve(formData)
    }
  })
}

export const applyRecaptcha = (localOptions = null) => {
  if (localOptions?.form?.captcha || window._kol.options.captcha) {
    if (window.grecaptcha) {
      debug('Google Recaptcha is already on the page')
      return
    }
    debug('Adding Google ReCaptcha to page')
    const script = document.createElement('script')
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('async', 'async')
    script.setAttribute('defer', 'defer')
    script.setAttribute(
      'src',
      `https://www.google.com/recaptcha/api.js?render=${captchaSiteKey(localOptions)}`
    )
    document.body.appendChild(script)
  }
}
