import { getData } from '../utils/data'
import { kolWarn as warn, kolDebug as debug } from '../utils/debugging'
import { tryJSON } from '../utils/objects'
import KOLStrings from '../utils/strings'

export default class KOLOneLeaderBoard {
  constructor (writeDoc) {
    const myself = this
    this.doc = writeDoc
    this.handleBuildByEvent = function (event) {
      myself.build()
    }

    this.build()
    this.setupEventListeners()
  }

  setupEventListeners () {
    this.doc.removeEventListener(
      'kol:snippets:build:leaderboard',
      this.handleBuildByEvent.bind(this),
      true
    )
    window.addEventListener(
      'kol:snippets:build:leaderboard',
      this.handleBuildByEvent.bind(this)
    )
  }

  buildUrl () {
    const campaignID =
      _kol.mode.isDesigner == true ? '1905' : _kol.options.campaignId
    const urlRoot =
      _kol.mode.isDesigner == true
        ? 'https://api.kickofflabs.com'
        : _kol.options.analytics.url
    return `${urlRoot}/v1/${campaignID}/leaderboard`
  }

  fetchLeaderBoardData (options) {
    const url = this.buildUrl()
    const dataQuery = {
      limit: options.leadLimit || 10,
      social_id: options.social_id,
      lead_description: options.leadDescription,
      include_social_id: options.includeSocialId,
      avatar_default: options.avatar_default || 'identicon'
    }
    return getData(url, dataQuery)
  }

  build () {
    const snippets = this.doc.querySelectorAll(
      "[data-kol-snippet='leaderboard']"
    )

    if (snippets.length > 0) {
      snippets.forEach((snippet, index) => {
        const options = tryJSON(snippet.getAttribute('data-leaderboard'), {})
        const innerWrapper = snippet.querySelector(
          '.kol-leaderboard-wrapper-inner'
        )

        if (innerWrapper) {
          innerWrapper.innerHTML = this.main_template(
            'kickofflabs_leaderboard_' + options.template,
            index
          )
        } else {
          snippet.innerHTML = this.main_template(
            'kickofflabs_leaderboard_' + options.template,
            index
          )
        }

        this.fetchLeaderBoardData(options)
          .then((data) => {
            debug('Leaderboard Data', data)

            const board_options = {
              container_id: snippet.getAttribute('id'),
              board_type: 'default',
              limit: options.leadLimit || 10,
              show_current_user: false,
              skip_current_lead: true,
              social_id: _kol.lead.social_id,
              rank_text: options.rankText || 'Rank',
              who_text: options.whoText || 'Who',
              score_text: options.scoreText || 'Score',
              lead_description: options.leadDescription || 'username',
              includeSocialId: options.includeSocialId || false,
              leads: data.leads,
              lead_count: data.lead_count,
              verified_lead_count: data.verified_lead_count,
              textColor: options.textColor
            }

            // get table wrapper
            const table = snippet.querySelector(
              '#kol_leader_board_results_' + index
            )

            const spinner = snippet.querySelector('#spinner')
            spinner.classList.add('hidden')
            if (options.template && this[options.template]) {
              table.innerHTML = this[options.template](board_options)
            } else {
              table.innerHTML = this.standard_template(board_options)
            }
          })
          .catch((err) => {
            warn(`Error looking up leaderboard: ${err.message}`)
          })
      })
    }
  }

  standard_template (options) {
    let leaderboard = `<thead style="color:${options.textColor}">
        <tr>
          <th class="kol-leaderboard-rank-header">${options.rank_text}</th>
        <th class="kol-leaderboard-who-header">${options.who_text}</th>
       <th class="kol-leaderboard-score-header">${options.score_text}</th>
        </tr>
      </thead><tbody style="color:${options.textColor}">`

    options.leads.forEach(function (lead) {
      leaderboard += `<tr class="${lead.user_class}">
            <td class="rank">${lead.board_rank}</td>
            <td class="score">${lead.lead_description}</td>
            <td class="user">${lead.score}</td>
          </tr>`
    })

    leaderboard += ' </tbody>'
    return leaderboard
  }

  minimal_template (options) {
    let leaderboard = `<tbody style="color:${options.textColor}">`

    options.leads.forEach(function (lead) {
      leaderboard += ` <tr>
        <td class="rank">#${lead.board_rank}</td>
        <td class="score">${lead.score}  ${KOLStrings.getPointsWord(
        lead.score
      )}</td>
        <td class="user">${lead.lead_description}</td>
      </tr>`
    })

    leaderboard += ' </tbody>'
    return leaderboard
  }

  minimal_avatar_template (options) {
    let leaderboard = `<tbody style="color:${options.textColor}">`

    options.leads.forEach(function (lead) {
      leaderboard += ` <tr>
        <td class="avatar"><img src="${lead.avatar}" alt="${
        lead.lead_description
      }"></td>
        <td class="rank-score">${lead.board_rank}</br> ${
        lead.score
      }  ${KOLStrings.getPointsWord(lead.score)}</td>
        <td class="user">${lead.lead_description}</td>
        </tr>`
    })

    leaderboard += ' </tbody>'
    return leaderboard
  }

  minimal_avatar_compact_template (options) {
    let leaderboard = `<tbody style="color:${options.textColor}">`

    options.leads.forEach(function (lead) {
      leaderboard += ` <tr>
        <td class="avatar"><img src="${lead.avatar}" alt="${
        lead.lead_description
      }"></td>
        <td class="score">${lead.score} ${KOLStrings.getPointsWord(
        lead.score
      )}</td>
        <td class="user">${lead.lead_description}</td>
        </tr>`
    })

    leaderboard += ' </tbody>'
    return leaderboard
  }

  main_template (className, index) {
    return `
      <div id="kol_leaderboard_main_wrapper">
        <div id="kol_leader_board_table_wrapper">
          <div id="spinner" style="position: relative; text-align:middle; height:200px;"><i style="font-size:50px; class="fas fa-circle-notch fa-spin"></i></div>
          <table id="kol_leader_board_results_${index}" class="${className} kol-leaderboard table table-striped table-hover">
          </table>
        </div>
        <div id="kol_current_user_wrapper">
          <div id="kol_current_user">
          </div>
        </div>
      </div>
     `
  }
}
