import { toPairs, isObject } from "lodash-es";

const parentWrapper = (key) => encodeURIComponent(`[${key}]`);

const removeEmpty = (items) => {
  const filteredItems = items.filter((item) => {
    const value = item[1];
    return value !== undefined && value !== "";
  });
  return filteredItems;
};

const toParam = (object, parent = null) => {
  const pairs = removeEmpty(toPairs(object));
  const params = pairs
    .map((item) => {
      const [key, value] = item;

      if (isObject(value)) {
        return toParam(value, key);
      }

      if (parent) {
        return `${parentWrapper(parent)}${key}=${encodeURIComponent(value)}`;
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return params;
};

export default toParam;
