import { kolDebug as debug } from './utils/debugging'
import { extendOverwrite } from './utils/objects'
import { fadeIn, fadeOut } from './utils/animations'
import exitIntent from 'exit-intent'
import { stringToHTML, addCSSFileToDoc } from './utils/dom'
import { buildSnippetUI } from './snippetUI/snippetUI'
import { ready, triggerContestBox } from './utils/events'
import KOLOneTemplates from './templates'
import storage from 'local-storage-fallback'
import KOLBrowsers from './utils/browsers'
import KOLStrings from './utils/strings'
import BoxFormHandler from './contestBoxShared/boxformhandler'
import { applyRecaptcha } from './utils/captcha'

// mode = status_only

// TODO: Can this be split up as a constructor for each action?
// TODO: Right now all static feels odd... but what about multiple ones?
export default class ContestBox {
  constructor (options = null) {
    this.options = options || window._kol.options
    this.contestBoxOptions = this.setContestBoxOptions({})

    const self = this
    this.handleSideBarDesignerBuild = function (event) {
      debug('Contest Box Designer Build Event', event)

      _kol.options.onPageActions.contestBox = self.setContestBoxOptions(event.detail)
      self.contestBoxOptions = _kol.options.onPageActions.contestBox
      self.options = _kol.options
      if (_kol.options.onPageActions.contestBox.mode != 'off') {
        self.refreshContestBoxHolder()
        self.writeButtonFrame()
        self.writeContestFrame()

        const cb_holder = document.getElementById('kol_contestbox_container')
        cb_holder.classList.remove('kol-contestbox-position-bottomleft')
        cb_holder.classList.remove('kol-contestbox-position-bottomright')
        cb_holder.classList.add('kol-contestbox-position-' + event.detail.position)

        self.openSidebar()
      }
    }

    if (!this.contestBoxOptions) {
      debug('No Contest Box Options. Shutting down!')
    } else {
      this.setupOpenEventListeners()
      debug('Loading Contest Box with Options: ', this.contestBoxOptions)
      this.contestBoxLoad()
    }
  }

  setContestBoxOptions (overrides) {
    _kol.options.onPageActions.contestBox = extendOverwrite(
      { template: 'chat' },
      _kol.options.onPageActions.contestBox,
      overrides
    )
    return _kol.options.onPageActions.contestBox
  }

  build_regex_pattern (pattern) {
    let reg
    if (typeof pattern === 'string') {
      reg = new RegExp(pattern, 'i')
      debug('contest box URL restrictions: ', reg)
      return reg
    } else {
      debug('contest box URL restrictions: ', pattern)
      return pattern
    }
  }

  should_skip_url (testPattern) {
    // Actually tests URL restrictions against the window location.
    // Returning true will skip the initialization of the widget.
    let i, len
    if (Array.isArray(testPattern)) {
      for (i = 0, len = testPattern.length; i < len; i++) {
        url = testPattern[i]
        if (this.should_skip_url(url)) {
          return true
        }
      }
    } else if (
      this.build_regex_pattern(testPattern).test(window.location.href)
    ) {
      debug('Contest box not allowed on URL Pattern: ', testPattern)
      return true
    } else {
      return false
    }
  }

  isSupportedContestBoxURL () {
    const excludedUrlString = this.contestBoxOptions.excluded_URLS
    if (excludedUrlString) {
      const excludedUrlRegexExpression = excludedUrlString
        .trim()
        .split(/\r\n|,/)
        .join('|')
        .replace(/\*/gi, '.*')
      debug('Excluded URL Regex: ', excludedUrlRegexExpression)
      return !this.should_skip_url(excludedUrlRegexExpression)
    } else {
      return true
    }
  }

  isOkToLoadContestBox (forceLoad) {
    const embedBoxes = document.querySelectorAll(
      "[data-kol-snippet='contestbox']"
    )

    const shouldBeLoaded =
      forceLoad ||
      (this.contestBoxOptions.mode === 'full' &&
        this.isSupportedContestBoxURL() &&
        embedBoxes.length === 0)

    const shouldBeLoadedBecauseOfModeAndKnownLead =
      this.contestBoxOptions.mode === 'status_only' &&
      !window._kol.lead.anonymous && embedBoxes.length === 0

    if (shouldBeLoaded && !shouldBeLoadedBecauseOfModeAndKnownLead && _kol.lead.anonymous) {
      applyRecaptcha()
    }
    return shouldBeLoaded || shouldBeLoadedBecauseOfModeAndKnownLead
  }

  contestBoxLoad (forceLoad = false) {
    debug(`ContestBoxLoad forced:${forceLoad}`)

    if (this.isOkToLoadContestBox(forceLoad)) {
      if (!window.kolContestBox) {
        this.contestBox()
        this.setupEventListeners()
      } else {
        debug('Cannot load ContestBox twice')
      }
    } else {
      debug('ContestBox can not be loaded')
      // setup event listener for page changes if contest box allowed
      this.setupURLChangeListener()
    }
  }

  handleMessage (event) {
    debug('Handling Message in Contest_box.js', event)
    if (event.data.type === 'kol:activate:ContestBox') {
      const evt = new CustomEvent('kol:leadchange', { detail: { lead: event.data.data } })
      window._kol.container.dispatchEvent(evt)
      triggerContestBox(event.data.data)
    }
  }

  handleExternalContestBoxLoadRequest (event) {
    debug('external ContestBox request')
    this.contestBoxLoad(true)
    this.openSidebar()
  }

  handleContestLoad (event) {
    // remove the preview.
    debug('Handling Contest Box Load', event)
    new BoxFormHandler('kol-contestbox-contest-frame')
    this.displayLeadInfo(window._kol.lead)
    this.displayShareLeadInfo(window._kol.shareLead)

    buildSnippetUI(null, null, event.currentTarget.contentWindow.document)
    const previewSpinner = document.querySelector('#kol-spinner-wrapper')
    if (KOLBrowsers.checkBrowser() == 'Firefox') {
      const contestFrame = document.getElementById('kol-contestbox-contest-frame')
      const embedDoc = contestFrame.contentWindow.document
      addCSSFileToDoc(embedDoc, `https://fonts.googleapis.com/css?family=${this.contestBoxOptions.font}`)
      addCSSFileToDoc(embedDoc, 'https://ka-p.fontawesome.com/releases/v6.0.0/css/pro.min.css?token=413baa29c3')
      addCSSFileToDoc(embedDoc, 'https://d1y0v6ricksqp.cloudfront.net/css/bootstrap/3.4.0_simple/bootstrap.min.css')
      addCSSFileToDoc(embedDoc, _kol.options.cssPath)
    }
    if (previewSpinner) {
      previewSpinner.remove()
    }
  }

  handleURLChange (event) {
    debug('Handling URL change event in contest box.')
    if (this) {
      if (this.isOkToLoadContestBox()) {
        this.refreshContestBoxHolder()

        this.writeButtonFrame()

        this.writeContestFrame()
      } else {
        // remove contest box if it shouldn't be there.
        const contestboxWrapper = document.getElementById(
          'kol_contestbox_container'
        )
        if (contestboxWrapper) {
          debug(
            "Removing existing contest box bc it's not allowed here.",
            event
          )
          contestboxWrapper.parentNode.removeChild(contestboxWrapper)
        }
      }
    }
  }

  handleDOMChange (event) {
    // is the contest box still there?
    const contestboxWrapper = document.getElementById('kol_contestbox_container')
    const frameTest = false
    if (contestboxWrapper) {
      const frameWindow = window.frames['kol-contestbox-button-frame']
      if (frameWindow) {
        frameWindow.contentWindow.document.getElementById('kol-button-wrapper')
      }
    }
    if (!contestboxWrapper || !frameTest) {
      debug('Contest box not found. Reloading.')
      this.handleURLChange(event)
    }
  }

  /* Setup Event Listeners */

  displayLeadInfo (lead) {
    if (!lead) return
    const image = lead.anonymous ? '' : lead.avatar
    const imageAlt = lead.anonymous ? '' : lead.username
    let pointText = ` - ${lead.contest_score} ${KOLStrings.getPointsWord(
          lead.contest_score
        )}`
    if (!this.contestBoxOptions.display_leadPoints) {
      pointText = ''
    }
    const text = lead.anonymous
      ? this.contestBoxOptions.content_notificationButtonText || this.contestBoxOptions.content_aboveHeroText
      : `${this.contestBoxOptions.content_notificationSignedIn}&nbsp;${
          lead.given_name ? lead.given_name : ''} ${pointText}`

    this.updateShowNotification({ image, imageAlt, text })
  }

  displayShareLeadInfo (lead) {
    if (!lead) return
    if (window._kol.lead && !window._kol.lead.anonymous) return
    this.updateShowNotification({
      image: lead.avatar,
      imageAlt: lead.username,
      text:
        (lead.given_name || lead.id) +
        ' ' +
        this.contestBoxOptions.content_notificationInvitedBy
    })
  }

  handleKnownLead (event) {
    debug('Known Lead Event in ContestBox', event)
    this.displayLeadInfo(event.detail.lead)
  }

  handleKnownShareLead (event) {
    debug('Known Share Lead Event', event)
    const lead = event.detail.lead
    debug('Share Known Lead', lead || 'Not Available')
    this.displayShareLeadInfo(lead)
  }

  // Just to see if we can later load a contest box
  setupURLChangeListener () {
    // Listen for messages in the parent window
    window.addEventListener('message', (event) => {
      this.handleMessage(event)
    }
    )
    if (!window._kol.mode.isDesigner && !window._kol.mode.isPreview) {
      window.removeEventListener('kol:url:changed', this.handleURLChange, true)
      window.addEventListener('kol:url:changed', (event) =>
        this.handleURLChange(event)
      )
    }
  }

  setupOpenEventListeners () {
    window._kol.container.addEventListener(
      'kol:activate:ContestBox',
      (event) => {
        this.handleExternalContestBoxLoadRequest(event)
      }
    )
    window._kol.container.addEventListener(
      'kol:close:ContestBox',
      (event) => {
        this.closeContestBoxSetLastClosed()
      }
    )
    // if exit intent on AND it's an anonymous visitor. Open the contest box.
    if (this.contestBoxOptions.display_onExitIntent && window._kol.lead.anonymous) {
      setTimeout(() => {
        const removeExitIntent = exitIntent({
          threshold: 50, // distance from top of page to trigger
          maxDisplays: 3, // total times the popup will trigger
          eventThrottle: 100, // how long between triggers
          onExitIntent: () => {
            debug('Exit Intent Triggered for contest box')
            const openEvent = new CustomEvent('kol:activate:ContestBox', {})
            // Don't run if KOL script isn't installed.
            window._kol.container.dispatchEvent(openEvent)
          }
        })
      }, 1000)
    }

    this.setupURLChangeListener()

    window.removeEventListener(
      'kol:onpage:contestbox:designer:build',
      this.handleSideBarDesignerBuild,
      true
    )
    window.addEventListener('kol:onpage:contestbox:designer:build',
      this.handleSideBarDesignerBuild
    )
  }

  setupEventListeners () {
    debug('Adding Contest Box Event Handlers')

    if (!window._kol.mode.isDesigner && !window._kol.mode.isPreview) {
      window.removeEventListener('kol:dom:changed', this.handleURLChange, true)
      window.addEventListener('kol:dom:changed', (event) =>
        this.handleDOMChange(event)
      )
    }

    window.removeEventListener('kol:knownlead', this.handleKnownLead, true)
    window.addEventListener('kol:knownlead', (event) =>
      this.handleKnownLead(event)
    )

    window.removeEventListener(
      'kol:knownsharelead',
      this.handleKnownShareLead,
      true
    )
    window.addEventListener('kol:knownsharelead', (event) =>
      this.handleKnownShareLead(event)
    )
  }

  closeContestBoxSetLastClosed () {
    this.closeSidebar()
    storage.setItem(
      `kola.${this.options.campaignId}.sidebarLastClosed`,
      Date.now()
    )
  }

  writeButtonFrame () {
    debug('Writing Contest Button Frame if Applicable to the template')
    const buttonFrame = document.getElementById('kol-contestbox-button-frame')
    if (!buttonFrame) return
    const kolTemplates = new KOLOneTemplates()
    buttonFrame.contentWindow.document.open('text/html', 'replace')
    buttonFrame.contentWindow.document.write(
      kolTemplates.getButtonFrameContent()
    )
    buttonFrame.contentWindow.document.close()
    const openBtn = buttonFrame.contentWindow.document.getElementById(
      'kol-button-wrapper'
    )
    openBtn.onclick = () => {
      const contestboxContest = document.querySelector(
        '.kol-contestbox-contest-frame-holder'
      )
      if (contestboxContest.classList.contains('kol-contest-open')) {
        this.closeContestBoxSetLastClosed()
      } else {
        this.openSidebar()
      }
    }
  }

  writeContestFrame () {
    debug('Writing Contest Frame')
    const contestFrame = document.getElementById('kol-contestbox-contest-frame')
    const kolTemplates = new KOLOneTemplates()
    contestFrame.addEventListener('load', (event) =>
      this.handleContestLoad(event)
    )
    contestFrame.contentWindow.document.open()

    const html = kolTemplates.getiContestFrameContent('contestBox')
    contestFrame.contentWindow.document.write(html)
    contestFrame.contentWindow.document.close()

    const background = window.document.getElementById(
      'kol_contestbox_container'
    )
    background.onclick = () => {
      const contestboxContest = document.querySelector(
        '.kol-contestbox-contest-frame-holder'
      )
      if (contestboxContest.classList.contains('kol-contest-open')) {
        this.closeSidebar()
        storage.setItem(
          `kola.${this.options.campaignId}.sidebarLastClosed`,
          Date.now()
        )
      } else {
        this.openSidebar()
      }
    }
  }

  hideNotificationBar () {
    const buttonFrame = document.getElementById('kol-contestbox-button-frame')
    const notice = buttonFrame.contentWindow.document.getElementById(
      'kol-notification'
    )
    if (!buttonFrame) return
    buttonFrame.classList.remove('kol-show-notification')
    notice.classList.remove('kol-show-notification')
    storage.setItem(
      `kola.${this.options.campaignId}.notificationLastSeen`,
      Date.now()
    )
  }

  showNotificationBar () {
    if (
      _kol.mode.isDesigner ||
      !storage.getItem(`kola.${this.options.campaignId}.notificationLastSeen`)
    ) {
      const buttonFrame = document.getElementById('kol-contestbox-button-frame')
      if (!buttonFrame) return
      const notice = buttonFrame.contentWindow.document.getElementById(
        'kol-notification'
      )
      buttonFrame.classList.add('kol-show-notification')
      notice.classList.add('kol-show-notification')
      storage.setItem(
        `kola.${this.options.campaignId}.notificationLastSeen`,
        Date.now()
      )
    }
  }

  openSidebar () {
    const contestboxContest = document.querySelector(
      '.kol-contestbox-contest-frame-holder'
    )
    const contestboxWrapper = document.getElementById('kol_contestbox_container')

    const buttonFrame = document.getElementById('kol-contestbox-button-frame')
    let buttonWrapper = null
    if (buttonFrame) {
      buttonWrapper = buttonFrame.contentWindow.document.getElementById(
        'kol-button-wrapper'
      )
      buttonWrapper.classList.add('kol-contest-open')
      this.hideNotificationBar()
    }

    contestboxWrapper.classList.add('kol-contest-open')
    contestboxContest.classList.add('kol-contest-open')

    fadeIn(contestboxContest, 500)
  }

  closeSidebar () {
    const contestboxWrapper = document.getElementById('kol_contestbox_container')

    const contestboxContest = document.querySelector(
      '.kol-contestbox-contest-frame-holder'
    )
    const buttonFrame = document.getElementById('kol-contestbox-button-frame')

    if (buttonFrame) {
      const buttonWrapper = buttonFrame.contentWindow.document.getElementById(
        'kol-button-wrapper'
      )
      buttonWrapper.classList.remove('kol-contest-open')
      this.showNotificationBar()
    }

    // TODO: Figure out how to read the options in here for things like speed.
    fadeOut(contestboxContest, 500)
    setTimeout(() => {
      contestboxContest.classList.remove('kol-contest-open')
      contestboxWrapper.classList.remove('kol-contest-open')
    }, 500)
  }

  updateShowNotification (options) {
    const buttonFrame = document.getElementById('kol-contestbox-button-frame')

    if (buttonFrame) {
      const notificationImage = buttonFrame.contentWindow.document.getElementById(
        'kol-notification-image'
      )
      const notificationText = buttonFrame.contentWindow.document.getElementById(
        'kol-notification-text'
      )
      const imageHolder = buttonFrame.contentWindow.document.getElementsByClassName(
        'kol-button-notification-image'
      )

      if (options.image) {
        Object.assign(notificationImage, {
          src: options.image,
          alt: options.imageAlt
        })
        Object.assign(imageHolder[0].style, {
          display: 'inline'
        })

        notificationText.classList.add('with-kol-image')
      } else {
        Object.assign(imageHolder[0].style, {
          display: 'none'
        })
        notificationText.classList.remove('with-kol-image')
      }
      notificationText.innerHTML = options.text
      // kol-contestbox-contest-frame-holder kol-contest-open
      const contestboxContest = document.querySelector(
        '.kol-contestbox-contest-frame-holder'
      )
      if (!contestboxContest.classList.contains('kol-contest-open')) {
        this.showNotificationBar()
      }
    }
  }

  refreshContestBoxHolder () {
    const cb_holder = document.getElementById('kol_contestbox_container')

    if (cb_holder) {
      cb_holder.parentNode.removeChild(cb_holder)
    }

    debug('launching contestbox')

    debug('box Options', this.contestBoxOptions)

    // create objects we need.
    const contestboxContainer = document.createElement('div')

    Object.assign(contestboxContainer, {
      id: 'kol_contestbox_container'
    })

    contestboxContainer.className = 'kol-contestbox-container kol-contestbox-template-' + this.contestBoxOptions.template + ' kol-contestbox-position-' + this.contestBoxOptions.position || 'bottomright'

    Object.assign(contestboxContainer.style, {
      bottom: 0,
      position: 'fixed'
    })

    const kolTemplates = new KOLOneTemplates()

    const openButtonHolder = stringToHTML(kolTemplates.getButtonFrameMarkup(this.contestBoxOptions))

    const contestHolder = stringToHTML(
      kolTemplates.getContestFrameMarkup(this.contestBoxOptions)
    )

    if (openButtonHolder && openButtonHolder.firstElementChild) {
      contestboxContainer.appendChild(openButtonHolder.firstElementChild)
    }
    contestboxContainer.appendChild(contestHolder.firstElementChild)

    document.body.appendChild(contestboxContainer)

    return contestboxContainer
  }

  // Check if last closed < a day a go. If so don't show it again automatically. Show once/day.
  shouldAutoOpen () {
    const lastClosed = storage.getItem(`kola.${this.options.campaignId}.sidebarLastClosed`)
    if (!lastClosed || window._kolDebuggingEnabled) {
      return true
    } else {
      const now = new Date()
      const days = (now - lastClosed) / (1000 * 3600 * 24)
      if (days > 1) {
        return true
      }
    }
    return false
  }

  /* Template Functions */
  contestBox () {
    const fn = () => {
      this.refreshContestBoxHolder()

      this.writeButtonFrame()

      this.writeContestFrame()

      window.kolContestBox = true

      if (_kol.mode.isDesigner || _kol.mode.isAnonymousPreview) {
        this.openSidebar()
      } else {
        if (this.contestBoxOptions.openAfter > -1 && this.shouldAutoOpen()) {
          const self = this
          setTimeout(function () {
            debug('Opening contest box in ' + self.contestBoxOptions.openAfter)
            self.openSidebar()
          }, this.contestBoxOptions.openAfter * 1000)
        }
      }
    }

    ready(fn.bind(this), 'SideBar')
  }
}
