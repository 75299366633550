import Timer from 'easytimer.js'
import { tryJSON } from '../utils/objects'
import KOLCountdownUtilities from '../utils/countdown-utilities'
import KOLCountdownLanguages from './countdown-languages.js'

export default class KOLCountdown {
  constructor (options, writeDoc) {
    this.options = options
    this.doc = writeDoc
    this.regionalOptions = KOLCountdownLanguages.getRegionalOptions()

    const myself = this

    this.handleBuildByEvent = function (event) {
      myself.build(myself.options)
    }

    this._handleClockUpdate = function (timer, snippet) {
      const layout = snippet.getAttribute('data-layout')
      if (layout && myself['layout_' + layout]) {
        // TODO - wrapping for errors so the rest always works?
        snippet.innerHTML = myself['layout_' + layout](timer, snippet)
      } else {
        snippet.innerHTML = timer.getTimeValues().toString()
      }
    }

    this.build(options)
  }

  build (options) {
    const snippets = this.doc.querySelectorAll("[data-kol-snippet='countdown']")
    snippets.forEach((snippet, index) => {
      const timer = new Timer()
      const snippetOptions = this.getSnippetOptions(snippet)

      const utcEndDate = KOLCountdownUtilities.getUTCDate(
        snippetOptions.endDate,
        snippetOptions.utcOffSet
      )
      const remaining = KOLCountdownUtilities.getTimeRemaining(utcEndDate)
      timer.start({
        countdown: true,
        startValues: { seconds: remaining.total / 1000 }

      })

      const myself = this
      // write initial if time has already passed.
      myself._handleClockUpdate(timer, snippet)
      timer.addEventListener('secondsUpdated', function () {
        myself._handleClockUpdate(timer, snippet)
      })

      // something special when ended?
      timer.addEventListener('targetAchieved', function (e) {
        const evt = new CustomEvent('kol:campaign:countdown:end', { detail: null })
        window.dispatchEvent(evt)
      })

      //
    })

    this.setupEventListeners()
  }

  setupEventListeners () {
    window.removeEventListener(
      'kol:snippets:build:countdown',
      this.handleBuildByEvent.bind(this),
      true
    )
    window.addEventListener(
      'kol:snippets:build:countdown',
      this.handleBuildByEvent.bind(this)
    )
  }

  getSnippetOptions (snippet) {
    let endDate, utcOffSet, language

    if (snippet.getAttribute('data-end-date')) {
      endDate = snippet.getAttribute('data-end-date')
    } else {
      if (this.options.countdown && this.options.countdown.end_date) {
        endDate = this.options.countdown.end_date
      }
    }

    if (snippet.getAttribute('data-utc-offset')) {
      utcOffSet = snippet.getAttribute('data-utc-offset')
    } else {
      if (
        this.options.countdown &&
        this.options.countdown.utc_offset
      ) {
        utcOffSet = this.options.countdown.utc_offset
      }
    }

    if (snippet.getAttribute('data-language')) {
      language = snippet.getAttribute('data-language')
    } else {
      if (this.options.localization.language) {
        language = this.options.localization.language
      } else {
        language = 'en-us'
      }
    }

    snippet.setAttribute('data-current-language', language)
    if (endDate) {
      endDate = new Date(endDate.replace(/-/g, '/'))
    } else {
      endDate = new Date()
      endDate.setDate(endDate.getDate() - 1)
    }
    return {
      endDate,
      utcOffSet,
      language: language || 'en-us'

    }
  }

  layout_minimal (timer, snippet) {
    let value

    let word
    let showValue = false
    const settings = tryJSON(snippet.getAttribute('data-settings'), {})
    const language = snippet.getAttribute('data-current-language') || 'en-us'
    const textColor = settings.textColor
    let alignment = 'center'
    if (settings.alignment) {
      alignment = settings.alignment
    }
    const regionStrings = KOLCountdownLanguages.getRegionalStrings(language)

    let countdownRowHtml = `<div class="flex-row text-${alignment} flex flex-no-wrap kol-no-save-js kol-countdown-row" style="color:${textColor};">`

    if (timer.getTimeValues().days >= 0) {
      showValue = true
      value = timer.getTimeValues().days
      word =
        value == 1 ? regionStrings.labels1[3] : regionStrings.labels[3]
      countdownRowHtml += `<div class="flex-1">
        <div class="kol-countdown-value footer-number">${value}</div>
        <div class="kol-countdown-word footer-number-description">${word}</div>
        </div>`
    }
    if (timer.getTimeValues().hours >= 0 || showValue == true) {
      showValue = true
      value = timer.getTimeValues().hours
      word =
        value == 1 ? regionStrings.labels1[4] : regionStrings.labels[4]
      countdownRowHtml += `<div class="flex-1">
        <div class="kol-countdown-value footer-number">${value}</div>
        <div class="kol-countdown-word footer-number-description">${word}</div>
        </div>`
    }
    if (timer.getTimeValues().minutes >= 0 || showValue == true) {
      showValue = true
      value = timer.getTimeValues().minutes
      word =
        value == 1 ? regionStrings.labels1[5] : regionStrings.labels[5]
      countdownRowHtml += `<div class="flex-1">
        <div class="kol-countdown-value footer-number">${value}</div>
        <div class="kol-countdown-word footer-number-description">${word}</div>
        </div>`
    }
    if (timer.getTimeValues().seconds >= 0 || showValue == true) {
      showValue = true
      value = timer.getTimeValues().seconds

      word =
        value == 1 ? regionStrings.labels1[6] : regionStrings.labels[6]
      countdownRowHtml += `<div class="flex-1">
        <div class="kol-countdown-value footer-number">${value}</div>
        <div class="kol-countdown-word footer-number-description">${word}</div>
        </div>`
    }

    countdownRowHtml += '</div>'

    return countdownRowHtml
  }

  layout_oneSignificantTime (timer, snippet) {
    let value

    let word

    const language = snippet.getAttribute('data-current-language')
    const regionStrings = KOLCountdownLanguages.getRegionalStrings(language)

    if (timer.getTimeValues().days > 0) {
      value = timer.getTimeValues().days
      word =
        value == 1 ? regionStrings.labels1[3] : regionStrings.labels[3]
    } else if (timer.getTimeValues().hours > 0) {
      value = timer.getTimeValues().hours
      word =
        value == 1 ? regionStrings.labels1[4] : regionStrings.labels[4]
    } else if (timer.getTimeValues().minutes > 0) {
      value = timer.getTimeValues().minutes
      word =
        value == 1 ? regionStrings.labels1[5] : regionStrings.labels[5]
    } else if (timer.getTimeValues().seconds >= 0) {
      value = timer.getTimeValues().seconds

      word =
        value == 1 ? regionStrings.labels1[6] : regionStrings.labels[6]
    } else {
      if (language == 'en-us') {
        value = '-'
        word = 'Done'
      } else {
        value = 0
        word = regionStrings.labels[6]
      }
    }

    return `
<div class="kol-countdown kol-countdown-wrapper kol-no-save-js kol-countdown-onesig">
<div class="kol-countdown-value footer-number">${value}</div>
<div class="kol-countdown-word footer-number-description">${word}</div>
</div>


`
  }
}
