import { extend, fromEntries } from "../utils/objects";

export const generatePreviewLead = (
  anonymous = false,
  location = window.location
) => {
  const params = fromEntries(new URLSearchParams(location.search));
  const defaultPreviewLead = {
    id: "ABC",
    campaign_id: window._kol.options.campaignId,
    completed_actions: [],
    contest_score: 6,
    contest_score_rank: 3,
    contest_score_rank_relative_position: 2,
    enhanced: true,
    given_name: "Joe",
    family_name: "Biden",
    rank: 1,
    rank_relative_position: 0,
    referrals: 0,
    social_id: "ABC",
    lead_count: 9,
    verified: false,
    waitlisted: true,
    when: new Date().toString(),
  };

  defaultPreviewLead.anonymous = anonymous;

  if (!anonymous) {
    defaultPreviewLead.username = "joeb";
  }

  const lead = extend({}, defaultPreviewLead, params);
  lead.id = lead.social_id =
    params.kid || params.social_id || params.id || defaultPreviewLead.id;
  lead.social_url = generateSocialUrl(lead.id);

  lead.anonymous = ensureBoolean(lead.anonymous);
  lead.enhanced = ensureBoolean(lead.enhanced);
  lead.verified = ensureBoolean(lead.verified);

  lead.campaign_id = ensureNumber(lead.campaign_id);
  lead.contest_score = ensureNumber(lead.contest_score);
  lead.const_score_rank = ensureNumber(lead.const_score_rank);
  lead.contest_score_rank_relative_position = ensureNumber(
    lead.contest_score_rank_relative_position
  );
  lead.rank = ensureNumber(lead.rank);
  lead.rank_relative_position = ensureNumber(lead.rank_relative_position);
  lead.lead_count = ensureNumber(lead.lead_count);
  return lead;
};

const ensureBoolean = (value) => {
  return (
    value === true ||
    value === "true" ||
    value === "t" ||
    value === 1 ||
    value === "1"
  );
};

const ensureNumber = (value) => {
  if (typeof value !== "number") {
    return parseInt(value);
  }
  return value;
};

const generateSocialUrl = (id) => {
  const socialUrl = window._kol.options.share_url || window.location.toString();
  const url = new URL(socialUrl);
  url.searchParams.set("kid", id);
  return url.toString();
};
