import { kolDebug as debug } from './utils/debugging'

export default class UrlListener {
  onUrlChangeFunction () {
    let event

    if (this.previousUrl && (window.location.href !== this.previousUrl)) {
      this.previousUrl = window.location.href
      debug(`URL changed to ${window.location.href}`)
      event = new CustomEvent('kol:url:changed', {
        bubbles: true,
        detail: {}
      })
    } else {
      event = new CustomEvent('kol:dom:changed', {
        bubbles: true,
        detail: {}
      })
    }

    // don't force refreshes in the KOL dashbaord.
    if (!window._kol.mode.isDesigner && !window._kol.mode.isPreview) {
      window.document.dispatchEvent(event)
    }
  }

  constructor () {
    this.previousUrl = false
    try {
      window.top.removeEventListener('popstate', this.onUrlChangeFunction)
      window.top.addEventListener('popstate', this.onUrlChangeFunction)
      const myself = this
      new MutationObserver(function (mutations) {
        myself.onUrlChangeFunction()
      }).observe(document, { subtree: false, childList: false })
    } catch {
      debug('Unable to start URL watcher')
    }
  }
}
