export default class KOLStrings {
  static getPointsWord (score) {
    if (score == 1) {
      return _kol.options.localization.pointsWordSingular || 'Point'
    } else {
      return _kol.options.localization.pointsWordPlural || 'Points'
    }
  }

  static cleanFieldName (str) {
    if (str) {
      return str.replace(/[^A-Z0-9]+/gi, '_').toLowerCase()
    } else {
      return ''
    }
  }

  static getPointsToNextReward () {
    if (_kol.options.rewards) {
      let pointsTill = 0

      if (!_kol.lead) {
        return _kol.options.rewards[0].points
      }

      for (const reward of _kol.options.rewards) {
        if (_kol.lead.contest_score < parseInt(reward.points)) {
          pointsTill = parseInt(reward.points) - _kol.lead.contest_score
          return pointsTill
        }
      }
      return pointsTill
    } else {
      return '-'
    }
  }
}
