export default class KOLscrollTo {
  constructor(writeDoc) {
    this.doc = writeDoc;

    let snippets = this.doc.querySelectorAll("[data-kol-snippet='scrollToForm']");
    snippets.forEach((snippet, index) => {
      
      snippet.onclick = null;
      snippet.onclick = (event) => {
        event.preventDefault();
        const forms = this.doc.querySelectorAll(".kol-signup-form");
        const form = this.firstVisibleElement(forms);
        if (form){
          form.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        }
        else{
          if(forms.length > 0){
            this.doc.documentElement.scrollTop = forms[0].offsetTop
          }
        }
        
        
      }
   
     
    
    });


  }


  firstVisibleElement(elemArray){
    for (var i = 0, max = elemArray.length; i < max; i++) {
      if (elemArray[i].offsetWidth > 0 && elemArray[i].offsetHeight > 0){
        return elemArray[i];
      }
    }
    return null;
  }


}
