import storage from "local-storage-fallback";

const cacheKey = () => {
 return `form.${_kol.options.campaignId}`
}

export const savePreviousForm = (leadData) => {
 let copiedLeadData = {...leadData}
 Object.keys(copiedLeadData).forEach((key) => {
   if (key.startsWith("__")) {
     delete copiedLeadData[key]
   }
 })
 storage.setItem(cacheKey(), JSON.stringify(copiedLeadData));
 _kol.previousFormData = copiedLeadData;
}

export const fetchPreviousForm = () => {
 if (!_kol.previousFormData) {
  let previousFormData = storage.getItem(cacheKey())
 
  if (previousFormData) {
   _kol.previousFormData = JSON.parse(previousFormData)
  } else {
   _kol.previousFormData = {};
  }
 }

 return _kol.previousFormData;
}

export const fetchPreviousFormItem = (key) => {
 return (_kol.previousFormData || {})[key]
}