import merge from 'deepmerge'
import Color from 'color'

export const extend = (...objects) => merge.all([...objects]) // eslint-disable-line import/prefer-default-export, max-len

export function extend2 (...objects) {
  return merge.all([...objects])
}

export function extendOverwrite (...objects) {
  const overwriteMerge = (destinationArray, sourceArray, options) =>
    sourceArray
  return merge.all([...objects], { arrayMerge: overwriteMerge })
}

export const tryColor = (rawData, defaultData = null) => {
  try {
    return Color(rawData) || Color(defaultData)
  } catch (_) {
    return Color(defaultData)
  }
}

export const tryJSON = (rawData, defaultData = null) => {
  try {
    return JSON.parse(rawData) || defaultData
  } catch (_) {
    return defaultData
  }
}

export const getBorderRadius = (settings) => {
  switch (settings.borderStyle) {
    case 'rounded':
      return settings.borderRadius || '4'
    case 'square':
      return '0'
    default:
      return '4'
  }
}

export const removeUndefined = (obj) => {
  Object.keys(obj).forEach((key) =>
    obj[key] === undefined ? delete obj[key] : {}
  )
  return obj
}

export const fromEntries = (entries) => {
  if (Object.fromEntries) {
    return Object.fromEntries(entries)
  } else {
    const object = {}
    for (const [key, value] of entries) {
      object[key] = value
    }
    return object
  }
}
