import Cookies from 'js-cookie'

export default class KOLCookie {
  static get (key) {
    return Cookies.get(key)
  }

  static set (key, value) {
    return Cookies.set(key, value, this.cookieOptions(1000))
  }

  static session (key, value) {
    return Cookies.set(key, value, this.cookieOptions())
  }

  static cookieOptions = (expires = null) => {
    const options = { path: '/', sameSite: 'strict' }
    if (window._kol.cookieDomain) {
      options.domain = window._kol.cookieDomain
    }
    if (expires) {
      options.expires = expires
    }
    return options
  }
}
