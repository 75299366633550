import Leads from '../leads'
import { applyCaptchaIfNecessary } from '../utils/captcha'
import { kolDebug as debug } from '../utils/debugging'

export default class BoxFormHandler {
  constructor (ContestBoxID) {
    this.addFormHandlerToContestBox(ContestBoxID)
  }

  handleLeadDataSubmit (data, source = 'cb') {
    applyCaptchaIfNecessary(data.formData).then((formData) => {
      return new Leads().addLead(formData, source)
    })
  }

  handleContestBoxForm (data) {
    debug('Handle Contest Box Form', data)
    if (data && data.type) {
      const type = data.type
      if (type && type.startsWith('kol:submit:contestBox')) {
        const contestBoxSource =
          type === 'kol:submit:contestBox' ? 'cb' : 'cbe'
        this.handleLeadDataSubmit(data, contestBoxSource)
      }
    }
  }

  addFormHandlerToContestBox (contestBoxFrameID) {
    const contestFrame = document.getElementById(contestBoxFrameID)
    if (contestFrame) {
      const form = contestFrame.contentDocument.getElementById('kol-widget-form')
      const self = this
      if (form) {
        form.onsubmit = function (event) {
          event.preventDefault()
          const formData = new FormData(this)
          const object = {}

          formData.forEach((value, key) => {
            if (object[key]) {
              // capture all the choice values.
              object[key] = formData.getAll(key).toString()
            } else {
              object[key] = value
            }
          })
          const data = {
            type: 'kol:submit:' + form.dataset.contestBoxType,
            formData: object
          }
          self.handleContestBoxForm(data)
          return false
        }
      } else {
        debug('ERROR: Could not find contest box form!')
      }
    } else {
      debug('Could not find contest box Frame by ID:', contestBoxFrameID)
    }
  }
}
