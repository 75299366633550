import { oauthUrl } from '../utils/urls'
export default class KOLGoogleSignup {
  constructor (writeDoc) {
    const myself = this
    this.doc = writeDoc
    this.handleBuildByEvent = function (event) {
      myself.build()
    }

    this.build()
    this.setupEventListeners()
  }

  setupEventListeners () {
    this.doc.removeEventListener(
      'kol:snippets:build:google_signup',
      this.handleBuildByEvent.bind(this),
      true
    )
    window.addEventListener(
      'kol:snippets:build:google_signup',
      this.handleBuildByEvent.bind(this)
    )
  }

  build () {
    const snippets = this.doc.querySelectorAll(
      "[data-kol-snippet='google_signup']"
    )
    const myself = this
    snippets.forEach((snippet, index) => {
      const button = snippet.querySelector('.google-signup-btn')

      button.onclick = null

      button.onclick = (event) => {
        myself.googleSignupClick(event)
      }
    })
    // now grab actions too.
    // Select all div elements with the class 'kol-activity-signup_google'
    const gSignupElements = document.querySelectorAll('div.kol-activity-signup_google')
    // Attach the click event listener to each element
    gSignupElements.forEach(element => {
      element.addEventListener('click', myself.googleSignupClick.bind(myself))
    })
  }

  inIframe () {
    try {
      return window !== window.top
    } catch (e) {
      return true
    }
  }

  googleSignupClick (buttonEvent) {
    const button = buttonEvent.currentTarget

    if (!document.body.classList.contains('kol-preview')) {
      const baseUrl = window?.kol_oauth_options?.url || window?._kol?.options?.oauth?.url
      const googleUrl = oauthUrl(baseUrl, 'google_oauth2_campaign')
      const url = new URL(googleUrl)
      if (window.__kol_analytics) {
        for (const [key, value] of Object.entries(
          window.__kol_analytics.getConversionData({})
        )) {
          if (key === '__custom') {
            url.searchParams.set('__custom_json', JSON.stringify(value))
          } else {
            url.searchParams.set(key, value)
          }
        }
      }
      if (this.inIframe()) {
        // url.searchParams.set('embedded', 'true')
        button.setAttribute('href', url.toString())
        // button.setAttribute('target', '_top')
      } else {
        buttonEvent.preventDefault()
        window.location = url.toString()
      }
      return true
    } else {
      buttonEvent.preventDefault()
      const options = {}
      options.title = 'Signup Disabled in Designer'
      options.message =
        'When clicking this on the published page, your user will be redirected to google to complete the signup.'
      if (
        !window._kol.container.KOLConfig.config[
          '#' + button.currentTarget.getAttribute('id') + '-shown-warning'
        ]
      ) {
        /* eslint no-new: "off" */
        new window._kol.container.DisabledDesignerAction({
          title: options.title,
          message: options.message
        })
        window._kol.container.KOLConfig.config[
          '#' + button.getAttribute('id') + '-shown-warning'
        ] = true
      }
    }
  }
}
