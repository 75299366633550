export default class KOLBrowsers {
  static checkBrowser () {
    let browser = ''
    const c = navigator.userAgent.search('Chrome')
    const f = navigator.userAgent.search('Firefox')
    const m8 = navigator.userAgent.search('MSIE 8.0')
    const m9 = navigator.userAgent.search('MSIE 9.0')
    if (c > -1) {
      browser = 'Chrome'
    } else if (f > -1) {
      browser = 'Firefox'
    } else if (m9 > -1) {
      browser = 'MSIE 9.0'
    } else if (m8 > -1) {
      browser = 'MSIE 8.0'
    }
    return browser
  }

  static isInIframe () {
    return window !== window.top
  }
}
