import KOLStrings from './../utils/strings'
import { tryJSON, tryColor, getBorderRadius } from './../utils/objects'
import { kolDebug as debug } from '../utils/debugging'

export default class KOLRewards {
  constructor (writeDoc) {
    this.doc = writeDoc
    this.options = _kol.options

    this.contest_score = _kol.lead ? _kol.lead.contest_score : 0

    const myself = this

    this._handleLead = function (event) {
      myself.contest_score = _kol.lead ? _kol.lead.contest_score : 0
      debug('Rewards - Rebuilding by lead change', event)
      myself.build(myself.options)
    }

    this.handleBuildByEvent = function (event) {
      debug('Rewards - Rebuilding by event', event)
      myself.contest_score = _kol.lead ? _kol.lead.contest_score : 0
      myself.build(myself.options)
    }

    this.build(this.options)

    // setup event handlers for activities.
    //
    this.setupEventListeners()
  }

  build (options) {
    const snippets = this.doc.querySelectorAll("[data-kol-snippet='rewards']")

    // build UI for all rewards holders.
    snippets.forEach((snippet, index) => {
      this.buildRewardsSnippet(snippet, options)
    })
  }

  buildRewardsSnippet (snippet, options) {
    // TODO: Clear existing HTML after done.
    const dataSettings = snippet.getAttribute('data-settings')
    const settings = tryJSON(dataSettings, { brandColor: 'inherit', style: 'modern', backgroundColor: '#FFFFFF', fontColor: '#000000' })
    // clearn any existing.
    snippet.innerHTML = ''
    let rewardsHTML = ''
    if (options.rewards && Array.isArray(options.rewards)) {
      if (options.rewards.length > 0) {
        const transparentTextColor = tryColor(
          settings.fontColor,
          '#111111'
        )
          .fade(0.75)

        const transparentTextColorLighter = tryColor(
          transparentTextColor,
          '#111111'
        )
        const borderRadius = getBorderRadius(settings)
        switch (settings.style) {
          case 'flexclassic':
            rewardsHTML += this.getClassicRewardsHTML(options, settings)
            rewardsHTML = `<div class="kol-reward-tracker"><div class="tracker-style-${settings.style}  kol-reward-levels-${this.options.rewards.length}">${rewardsHTML}
              </div>
              <style>
               #${snippet.id} .kol-reward-tracker .tracker-style-flexclassic .reward-level-prize {
                background: ${settings.backgroundColor || '#FFFFFF'} !important;
                border: .5px solid ${transparentTextColorLighter};
                color: ${settings.fontColor || '#333333'} !important;
                border-radius: ${borderRadius}px;
                box-shadow: 0 1px 3px ${transparentTextColorLighter}, 0 1px 2px ${transparentTextColor};
               }
                #${snippet.id} .kol-reward-tracker .tracker-style-flexclassic .reward-level-text {
                  color: ${settings.fontColor || '#333333'} !important;
                  border: .5px solid ${transparentTextColorLighter};
                  background:  ${settings.backgroundColor || '#FFFFFF'} !important;
                 
              }
              #${snippet.id} .kol-reward-tracker .tracker-style-flexclassic .reward-level-text.reward-earned {
                  color: #FFFFFF !important;
                  border: .5px solid ${transparentTextColorLighter};
                  background: ${settings.brandColor} !important;
                  box-shadow:none;
              }
              #${snippet.id} .tracker-style-flexclassic .progress-wrapper {
                background: ${settings.backgroundColor || '#f1f1f1'} !important;
              }

              #${snippet.id} .kol-reward-tracker .tracker-style-flexclassic .progress-bar {
                box-shadow: none !important;
              }
              </style>
              </div>`
            break

          default:
            settings.style = 'modern'
            rewardsHTML += this.getModernRewardsHTML(options, settings)
            rewardsHTML = `<div class="kol-reward-tracker is-no-col-resizing"><div class="tracker-style-${settings.style}  kol-reward-levels-${this.options.rewards.length}
              reward-tracker-prizes text-center">${rewardsHTML}
              </div>
              <style>
                #${snippet.id} .kol-reward-tracker .tracker-style-modern .reward-level{
                border: .5px solid ${transparentTextColorLighter};
                box-shadow: 0 1px 3px ${transparentTextColorLighter}, 0 1px 2px ${transparentTextColor};
                background: ${settings.backgroundColor} !important;
                border-radius: ${borderRadius}px;
              }
              #${snippet.id} .kol-reward-tracker .tracker-style-modern .reward-level-prize {
                border-top-left-radius: ${borderRadius}px;
                border-top-right-radius: ${borderRadius}px;
              }
              #${snippet.id} .kol-reward-tracker .tracker-style-modern .points {
                border-bottom-left-radius: ${borderRadius}px;
                border-bottom-right-radius: ${borderRadius}px;
              }
               #${snippet.id} .kol-reward-tracker .tracker-style-modern .reward-level-prize, 
               #${snippet.id} .kol-reward-tracker .tracker-style-modern .points   {
                background: ${settings.backgroundColor || '#FFFFFF'} !important;
                color: ${settings.fontColor || '#333333'} !important;
               }
              </style>
              </div>`
            break
        }
      } else {
        rewardsHTML += "<div class='kol-no-rewards'><h2>-</h2></div>"
      }
    }
    snippet.innerHTML = rewardsHTML
  }

  getRewardEarnedClass (required_points) {
    if (parseInt(this.contest_score) >= parseInt(required_points)) {
      return 'reward-earned'
    } else {
      return 'reward-not-earned'
    }
  }

  getLeftPercentageByIndex (i) {
    let distanceToPrevGoal, pointsAwayFromPrevGoal, width

    if (i == 0) {
      width = this.contest_score / this.options.rewards[i].points * 100
    } else {
      distanceToPrevGoal = this.options.rewards[i].points - (this.options.rewards[i - 1].points || 0)
      pointsAwayFromPrevGoal = this.contest_score - (this.options.rewards[i - 1].points || 0)
      width = pointsAwayFromPrevGoal < 0 ? '0' : (pointsAwayFromPrevGoal / distanceToPrevGoal) * 100
    }

    width = width - 50

    if (width < 0) {
      width = 0
    } else {
      width = width * 2
    }
    if (width > 100) {
      width = 100
    }
    return width
  }

  getRightPercentageByIndex (i) {
    let distanceToNextGoal, pointsFromNextGoal, width
    if (this.options.rewards[i + 1]) {
      distanceToNextGoal = (this.options.rewards[i + 1].points || 0) - this.options.rewards[i].points
      pointsFromNextGoal = this.contest_score - this.options.rewards[i].points
      width = pointsFromNextGoal < 0 ? '0' : ((pointsFromNextGoal / distanceToNextGoal) * 100) * 2
    }

    if (width > 100) {
      width = 100
    }
    return width
  }

  getRewardPositionClass (index) {
    if ((index + 1) < this.options.rewards.length) {
      return 'reward-right'
    } else {
      return 'reward-level-last'
    }
  }

  getClassicRewardsHTML (options, settings) {
    let rewardsHTML = ''
    let rewardTextHTML = ''

    options.rewards.forEach((reward, index) => {
      rewardTextHTML += this.getClassicRewardTextHTML(reward, index, settings)
    })
    rewardsHTML += `<div class="flex flex-row flex-col-xs flex-no-wrap referral-tracker-prizes ">${rewardTextHTML}</div>`
    return rewardsHTML
  }

  getclassicRewardsTextStyle (reward, settings) {
    let style = ''
    if (this.contest_score >= reward.points) {
      style = `style="background:${settings.brandColor};color:#FFF; font-weight:bold;"`
    } else {
      style = `style="color:${settings.brandColor}"`
    }
    return style
  }

  getRewardImageHTML (reward) {
    if (reward.image) {
      return `<div class="reward-level-prize-image-wrapper">
            <img class="reward-level-image" src="${reward.image}" alt="${reward.description}"/>
          </div>   
          `
    } else {
      return ''
    }
  }

  getClassicRewardTextHTML (reward, index, settings) {
    const reward_earned_class = this.getRewardEarnedClass(reward.points)
    const style = this.getclassicRewardsTextStyle(reward, settings)
    return `<div class="${reward_earned_class} flex-1 flex flex-col reward-level-prize-container ${this.getRewardPositionClass(index)} reward-level-${index}">
    <div class="reward-level reward-desktop-progress flex flex-align-center flex-justify-content-center flex-no-wrap flex-1 reward-level-${index} ${this.getRewardEarnedClass(reward.points)} ${this.getRewardPositionClass(index)}">
        <div class="left-handle flex-1 progress-wrapper">
          <div class="progress-bar" style="width: ${this.getLeftPercentageByIndex(index)}%;background:${settings.brandColor};">
          </div>
        </div>  
        <div class="reward-text-wrapper flex-initial"><span class="reward-level-text ${this.getRewardEarnedClass(reward.points)} " ${style}>${reward.points}</span></div>
        
        <div class="right-handle flex-1 progress-wrapper">
            <div class="progress-bar" style="width:${this.getRightPercentageByIndex(index)}%;background:${settings.brandColor};">
          </div>
        </div>
    </div>
    <div class="reward-level-prize-wrapper flex-1 flex flex-row">  
      <div class="reward-level-prize-goal flex flex-col">
         <div class="reward-text-wrapper"><span class="reward-level-text ${this.getRewardEarnedClass(reward.points)} " ${style}>${reward.points}</span></div>
          <div class="bottom-handle flex-1 vertical-progress-wrapper">
            <div class="vertical-progress-bar" style="height:${this.getRightPercentageByIndex(index)}%;background:${settings.brandColor};">
          </div>
        </div>
      </div>
      <div class="reward-level-prize flex-1">
          <div class="reward-level-prize-text-wrapper">
            ${reward.description}
          </div>
          ${this.getRewardImageHTML(reward)}       
          
        </div>
      </div>  
    </div>`
  }

  getModernRewardsHTML (options, settings) {
    let rewardsHTML = ''
    options.rewards.forEach((reward, index) => {
      rewardsHTML += this.getModernRewardHTML(reward, index, settings)
    })
    return rewardsHTML
  }

  getModernRewardHTML (reward, index, settings) {
    const rewardProgress = this.getPercentageToReward(reward, index)
    const reward_earned_class = this.getRewardEarnedClass(reward.points)
    return `
    <div id="reward-level-id-${
      reward.id
    }" class="reward-level reward-level-${index} ${reward_earned_class}">
        <div class="reward-status-indicator">
          <span class="check" style="background:${settings.brandColor}">
            <svg height="51" width="52" xmlns="http://www.w3.org/2000/svg">
              <path d="M35.42 18.26a1 1 0 010 1.41L22.69 32.4a1 1 0 01-1.42 0l-4.95-4.95a1 1 0 01-.09-1.32l.09-.1a1 1 0 011.32-.08l.1.09 4.24 4.24L34 18.26a1 1 0 011.42 0z" fill="#ffffff" fill-rule="evenodd"/>
            </svg>
          </span>
          <span class="lock">
            <?xml version="1.0" encoding="UTF-8"?>
            <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
              <g id="lock" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M32.53 20.05a2 2 0 012 2v9.4a2 2 0 01-2 2h-15.9a2 2 0 01-2-2v-9.4c0-1.1.89-2 2-2h15.9zm-7.95 3.22a1.74 1.74 0 00-1.33 2.86l.1.11-.77 3.44h4l-.78-3.44a1.73 1.73 0 00-1.22-2.97z" id="lock-icon--lock-body" fill="#ffffff"/>
                <path d="M30 21.21c-.05-8.27-.99-10.12-5.42-10.12-4.5 0-5.53 2.02-5.58 10.28.28.04.65.08 1.1.11 1.11.07 2.66.09 4.38.05a81.88 81.88 0 005.52-.32zm-11.38.08a.75.75 0 000 0z" id="lock-icon---lock-handle" stroke="#ffffff" stroke-width="2"/>
              </g>
            </svg>
          </span>
        </div>
        <div class="reward-level-prize reward-level-prize-text content-wrapper">
            ${reward.description}
            ${this.getRewardImageHTML(reward)}
        </div>
        <div class="modern-prize-box-progress">
          <div class="progress-bar" style="width: ${rewardProgress}%;background-color:${
      settings.brandColor
    } "></div>
        </div>
        <div class="points">
          <div class="reward-points-text"><span class="reward-points-progress-number">${
            reward.points
          }</span> <span class="reward-points-points-word">${KOLStrings.getPointsWord(reward.points)}</span></div>
        </div>
    </div>

    `
  }

  getPercentageToReward (reward, i) {
    let distanceToPrevGoal, pointsAwayFromPrevGoal, prevRewardPoints, width
    const points = this.contest_score

    if (points >= parseInt(reward.points)) {
      return 100
    } else {
      prevRewardPoints = this.options.rewards[i - 1]
        ? parseInt(this.options.rewards[i - 1].points)
        : 0

      distanceToPrevGoal =
        parseInt(this.options.rewards[i].points) - prevRewardPoints
      pointsAwayFromPrevGoal = points - prevRewardPoints
      width =
        pointsAwayFromPrevGoal <= 0
          ? '0'
          : (pointsAwayFromPrevGoal / distanceToPrevGoal) * 100
      if (width < 0) {
        width = 0
      }
      if (width > 100) {
        width = 100
      }
      return width
    }
  }

  setupEventListeners () {
    window._kol.container.removeEventListener(
      'kol:leadchange',
      this._handleLead,
      true
    )
    window._kol.container.addEventListener('kol:leadchange', this._handleLead)
    this.doc.removeEventListener('kol:knownlead', this._handleLead, true)
    this.doc.addEventListener('kol:knownlead', this._handleLead)
    this.doc.removeEventListener('kol:unknownlead', this._handleLead, true)
    this.doc.addEventListener('kol:unknownlead', this._handleLead)

    window.removeEventListener(
      'kol:activity-marked-done',
      this.handleBuildByEvent.bind(this),
      true
    )
    window.addEventListener(
      'kol:activity-marked-done',
      this.handleBuildByEvent.bind(this)
    )
    window.removeEventListener(
      'kol:snippets:build:rewards',
      this.handleBuildByEvent.bind(this),
      true
    )
    window.addEventListener(
      'kol:snippets:build:rewards',
      this.handleBuildByEvent.bind(this)
    )
  }
}
