import { removeUndefined } from "./utils/objects"
import { kolDebug as debug } from "./utils/debugging";
export const instantSignup = () => {
 let leadData = window.kol_instant_lead

 if(!leadData) {
  let email = _kol.queryString.email;
  let phone_number = _kol.queryString.phone_number
  if (email || phone_number) {
   leadData = {email,phone_number}
   debug("Instant Signup via QueryString", leadData)
  }
 }
 if (leadData) {
  debug("Instant Signup has Data", leadData)
  leadData = validateAndCleanLeadData(leadData)
  if (leadData) {
   debug("Using Instant Signup", leadData)
   return _kol.leads.addLead(leadData, "cbi")
  }
 }
 
 return null;
}

const validateAndCleanLeadData = (leadData) => {
 leadData = removeUndefined(leadData);

 if (Object.keys(leadData).length === 0) {
  debug("Instant Signup has no valid data")
  return null;
 }

 if (leadData.email || leadData.phone_number) {
  return leadData
 }

 return null
}