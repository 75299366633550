import { kolDebug as debug } from './utils/debugging'
import createUser from './analytics_user'
import uuid from './utils/uuid'
import Cookies from './utils/cookies'
import { extend, removeUndefined } from './utils/objects'
import toParam from './utils/params'
import { onSubSelector, ready } from './utils/events'

export default class KOLAnalytics {
  constructor (optionOverrides) {
    this.optionOverrides = optionOverrides
    this.options = extend(
      {},
      this.defaultOptions,
      optionOverrides,
      KOLAnalytics.globalOverrides()
    )
    debug('The Current Options', this.options)
    this.setup()
  }

  user = {}

  defaultOptions = {
    analytics: {
      url: 'https://api.kickofflabs.com',
      web_view_path: 'stats/b',
      click_track_path: 'stats/t',
      stats_version: 1,
      trackViews: true,
      click_selector:
        'a.social_share_link, a.kol-social-share-link, a[data-kol-track=true]'
    },
    source: 'koljs'
  }

  static globalOverrides () {
    return window.kol_analytics_options || {}
  }

  setup () {
    this.setUserProperties()
    this.trackUserInteraction()
    // wrap this.capture with event to maintain proper scope

    window._kol.container.addEventListener(
      'kol:leadchange',
      this.captureSignUp.bind(this)
    )
    if (this.options.analytics.click_selector && !window._kol.mode.isPreview) {
      onSubSelector('body', '.kol', 'click', (event) => {
        const aHref = event.target
        const data = JSON.parse(aHref.dataset.kol) || {}
        data.url = data.url || aHref.getAttribute('href')
        this.trackClick(data)
      })
    }
  }

  trackClick (data) {
    const mergedData = extend({}, this.user, { click: data })
    removeUndefined(mergedData)
    const imageUrl = this.generateUrl(
      this.options.analytics.click_track_path,
      mergedData
    )
    KOLAnalytics.addImageToPage(imageUrl)
  }

  track_click (data) {
    return this.trackClick(data)
  }

  setUserProperties () {
    this.user = createUser(this.options)
  }

  trackUserInteraction () {
    this.mouseMoves = 0
    this.keyDowns = 0
    window.addEventListener('mousemove', () => {
      this.mouseMoves += 1
    })
    window.addEventListener('keydown', () => {
      this.keyDowns += 1
    })
  }

  getConversionData (data = null) {
    const newObject = data || {}
    /* eslint no-restricted-syntax: off */
    for (const key in this.user) {
      if (Object.prototype.hasOwnProperty.call(this.user, key)) {
        const item = this.user[key]
        if (item) {
          newObject[`__${key}`] = item
        }
      }
    }
    newObject.__mm = this.mouseMoves
    newObject.__kd = this.keyDowns
    return newObject
  }

  // This maps to to V1 Analytics
  set_conversion_data (data = {}) {
    return this.getConversionData(data)
  }

  // TODO: Scott? CID vs SID VS ID
  captureSignUp (event) {
    debug('captureSignUp()', event)
    if (event.detail && event.detail.lead) {
      this.setConversionId(event.detail.lead.social_id)
    } else {
      this.setConversionId(event.id)
    }
  }

  setConversionId (sid) {
    if (sid) {
      debug('setting conversion id', sid)
      this.user.cid = sid
      Cookies.set(`kola.${this.options.campaignId}.cid`, sid)
    } else {
      debug('setConversionId called, but no conversion id')
    }
  }

  get trackViews () {
    return this.options.analytics.trackViews
  }

  trackView (trackOptions = {}) {
    if (!this.trackedView) {
      debug('trackView()', trackOptions)
      extend(this.user.custom, this.user.custom, trackOptions.custom || {})
      this.logView()
      this.trackedView = true
      return true
    }
    debug('trackView()', 'skipping. Already executed')
    return false
  }

  logView () {
    debug('logView', 'logging called')
    const imageUrl = this.generateUrl(
      this.options.analytics.web_view_path,
      this.user
    )
    debug('imageUrl', imageUrl)
    KOLAnalytics.addImageToPage(imageUrl)
  }

  generateUrl (path, data) {
    debug(`generateUrl(${path})`, data)
    const params = toParam(data)
    debug(`generateUrl(${path}) params`, params)
    return `${this.options.analytics.url}/${path}/${uuid()}?${params}`
  }

  static addImageToPage (imageUrl) {
    const callback = () => {
      const img = document.createElement('img')
      img.setAttribute('style', 'display:none')
      img.setAttribute('src', imageUrl)
      document.body.appendChild(img)
    }

    ready(callback)
  }
}
