import { getData, postData, minimumCacheTime } from './utils/data'
import { extend } from './utils/objects'
import storage from 'local-storage-fallback'
import { kolDebug as debug } from './utils/debugging'
import { generatePreviewLead } from './utils/preview_lead'
import { dispatchLeadChangeEvents, dispatchLeadConversionEvents } from './utils/events'
import { savePreviousForm } from './previous_form'
export default class Leads {
  constructor (optionOverrides = null, analytics = null) {
    this.options = extend(
      {},
      this.defaultOptions,
      optionOverrides || window._kol.options
    )
    this.analytics = analytics || window._kol.analytics
  }

  defaultOptions = {
    leads: {
      url: 'https://leads.kickofflabs.com'
    }
  }

  isCachedDataStillValid (lead) {
    return (
      Date.now() - lead.queryDateTime <=
      minimumCacheTime(this.options.campaignOptions.cacheFor) * 1000
    )
  }

  isDataForThisLead (lead, leadId) {
    return !leadId || lead.id === leadId
  }

  useCachedData (lead, leadId) {
    return (
      this.isCachedDataStillValid(lead) && this.isDataForThisLead(lead, leadId)
    )
  }

  cachedAnonymousLead () {
    debug('Querying cache for current anonymous lead', this.analytics.user.uid)

    if (this.options.debug && this.options.debugAnonymousLead) {
      return Promise.resolve(this.options.debugAnonymousLead)
    }

    if (_kol.mode.isAnonymousPreview) {
      debug('Using Preview Anonymous Lead')
      return Promise.resolve(generatePreviewLead(true))
    }

    const leadJsonData = storage.getItem(
      `kola.${this.options.campaignId}.anon.data`
    )

    if (leadJsonData) {
      const leadData = JSON.parse(leadJsonData)
      if (this.useCachedData(leadData, this.analytics.user.uid)) {
        debug('Anonymous Lead Found in Cache', this.analytics.user.uid)
        return Promise.resolve(leadData)
      } else {
        debug('Anonymous lead data exists in cache, but cannot be used')
      }
    }

    return this.currentAnonymousLead()
  }

  /*
   * Pass a lead ID (or just lookup current lead in cookies and get the cached CID data if it exists.
   * ageAllowed is how old you are willing to let the data be in seconds.
   * Returns a promise you can .then off of.
   */
  cachedCurrentLeadData (leadId = null) {
    leadId = leadId || this.analytics.user.cid
    debug('Querying cache for current lead', leadId)
    if (this.options.debug && this.options.debugCurrentLead) {
      return Promise.resolve(this.options.debugCurrentLead)
    }

    if (_kol.mode.isLeadPreview) {
      debug('Using Preview Lead')
      return Promise.resolve(generatePreviewLead())
    }

    // ensure we do not find something cookied
    if (_kol.mode.isAnonymousPreview) {
      return Promise.resolve(null)
    }

    // Do NOT pull from cache if we have an email in the query string
    if (_kol.queryString.email) {
      return Promise.resolve(null)
    }

    const leadJsonData = storage.getItem(
      `kola.${this.options.campaignId}.cid.data`
    )

    if (leadJsonData) {
      const leadData = JSON.parse(leadJsonData)
      if (this.useCachedData(leadData, leadId)) {
        debug('Current lead found and retrieved from cache')
        return Promise.resolve(leadData)
      } else {
        debug('Lead data found in cache, but could not be used')
      }
    }

    return this.currentLead(leadId)
  }

  currentAnonymousLead () {
    debug('Looking up current anonymous lead', this.analytics.user.uid)

    return this.fetch(this.analytics.user.uid, 'anon').then((leadData) => {
      this.cacheLeadData(leadData)
      return leadData
    })
  }

  currentLead (leadId = null) {
    const leadToLookUp = leadId || this.analytics.user.cid || _kol.queryString.email
    if (leadToLookUp) {
      debug('Looking up current lead', leadToLookUp)
      return this.fetch(leadToLookUp).then((leadData) => {
        this.cacheLeadData(leadData)
        return leadData
      })
    }
    debug('The current lead could not be found', leadToLookUp)
    return Promise.resolve(null)
  }

  /*
   * Pass a lead ID (or just lookup current lead in cookies and get the cached CID data if it exists.
   * ageAllowed is how old you are willing to let the data be in seconds.
   */
  cachedShareLeadData (leadId = null) {
    leadId = leadId || this.analytics.user.kid
    debug('Looking for cached shared lead', leadId)
    if (this.options.debug && this.options.debugShareLead) {
      return Promise.resolve(this.options.debugShareLead)
    }

    if (_kol.mode.isDesigner) {
      return Promise.resolve(null)
    }

    let leadData = storage.getItem(`kola.${this.options.campaignId}.kid.data`)
    if (leadData) {
      leadData = JSON.parse(leadData)
      debug('Using cached share data', leadData)
      if (this.useCachedData(leadData, leadId)) {
        return Promise.resolve(leadData)
      }
    }
    return this.shareLead(leadId)
  }

  shareLead (leadId = null) {
    const leadToLookUp = leadId || this.analytics.user.kid
    debug('Looking up Share Lead', leadToLookUp)
    if (leadToLookUp) {
      return this.fetch(leadToLookUp).then((leadData) => {
        this.cacheLeadData(leadData, 'kid')
        debug('Found Share Lead', leadData)
        return leadData
      })
    }
    debug('Could not find Share Lead data', leadToLookUp)
    return Promise.resolve(null)
  }

  fetch (leadId, path = 'lead') {
    if (!leadId) {
      throw Error('Cannot fetch a missing lead id')
    }
    const url = leadId.indexOf('@') == -1 ? this.buildUrl(leadId, path) : this.buildUrlWithEmail(leadId, path)
    return getData(url).catch((err) => {
      if (err.message.indexOf('404') > -1) {
        debug(`Lead with ID ${leadId} could not be found`)
      } else {
        debug(`Failed to look up ${leadId} - ${err}`)
      }
    })
  }

  buildUrl (leadId, path) {
    const url = `${this.options.leads.url}/${path}/${this.options.campaignId}/${leadId}`
    if (this.options.internal) {
      debug('INTERNAL API REQUEST')
      return `${url}?in=true`
    }
    return url
  }

  buildUrlWithEmail (email, path) {
    const url = `${this.options.leads.url}/${path}/${this.options.campaignId}?email=${email}`
    if (this.options.internal) {
      debug('INTERNAL API REQUEST')
      return `${url}&in=true`
    }
    return url
  }

  removeLeadFromCache (lead = window._kol.lead) {
    if (lead) {
      debug(`Remove lead ${lead.id} from cache`)
      storage.removeItem(this.leadCacheKey(lead))
    }
  }

  leadCacheKey (lead = window._kol.lead, type) {
    if (lead) {
      const cacheType = type || (lead.anonymous ? 'anon' : 'cid')
      return `kola.${this.options.campaignId}.${cacheType}.data`
    } else {
      debug('Tried to generate lead cache key when no lead exists')
    }
  }

  cacheLeadData (leadData, type = null) {
    if (leadData) {
      debug('Caching lead data', type)
      leadData.queryDateTime = Date.now()
      storage.setItem(
        this.leadCacheKey(leadData, type),
        JSON.stringify(leadData)
      )
    }
    return leadData
  }

  sendSuccessEvent (lead) {
    const event = new CustomEvent('kol:success', {
      bubbles: true,
      detail: {
        lead,
        statusText: 'Lead Created'
      }
    })
    window.document.dispatchEvent(event)
    return lead
  }

  pushLead (lead) {
    dispatchLeadChangeEvents(lead)
    this.sendSuccessEvent(lead)
    this.cacheLeadData(lead)
  }

  addLead (formData, source = null) {
    debug('Adding Lead', formData)
    savePreviousForm(formData)
    const conversionData = _kol.analytics.getConversionData()
    // Ensure we honor a source set with the lead data
    // then passed in with the api call
    // and fall back on conversionData
    source = (formData.__source || source || conversionData.__source)
    const leadData = extend({}, formData, conversionData, {
      __source: source
    })
    debug('Apply Conversion Data', leadData)

    const url = `${this.options.leads.url}/lead/${this.options.campaignId}`
    return postData(url, leadData)
      .then(dispatchLeadConversionEvents)
      .then(dispatchLeadChangeEvents)
      .then(this.sendSuccessEvent)
      .then((lead) => this.cacheLeadData(lead))
  }
}
