import { kolDebug as debug } from './utils/debugging'
import { buildEmbedParams } from './utils/query_strings'
import { fadeIn, fadeOut } from './utils/animations'
import exitIntent from 'exit-intent'
import { stringToHTML } from './utils/dom'
import storage from 'local-storage-fallback'

// mode = status_only

// TODO: Can this be split up as a constructor for each action?
// TODO: Right now all static feels odd... but what about multiple ones?
export default class KOLPagePopups {
  constructor (popupOptions = null) {
    this.options = popupOptions || window._kol.options.onPageActions.popups
    if (this.options && this.options.length > 0) {
      this.options.forEach(popup => {
        this.buildPopup(popup)
      })

      this.setupOpenCloseEvents()

      this.handlePopupEmbedLoad = function (event) {
        debug('Handling Popup Embed Frame Load', event)
        // Get the iframe that loaded.
        const embedPageFrame = document.getElementById(event.currentTarget.id)
        if (embedPageFrame) {
          // TODO: Seperate out code to show it.
          // TODO: Run Open Logic
          const popupId = embedPageFrame.getAttribute('data-popup-page-id')
          this.runPopupLogic(popupId)
        }
      }
    }
  }

  getPopupOptionsById (popupId) {
  // Use the find method to search for the first object matching the pageId
    const popupOptions = this.options.find(popup => Number(popup.pageId) === Number(popupId))
    // If no matching popup options are found, return a default message or null
    return popupOptions || null
  }

  canShowAgain (popupId) {
    // currently set to show every 24 hours
    const popupLastClosed = storage.getItem(`kola.${_kol.options.campaignId}.popupLastClosed.${popupId}`)
    if (!popupLastClosed || window._kolDebuggingEnabled) {
      debug('Popup Last Closed', popupLastClosed)
      return true
    } else {
      debug('Popup Last Closed', popupLastClosed)
      const now = new Date()
      const days = (now - popupLastClosed) / (1000 * 3600 * 24)
      if (days > 1) {
        return true
      }
    }
    return false
  }

  runPopupLogic (popupId) {
    const popupOptions = this.getPopupOptionsById(popupId)
    // If the popup options are not found, return false
    if (popupOptions === null) {
      return false
    }
    if (!this.canShowAgain(popupId)) {
      return false
    }

    if (popupOptions.openAfter !== null && popupOptions.openAfter > -1) {
      setTimeout(() => {
        if (window._kol.lead.anonymous) {
          this.openPopup(popupId)
        }
      }, popupOptions.openAfter * 1000)
    }
    // if exit intent on AND it's an anonymous visitor. Open the contest box.

    if ((popupOptions.openOnExit === true || popupOptions.openOnExit === "true") && window._kol.lead.anonymous) {
      setTimeout(() => {
        const removeExitIntent = exitIntent({
          threshold: 50, // distance from top of page to trigger
          maxDisplays: 3, // total times the popup will trigger
          eventThrottle: 100, // how long between triggers
          onExitIntent: () => {
            if (window._kol.lead.anonymous) {
              debug('Exit Intent Triggered for popup', popupId)
              const openEvent = new CustomEvent('kol:open:PagePopup', { detail: { popupPageId: popupId } })
              // Don't run if KOL script isn't installed.
              window._kol.container.dispatchEvent(openEvent)
            }
          }
        })
      }, 1000)
    }
  }

  openPopup (popupPageId) {
    // make the frame visible.
    const embedPageFrame = window.top.document.getElementById('kol-popup-frame-' + popupPageId)
    if (!embedPageFrame) {
      debug('Popup frame not found', popupPageId)
      return false
    }
    embedPageFrame.style.visibility = 'visible'
    // make holder visible.
    const popupFrame = embedPageFrame.closest('.kol-popup-frame-holder')
    const popupWrapper = popupFrame.closest('.kol-popup-wrapper')
    popupWrapper.classList.remove('kol-popup-closed')
    popupWrapper.classList.add('kol-popup-open')
    popupFrame.style.display = 'flex'
    fadeIn(popupWrapper, 500)
  }

  buildPopup (popup) {
    debug('Building popup', popup)
    // create objects we need.
    const popupWrapper = document.createElement('div')

    Object.assign(popupWrapper, {
      id: 'kolPopupWrapper-' + popup.pageId
    })

    popupWrapper.className = 'kol-popup-wrapper kol-popup-closed kol-popup-template-' + popup.popupTemplate
    popupWrapper.setAttribute('data-popup-page-id', popup.pageId)
    Object.assign(popupWrapper.style, {
      bottom: 0,
      opacity: 0,
      position: 'fixed'
    })
    const popupFrame = stringToHTML(
      this.getPopupFrameMarkup(popup)
    )

    popupWrapper.appendChild(popupFrame.firstElementChild)
    document.body.appendChild(popupWrapper)
    this.writePopupEmbedFrame(popup)
  }

  getCloseButtonMarkup (popup) {
    if (popup.popupTemplate == 'full' || popup.popupTemplate == 'box') {
      return `<div id="kol-popup-close-${popup.pageId}" href="#kolClosePopup" class="kol-popup-close-button" aria-label="Close"><span style="visibility:hidden;">X</span></div>`
    } else { return '' }
  }

  getCommonPopupStyles () {
    return `
          .kol-popup-frame{
            width: 100%;
            height: 100%;
            position: absolute;
            font-size: 100%;
            font-style: normal;
            letter-spacing: normal;
            font-stretch: normal;
            font-weight: normal;
            text-align-last: initial;
            text-indent: 0px;
            text-shadow: none;
            text-transform: none;
            alignment-baseline: baseline;
            animation-play-state: running;
            backface-visibility: visible;
            background-color: transparent;
            
            baseline-shift: baseline;
            bottom: auto;
            -webkit-box-decoration-break: slice;
            box-shadow: none;
            box-sizing: content-box;
            caption-side: top;
            clear: none;
            clip: auto;
            color: inherit;
            column-count: auto;
            column-fill: balance;
            column-gap: normal;
            column-width: auto;
            content: normal;
            counter-increment: none;
            counter-reset: none;
            cursor: auto;
            direction: ltr;

            dominant-baseline: auto;
            empty-cells: show;
            float: none;
            height: auto;
            -webkit-hyphenate-character: auto;
            hyphens: manual;
            image-rendering: auto;
            left: auto;
            line-height: inherit;
            max-height: none;
            max-width: none;
            min-height: 0px;
            min-width: 0px;
            opacity: 1;
            orphans: 2;
            outline-offset: 0px;
            page: auto;
            perspective: none;
            perspective-origin: 50% 50%;
            pointer-events: auto;
            position: static;
            quotes: none;
            resize: none;
            right: auto;
            size: auto;
            table-layout: auto;
            top: auto;
            transform: none;
            transform-origin: 50% 50% 0px;
            transform-style: flat;
            unicode-bidi: normal;
            vertical-align: baseline;
            white-space: normal;
            widows: 2;
            width: auto;
            word-break: normal;
            word-spacing: normal;
            overflow-wrap: normal;
            z-index: auto;
            text-align: start;
            -webkit-font-smoothing: antialiased;
            font-variant: normal;
            text-decoration: none;
            border-width: 0px;
            border-style: none;
            border-color: transparent;
            border-image: initial;
            border-radius: 0px;
            list-style: outside none disc;
            margin: 0px;
            overflow: visible;
            padding: 0px;
            page-break-after: auto;
            page-break-before: auto;
            page-break-inside: auto;
          }
          .kol-popup-close-button {
            cursor: pointer;
            width: 14px;
            height: 14px;
            top: 5px;
            right: 0px;
            display: inline-block; /* To ensure it works inside <a> */
            position: absolute;
            background-color: transparent;
            border: none;
            text-decoration: none; /* Remove link underlines */
          }

          .kol-popup-close-button:before, .kol-popup-close-button:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0px;
            width: 2px;
            height: 100%;
            background: rgba(0, 0, 0, 0.8); /* For light backgrounds */
          }

          .kol-popup-close-button:before {
            transform: rotate(45deg);
          }

          .kol-popup-close-button:after {
            transform: rotate(-45deg);
          }

          /* Adding an outer glow for dark backgrounds */
          .kol-popup-close-button:before, .kol-popup-close-button:after {
            box-shadow: 0 0 2px #fff, 0 0 5px #fff, 0 0 10px #fff;
          }`
  }

  getPopupFrameMarkup (popup) {
    return `
    <div class="kol-popup-frame-holder">
    
    <div><style>

          .kol-popup-frame-holder{
            z-index: 2147483000;
            position: fixed;
            top: 0px;
            right: 0px;
            width: 100%;
            background:white;
            display:none;
            align-content: center;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            min-height: 250px;
            max-height: 750px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
            opacity: 1;
            height: 100%;
            border-radius: 8px;
            overflow: hidden;
          }

          .kol-popup-open .kol-popup-frame-holder{
            display: flex !important;
          }

          .kol-popup-closed .kol-popup-frame-holder{
            display: none !important;
          }

          .kol-popup-wrapper.kol-popup-open{
            background: rgba(0,0,0,0.5);
            width:100%;
            height:100%;
            z-index: 2147483000;
          }
           @media (min-width: 768px){
            .kol-popup-template-box .kol-popup-frame-holder{
              width: 720px;
              transform: translatex(50%);
              height: 500px;
              top: 20px;
              right:50%;

            }
           
          }


          ${this.getCommonPopupStyles()}
          </style></div>
          ${this.getCloseButtonMarkup(popup)}

      <iframe id="kol-popup-frame-${popup.pageId}" data-popup-page-id="${popup.pageId}" allow="clipboard-write" name="kol-popup-box-${popup.pageId}" style="width: 100% !important;height: 100% !important;" class="kol-popup-frame" ></iframe>
    </div>`
    // could also use option ala <h2 class="user-name">${options.name}</h2>
  }

  writePopupEmbedFrame (popup) {
    debug('Writing popup embedded Embed Frame')

    if (window._kol.options.embed) {
      // Select all frames with the class name matching 'kol-embed-page-frame-' + pageID
      const embedFrame = document.getElementById('kol-popup-frame-' + popup.pageId)

      // Build the URL with parameters
      const params = buildEmbedParams()

      // Set the source for each frame
      embedFrame.src = window._kol.options.embed.url + '/' + popup.pageId + '?' + params.toString()
      embedFrame.addEventListener('load', (event) =>
        this.handlePopupEmbedLoad(event)
      )
      embedFrame.setAttribute('data-kol-page-ready', 'true')
      embedFrame.setAttribute('allow', 'clipboard-write')
    }else {debug('No embed url options found')}
  }

  closePopupSetLastClosed (popup) {
    // Set the last closed time in local storage
    storage.setItem(`kola.${_kol.options.campaignId}.popupLastClosed.${popup.popupPageId}`, Date.now())
    // Hide the popup
    const popupWrapper = document.getElementById('kolPopupWrapper-' + popup.popupPageId)
    fadeOut(popupWrapper, 500)
    setTimeout(() => {
      popupWrapper.classList.remove('kol-popup-open')
      popupWrapper.classList.add('kol-popup-closed')
    }, 500)
  }

  extractNumberFromHref (href) {
    const match = href.match(/#kolOpenPopup-(\d+)/i) // Case-insensitive regex to match the pattern and capture the number
    if (match) {
      return match[1] // The captured group with the number
    }
    return null // Return null if no match was found
  }

  setupOpenCloseEvents () {
    const openSnippets = window._kol.container.document.querySelectorAll('a[href^="#kolOpenPopup-" i]')
    openSnippets.forEach((snippet) => {
      snippet.onclick = null
      snippet.onclick = (e) => {
        e.preventDefault()
        const popupPageId = this.extractNumberFromHref(e.target.href)
        if (!popupPageId) {
          return false
        }
        const openEvent = new CustomEvent('kol:open:PagePopup', { detail: { popupPageId } })
        // Don't run if KOL script isn't installed.
        window._kol.container.dispatchEvent(openEvent)
        return false
      }
    })

    const closeSnippets = window._kol.container.document.querySelectorAll("[href='#kolClosePopup' i], .kol-popup-close-button")

    closeSnippets.forEach((snippet) => {
      snippet.onclick = null
      snippet.onclick = (e) => {
        e.preventDefault()
        const popup = e.target.closest('.kol-popup-wrapper')
        const popupPageId = popup.getAttribute('data-popup-page-id')
        const closeEvent = new CustomEvent('kol:close:PagePopup', { detail: { popupPageId } })
        // Don't run if KOL script isn't installed.
        window._kol.container.dispatchEvent(closeEvent)
        return false
      }
    })

    window._kol.container.addEventListener(
      'kol:open:PagePopup',
      (event) => {
        this.openPopup(event.detail.popupPageId)
      }
    )
    window._kol.container.addEventListener(
      'kol:close:PagePopup',
      (event) => {
        this.closePopupSetLastClosed(event.detail)
      }
    )
  }
}
