import { kolDebug as debug } from './../utils/debugging'
import getActivityIconClasses from '../activities/network_icons'
import { extend } from 'lodash-es'
import { getNetworkShareURL } from '../activities/activity_url'
import { tryJSON } from '../utils/objects'
import { isMobile, isMobileOrTablet } from '../utils/devices'

export default class KOLShareButtons {
  constructor (writeDoc) {
    this.doc = writeDoc
    this.options = {}

    const myself = this

    this.handleBuildByEvent = function (event) {
      myself.build()
    }

    this.build()
    this.setupEventListeners()
  }

  setupEventListeners () {
    this.doc.removeEventListener(
      'kol:snippets:build:sharebuttons',
      this.handleBuildByEvent.bind(this),
      true
    )
    window.addEventListener(
      'kol:snippets:build:sharebuttons',
      this.handleBuildByEvent.bind(this)
    )
  }

  // post default social options to list options so we can read these?
  getSnippetOptions (snippet) {
    let sl_options = {}
    if (snippet) {
      sl_options = { // snippet.getAttribute("data-end-date");
        button_type: snippet.getAttribute('data-button_type'),
        networks: tryJSON(snippet.getAttribute('data-networks'), '[]')
      }
    }
    return sl_options
  }

  build () {
    const snippets = this.doc.querySelectorAll("[data-kol-snippet='sharebuttons']")

    const myself = this
    snippets.forEach((snippet, index) => {
      myself.options = extend({}, myself.default_settings(), _kol.options.social_defaults, myself.getSnippetOptions(snippet))
      let buttons = '<div class="kol-share-links kol-no-save-js">'
      buttons += this.generate_html({ social_url: _kol.lead.social_url })
      buttons += '</div>'
      const wrapper = this.doc.getElementById(snippet.getAttribute('id') + '_wrapper')
      if (wrapper) {
        wrapper.innerHTML = buttons
      } else {
        snippet.innerHTML = buttons
      }
    })
  }

  default_settings () {
    return {
      button_type: 'rounded',
      networks: []
    }
  }

  add_network (network, index) {
    let current_index, existing_network, i, len, new_network_pushed, new_networks, ref
    if (index == null) {
      index = -1
    }
    if (index === -1) {
      return this.options.networks.push(network)
    } else {
      new_networks = []
      new_network_pushed = false
      ref = this.options.networks
      for (current_index = i = 0, len = ref.length; i < len; current_index = ++i) {
        existing_network = ref[current_index]
        if (current_index === index) {
          new_network_pushed = true
          new_networks.push(network)
        }
        new_networks.push(existing_network)
      }
      if (!new_network_pushed) {
        new_networks.push(network)
      }
      return this.options.networks = new_networks
    }
  }

  network_url (network, social_url) {
    // for safety ensure options are there.

    network.network_options = network.network_options || {}
    const url = getNetworkShareURL(network, social_url)
    return url
  }

  add_network_overrides () {
    let i, len, network_info, ref

    if (this.options.network_overrides) {
      ref = this.options.network_overrides
      for (i = 0, len = ref.length; i < len; i++) {
        network_info = ref[i]
        this.add_network(network_info[0], network_info[1] || -1)
      }
    }
  }

  generate_html (data) {
    let escaped_social_url, html, i, len, network, network_info, ref
    this.add_network_overrides()
    escaped_social_url = data.social_url
    if (this.options.networks) {
      ref = this.options.networks
      for (i = 0, len = ref.length; i < len; i++) {
        network_info = ref[i]
        network_info = extend({}, network_info, this.network_urls(network_info, escaped_social_url))
        network_info.share_data = JSON.stringify({
          type: 'share',
          description: network_info.network
        })
        if (!network_info.icon) {
          network_info.icon = network_info.network
        }
        if (!network_info.device_type) {
          network_info.device_type = 'any'
        }
        if (network_info.network === 'telegram') {
          if (!network_info.icon) {
            network_info.icon = 'paper-plane'
          }
        }
        if(network_info.network === 'twitter') {
           network_info.icon = 'envelope'
        }
        if (network_info.network === 'email') {
          if (!network_info.icon) {
            network_info.icon = 'envelope'
          }
        } else {
          network_info.onclick = 'onclick="javascript:window.open(this.getAttribute(\'data-share-url\'), \'_blank\', \'width=800,height=500\');return false;"'
        }
        ref[i] = network_info
      }
      this.options.networks = this.extractNetworks()
      return html = this.link_template(this.options)
    }
  }

  extractNetworks () {
    let j, len1, ref1, results, network
    ref1 = this.options.networks
    results = []
    for (j = 0, len1 = ref1.length; j < len1; j++) {
      network = ref1[j]
      if (this.verify_device(network)) {
        results.push(network)
      }
    }
    return results
  }

  network_urls (network, escaped_social_url) {
    let url = null
    url = this.network_url(network, escaped_social_url)

    if (network.network === 'twitter') {
      return {
        href: '#',
        share_url: url
      }
    } else {
      return {
        href: url,
        share_url: url
      }
    }
  }

  verify_device (network) {
    switch (network.device_type) {
      case 'any':
        return true
      case 'mobile':
        return isMobile()
      case 'tablet':
        return isMobileOrTablet()
      default:
        return true
    }
  }

  add_trailing_slash_for_whatsapp_because_it_is_not_really_2017 (url) {
    let new_url, url_parts
    url_parts = url.split('?')
    if (url_parts.length === 1) {
      return url
    } else if (url_parts.length === 2) {
      new_url = url_parts[0]
      if (!new_url.endsWith('/')) {
        new_url = new_url + '/'
      }
      return new_url + '?' + url_parts[1]
    }
  }

  link_template () {
    let btns = '<div id="kol_share_links">'
    let i, len
    const ref = this.options.networks
    for (i = 0, len = ref.length; i < len; i++) {
      btns += `<a class="kol-social-share-link kol_s_button kol-btn kol-btn-sharing kol-btn-${ref[i].network}" id="share_${ref[i].network}" data-share-url="${ref[i].share_url}" data-kol-track="true" data-kol="${ref[i].share_data}" href="${ref[i].href}" target="_top" class="kol_s_button" ${ref[i].onclick}>      <i class='${getActivityIconClasses(ref[i].network)}'></i> ${ref[i].text}    </a> `
    }

    btns += '</div>'

    return btns
  }
}
