import { oauthUrl } from '../utils/urls'

export default class KOLFacebookSignup {
  constructor (writeDoc) {
    const myself = this
    this.doc = writeDoc
    this.handleBuildByEvent = function (event) {
      myself.build()
    }

    this.build()
    this.setupEventListeners()
  }

  setupEventListeners () {
    this.doc.removeEventListener(
      'kol:snippets:build:facebook_signup',
      this.handleBuildByEvent.bind(this),
      true
    )
    window.addEventListener(
      'kol:snippets:build:facebook_signup',
      this.handleBuildByEvent.bind(this)
    )
  }

  build () {
    // first connect snippets to their buttons
    const snippets = this.doc.querySelectorAll(
      "[data-kol-snippet='facebook_signup']"
    )
    const myself = this
    snippets.forEach((snippet, index) => {
      const button = snippet.querySelector('.facebook-signup-btn')

      button.onclick = null

      button.onclick = (event) => {
        myself.facebookSignupClick(event)
      }
    })
    // Select all div elements with the class 'kol-activity-signup_facebook'
    const fbSignupElements = document.querySelectorAll('div.kol-activity-signup_facebook')
    // Attach the click event listener to each element
    fbSignupElements.forEach(element => {
      element.addEventListener('click', myself.facebookSignupClick.bind(myself))
    })
  }

  inIframe () {
    try {
      return window !== window.top
    } catch (e) {
      return true
    }
  }

  facebookSignupClick (buttonEvent) {
    const button = buttonEvent.currentTarget
    if (!document.body.classList.contains('kol-preview')) {
      const rootUrl = window.kol_oauth_options.root_url || window?._kol?.options?.oauth?.url
      const facebookUrl = oauthUrl(rootUrl, 'facebook')
      const url = new URL(facebookUrl)
      if (window.__kol_analytics) {
        // Scott how to do this.
        for (const [key, value] of Object.entries(
          window.__kol_analytics.getConversionData({})
        )) {
          if (key === '__custom') {
            url.searchParams.set('__custom_json', JSON.stringify(value))
          } else {
            url.searchParams.set(key, value)
          }
        }
      }
      if (this.inIframe()) {
        url.searchParams.set('embedded', 'true')
        button.setAttribute('href', url.toString())
        button.setAttribute('target', '_top')
      } else {
        buttonEvent.preventDefault()
        window.open(url.toString())
      }
      return true
    } else {
      buttonEvent.preventDefault()
      const options = {}
      options.title = 'Signup Disabled in Designer'
      options.message =
        'When clicking this on the published page, your user will be redirected to Facebook to complete the signup.'
      if (
        !window._kol.container.KOLConfig.config[
          '#' + button.currentTarget.getAttribute('id') + '-shown-warning'
        ]
      ) {
        /* eslint no-new: "off" */
        new window._kol.container.DisabledDesignerAction({
          title: options.title,
          message: options.message
        })
        window._kol.container.KOLConfig.config[
          '#' + button.getAttribute('id') + '-shown-warning'
        ] = true
      }
    }
  }
}
