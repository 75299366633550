import { extend } from './utils/objects'
import KA from './analytics'
import { postData } from './utils/data'
import { kolDebug as debug } from './utils/debugging'
import { trigger, dispatchLeadChangeEvents } from './utils/events'
import { capturedFunctionNotificationName } from './utils/globals'

export default class KOLTag {
  constructor (optionOverrides = {}, analytics = null) {
    this.options = extend(
      {},
      this.defaultOptions,
      optionOverrides,
      KOLTag.globalOverrides()
    )
    this.analytics = analytics
    debug('Options From Tags', this.options)
  }

  defaultOptions = {
    tags: {
      url: 'https://tags.kickofflabs.com'
    }
  }

  static globalOverrides () {
    return window.kol_tag_options || {}
  }

  buildUrl (tagId, path) {
    return `${this.options.tags.url}/${this.options.campaignId}/${tagId}/${path}`
  }

  addReferral (args) {
    debug('#addReferral')
    const { tagId, email, kid = this.defaultKid() } = args
    if (email || kid) {
      // Can we just call /referral
      const url = this.buildUrl(tagId, 'referral')
      debug(`tagURL ${url}`)
      return postData(url, { email, kid }).then((response) => {
        debug('Tag Response', response)
        return response
      })
    }

    console.error('No kid was passed in and none could be derived')
    return false
  }

  defaultKid () {
    return this.analytics && this.analytics.user && this.analytics.user.kid
  }

  addLeadToTag (args) {
    debug('#send!')
    const { tagId, ...data } = args
    const conversionData = this.analytics.getConversionData()
    const apiData = extend({}, data, conversionData)
    debug(`Tag Data ${apiData}`)
    return this.executeAdd(tagId, apiData)
  }

  addLeadToTagOld (tagId, email) {
    debug('#sendOld')
    const conversionData = this.analytics.getConversionData()
    const apiData = extend({}, { email }, conversionData)
    debug(`Tag Data ${apiData}`)
    return this.executeAdd(tagId, apiData)
  }

  executeAdd (tagId, data) {
    debug('executeSend')
    const url = this.buildUrl(tagId, 'add')
    debug(`tagURL ${url}`)
    return postData(url, data)
      .then((response) => {
        debug('Tag Response', response)
        return response
      })
      .then((response) => {
        if (response.lead) {
          debug(`Triggering ${capturedFunctionNotificationName}`, response)
          trigger('body', capturedFunctionNotificationName, response.lead)
          dispatchLeadChangeEvents(response.lead)
        }
        return response
      })
  }
}
