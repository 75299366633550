import { isPreview as qsIsPreview, isEmbed as qsIsEmbed } from '../utils/query_strings'
export const modes = (mode) => {
  // modes
  // standard: DEFAULT Runtime
  // preview-lead:  This will set the lead as the not-anonymous preview
  // preview-anonymous: This will set the lead as
  //
  // designer-preview-anonymous: When you are designing landing pages

  const currentMode = qsIsPreview() ? 'preview-lead' : mode
  return {
    get mode () {
      return currentMode
    },
    get isEmbed () {
      return qsIsEmbed()
    },
    get isLeadPreview () {
      return currentMode === 'preview-lead'
    },
    get isAnonymousPreview () {
      return currentMode === 'preview-anonymous' || this.isDesigner
    },
    get isDesignerPreview () {
      return currentMode === 'designer-preview-anonymous'
    },
    get isStandard () {
      return currentMode === 'standard'
    },
    get isPreview () {
      return this.isLeadPreview || this.isAnonymousPreview || this.isDesignerPreview
    },
    get isDesigner () {
      // Only really used to never delete the form HTML. Also counts as a preview mode.
      return this.isDesignerPreview
    }
  }
}
