import KOLActivities from './activities'
import KOLRewards from './rewards'
import KOLLeadPropertiesText from './leadPropertiesText'
import KOLLeadHideShowClasses from './leadHideShowClasses'
import KOLPopups from './popups'
import KOLCountdown from './countdown'
import KOLTermsPopup from './terms-popup'
import KOLShareLinkCopy from './sharelinkcopy'
import { kolDebug as debug } from './../utils/debugging'
import KOLShareButtons from './sharebuttons'
import KOLOneBox from './onebox'
import KOLOneLeaderBoard from './leaderboard'
import KOLFacebookSignup from './facebook-signup'
import KOLGoogleSignup from './google-signup'
import KOLscrollTo from './scrollto'
import KOLOpenContestBoxLinks from './openContestBoxLinks'
import KOLCampaignCountdownEnd from './campaign-countdown-end'

export const buildSnippetUI = (options = null, analytics = null, onDocument = null) => {
  options = options || window._kol.options
  analytics = analytics || window._kol.analytics
  const writeDocument = onDocument || window.document
  debug('Building Snippets')
  /* eslint no-new: "off" */
  new KOLActivities(options, analytics, writeDocument)
  new KOLRewards(writeDocument)
  new KOLLeadPropertiesText(writeDocument)
  new KOLLeadHideShowClasses(writeDocument)
  new KOLPopups(writeDocument)
  new KOLTermsPopup(writeDocument)
  new KOLCountdown(options, writeDocument)
  new KOLShareLinkCopy(writeDocument)
  new KOLShareButtons(writeDocument)
  new KOLOneBox(writeDocument)
  new KOLOneLeaderBoard(writeDocument)
  new KOLFacebookSignup(writeDocument)
  new KOLGoogleSignup(writeDocument)
  new KOLscrollTo(writeDocument)
  new KOLOpenContestBoxLinks(writeDocument)
  new KOLCampaignCountdownEnd(options, writeDocument)

  debug('Completed Starting Snippets')
}
