import { fromEntries } from './objects'
export const getQS = (name) => {
  return window._kol.queryString[name]
}

export const parse = (location = window.location) => {
  return fromEntries(new URLSearchParams(location.search))
}

export const isEmbed = (location = window.location) => {
  if (window._kol.queryString) {
    return window._kol.queryString.__embed === 'true'
  } else {
    const url = new URL(location)
    return url.searchParams && url.searchParams.get('__embed') === 'true'
  }
}

export const isPreview = (location = window.location) => {
  if (window._kol.queryString) {
    return window._kol.queryString.preview === 'true'
  } else {
    const url = new URL(location)
    return url.searchParams && url.searchParams.get('preview') === 'true'
  }
}

export const buildEmbedParams = () => {
  // Build the URL with parameters
  const params = new URLSearchParams(location.search)
  const currentFullUrl = `${location.protocol}//${location.hostname}${location.pathname}`
  params.set('__url', currentFullUrl)
  params.set('__embed', 'true')
  if (document.referrer) {
    params.set('__ref', document.referrer)
  }
  if (!params.get('kolid') && window._kol.analytics.user.cid) {
    params.set('kolid', window._kol.analytics.user.cid)
  }
  if (window._kol.analytics.user.uid) {
    params.set('__uid', window._kol.analytics.user.uid)
  }
  if (window._kol.analytics.user.sid) {
    params.set('__sid', window._kol.analytics.user.sid)
  }
  return params
}
