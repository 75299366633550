export default class KOLCountdownUtilities {

  static endOnCountDownEnd() {
    return window._kol.options["countdown"] && window._kol.options["countdown"]["end_on_countdown_end"] == true;
  }

  static acceptNewSubscriptionsAndScoring(){
    return  !(this.endOnCountDownEnd() && this.hasCountdownEnded());
  }

  
  static hasCountdownEnded() {

    
    let endDate, utcOffSet;



    if (_kol.options["countdown"] && _kol.options["countdown"]["end_date"]) {
      endDate = _kol.options["countdown"]["end_date"];
    }

    if ( _kol.options["countdown"] && _kol.options["countdown"]["utc_offset"]) {
      utcOffSet = _kol.options["countdown"]["utc_offset"];
    }

    if (endDate && utcOffSet) {
      endDate = new Date(endDate.replace(/-/g, "/"));
      let utcEndDate = this.getUTCDate(
        endDate,
        utcOffSet
      );
      const remaining = this.getTimeRemaining(utcEndDate);
      if (remaining.total <= 0) {
        return true;
      }
      else {
        return false;
      }

    }
    else {
      return false;
    }

  }

  static getTimeRemaining(endtime) {
    const total = Math.max(0,Date.parse(endtime) - Date.parse(new Date()));
    const seconds = Math.max(0,Math.floor((total / 1000) % 60));
    const minutes = Math.max(0,Math.floor((total / 1000 / 60) % 60));
    const hours = Math.max(0,Math.floor((total / (1000 * 60 * 60)) % 24));
    const days = Math.max(0,Math.floor(total / (1000 * 60 * 60 * 24)));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  }

  static getUTCDate(endDate, utcOffSet) {
    if (isNaN(utcOffSet)) {
      return endDate;
    }

    let ms, secs, mins, hours, day, month, year;
    if (typeof endDate === "object" && endDate instanceof Date) {
      ms = endDate.getMilliseconds();
      secs = endDate.getSeconds();
      mins = endDate.getMinutes();
      hours = endDate.getHours();
      day = endDate.getDate();
      month = endDate.getMonth();
      year = endDate.getFullYear();
    }

    var d = new Date();
    d.setUTCFullYear(year);
    d.setUTCDate(1);
    d.setUTCMonth(month || 0);
    d.setUTCDate(day || 1);
    d.setUTCHours(hours || 0);
    d.setUTCMinutes(
      (mins || 0) - (Math.abs(utcOffSet) < 30 ? utcOffSet * 60 : utcOffSet)
    );
    d.setUTCSeconds(secs || 0);
    d.setUTCMilliseconds(ms || 0);
    return d;
  }


}