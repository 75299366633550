import KOLCountdownUtilities from '../utils/countdown-utilities'

export default class KOLCampaignCountdownEnd {
  constructor (options, writeDoc) {
    this.options = options
    this.doc = writeDoc

    if (window._kol.options.countdown && window._kol.options.countdown.end_on_countdown_end == true) {
      const myself = this
      this._handleCountdownEnd = function () {
        myself.replaceKOLForms()
      }

      if (!KOLCountdownUtilities.acceptNewSubscriptionsAndScoring()) {
        myself.replaceKOLForms()
      }

      this.setupEventListeners()
    }
  }

  replaceKOLForms () {
    if (window._kol.options.countdown && window._kol.options.countdown.end_message) {
      const message = document.createElement('p')
      message.innerHTML = '<p>' + window._kol.options.countdown.end_message + '</p>'
      const forms = this.doc.querySelectorAll('.kol-signup-form ')
      forms.forEach((form) => {
        form.replaceWith(message)
      })
    }
  }

  setupEventListeners () {
    window.removeEventListener('kol:campaign:countdown:end', this._handleCountdownEnd, true)
    window.addEventListener('kol:campaign:countdown:end', this._handleCountdownEnd)
  }
}
