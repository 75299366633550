import { kolWarn as warn, kolDebug as debug } from "./utils/debugging";

const logOut = (id = null) => {
  const campaignId = id || window._kol.options.campaignId
  if (campaignId) {
    debug(`Logging out campaign id ${campaignId}`)
    localStorage.removeItem(`kola.${campaignId}.options.data`);
    localStorage.removeItem(`kola.${campaignId}.notificationLastSeen`);
    debug(`Local storage removed for ${campaignId}`)

    document.cookie = `kola.${campaignId}=;expires=Thu, 21 Sep 1979 00:00:01 UTC;path=/`;
    document.cookie = `kola.${campaignId}.session=;expires=Thu, 21 Sep 1979 00:00:01 UTC;path=/`;
    document.cookie = `kola.${campaignId}.cid=;expires=Thu, 21 Sep 1979 00:00:01 UTC;path=/`;
    debug(`Cookies removed for ${campaignId}`)
    const  event = new CustomEvent("kol:logout", {
      bubbles: true,
      detail: {},
    });
    window.document.dispatchEvent(event);
    debug("Logout Event Dispatched")
  } else {
    warn("Logout called with no CampaignId available");
  }
}

export default logOut;
