import { kolDebug as debug } from './debugging'
import KOLBrowsers from '../utils/browsers'

export const dispatchLeadConversionEvents = (lead) => {
  debug('dispatchLeadConversionEvents', lead)

  const leadConversionEvent = new CustomEvent('kol:leadConversion', {
    detail: { lead },
    bubbles: true
  })
  window.dispatchEvent(leadConversionEvent)

  const iframe = document.querySelector('#kol-contestbox-contest-frame')
  if (iframe) {
    iframe.contentWindow.dispatchEvent(leadConversionEvent)
  }
  return lead
}

export const dispatchLeadChangeEvents = (lead) => {
  debug('dispatchLeadChangeEvents', lead)

  const leadChangeEvent = new CustomEvent('kol:leadchange', {
    detail: { lead },
    bubbles: true
  })
  window.dispatchEvent(leadChangeEvent)
  // pass lead changed to parent window
  if (KOLBrowsers.isInIframe()) {
    const message = {
      type: 'kol:leadchange',
      data: lead
    }
    window.top.postMessage(message, '*')
  }

  // delete for 3.0.0 scripts
  const iframe = document.querySelector('#kol-contestbox-contest-frame')
  if (iframe) {
    iframe.contentWindow.dispatchEvent(leadChangeEvent)
  }

  const knownLeadEvent = new CustomEvent('kol:knownlead', {
    detail: { lead },
    bubbles: true
  })
  window.dispatchEvent(knownLeadEvent)
  return lead
}

export const triggerContestBox = (lead, message = 'Activating ContestBox') => {
  debug('ContestBox#triggerContestBox')
  const event = new CustomEvent('kol:activate:ContestBox', {
    bubbles: true,
    detail: {
      lead,
      message
    }
  })
  if (KOLBrowsers.isInIframe()) {
    const message = {
      type: 'kol:activate:ContestBox',
      data: lead
    }
    window.top.postMessage(message, '*')
  } else {
    window._kol.container.dispatchEvent(event)
  }
}

export const onSubSelector = (
  parentSelector,
  childSelector,
  eventType,
  callbackFunction
) => {
  // eslint-disable-line import/prefer-default-export, max-len
  const selectors = document.querySelector(parentSelector)
  if (selectors) {
    selectors.addEventListener(eventType, (event) => {
      if (event.target.matches(childSelector)) {
        return callbackFunction(event)
      }
      return null
    })
  } else {
    debug(`Could not find ${parentSelector}`)
  }
}

export const ready = (fn, message = null) => {
  if (message) debug(`READY: ${message}`)

  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      fn()
    })
  }
}

export const trigger = (selector, eventName, data) => {
  let event = null
  if (typeof window.CustomEvent === 'function') {
    event = new CustomEvent(eventName, { detail: data })
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, true, true, data)
  }

  const el = document.querySelector(selector)
  if (el) {
    el.dispatchEvent(event)
  }
}

export const on = (elementSelector, eventName, eventHandler) => {
  ready(() => {
    document.addEventListener(
      eventName,
      (theEvent) => {
        // loop parent nodes from the target to the delegation node
        for (
          let target = theEvent.target;
          target && target != this;
          target = target.parentNode
        ) {
          if (target.matches && target.matches(elementSelector)) {
            eventHandler.call(target, theEvent)
            break
          }
        }
      },
      false
    )
  })
}
