import { isMobileOrTablet } from '../utils/devices'
import getFullActivityObjectById from '../activities/activity_helpers'

const replaceLeadData = (url) => {
  if (!_kol.lead.anonymous) {
    const pupa = require('pupa')
    const urlReplaced = pupa(url, _kol.previousFormData || [])
    return urlReplaced
  } else {
    return url
  }
}

const getActivityUrl = (actions, activity) => {
  // TODO: Need to replace the URL on share items with actual share URL
  const activityObject = getFullActivityObjectById(
    actions,
    activity.dataset.kolActivityId
  )
  let url = ''
  if (
    activity.dataset.kolActivityUrl &&
    activity.dataset.kolActivityUrl !== 'null' &&
    activity.dataset.kolActivityUrl !== 'undefined' // Need to track down where null is set to a string
  ) {
    // url = replaceLeadData(activity.dataset.kolActivityUrl);
    if (activity.dataset.kolActivityUrl.indexOf('?') == -1) {
      url = activity.dataset.kolActivityUrl + '?kid=' + window._kol.lead.social_id
    } else {
      url = activity.dataset.kolActivityUrl + '&kid=' + window._kol.lead.social_id
    }
  } else {
    url = window._kol.lead.social_url
  }

  let activityObjectData = {}
  let shareText = 'Check this out... '
  if (activityObject) {
    activityObjectData = activityObject.data
    if (activityObject.data && activityObject.data.shareText) {
      shareText = activityObject.data.shareText
    }
  }
  const networkName = activity.dataset.kolActivityType.replace('refer_', '').replace('share_', '')
  const networkData = {
    network: networkName,
    text: shareText,
    ...activityObjectData
  }
  return getNetworkShareURL(networkData, url)
}

const getNetworkShareURL = (network, url) => {
  const social_defaults = window._kol.options.social_defaults
  const pupa = require('pupa')

  const normalized_share_text = pupa(network.shareText || network.share_text || social_defaults.twitter_share_text, window._kol.lead || [])

  switch (network.network) {
    case 'facebook':
      return 'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(url)
    case 'fbmessenger':
    case 'messenger':
      if (isMobileOrTablet()) {
        return 'fb-messenger://share/?link=' + encodeURIComponent(url) + '&app_id=117393808353070'
      } else {
        return (
          'http://www.facebook.com/dialog/send?redirect_uri=' +
         encodeURIComponent(url) +
        '&app_id=117393808353070&link=' +
         encodeURIComponent(url)
        )
      }

    case 'twitter':

      return 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url) + '&text=' + (encodeURIComponent(normalized_share_text)) + '&amp;source=webclient'
    case 'linkedin':
      return 'http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url)
    case 'send_emailsend':
      return 'mailto:?subject=' + (encodeURIComponent(getEmailSubject(network)) + '&body=' + (encodeURIComponent(pupa(getEmailBody(network), window._kol.lead))))
    case 'email':
      return 'mailto:?subject=' + (encodeURIComponent(getEmailSubject(network)) + '&body=' + (encodeURIComponent(getEmailBody(network))) + '%20' + encodeURIComponent(url))
    case 'pinterest':
      return 'https://pinterest.com/pin/create/button/?url=' + encodeURIComponent(url) + '&media=' + (encodeURIComponent(social_defaults.pinterest_share_image_url || network.share_image || social_defaults.share_image))
    case 'reddit':
      return 'https://www.reddit.com/submit?url=' + encodeURIComponent(url) + '&title=' + (encodeURIComponent(network.reddit_title || social_defaults.facebook_title))
    case 'tumblr':
      return 'http://tumblr.com/widgets/share/tool?canonicalUrl=' + encodeURIComponent(url) + '&posttype=link&title=' + (encodeURIComponent(network.tumblr_title || social_defaults.facebook_title)) + '&caption=' + (encodeURIComponent(network.tumblr_caption || social_defaults.facebook_description))
    case 'digg':
      return 'http://www.digg.com/submit?url=' + encodeURIComponent(url)
    case 'wordpress':
      return 'http://wordpress.com/press-this.php?u=' + encodeURIComponent(url)
    case 'whatsapp':

      return 'https://api.whatsapp.com/send?text=' + (encodeURIComponent(normalized_share_text) + '%20' + encodeURIComponent(addTrailingSlashForWhatsApp(url)))
    case 'telegram':
      return 'https://telegram.me/share/url?url=' + encodeURIComponent(url) + '&text=' + (encodeURIComponent(normalized_share_text))
    case 'weibo':
      return 'http://service.weibo.com/share/share.php?url=' + encodeURIComponent(url) + '&appkey=&title=' + (encodeURIComponent(normalized_share_text) + '%20' + url) + '&pic=&ralateUid=&language=' + (network.language || 'zh_cn')
    case 'sms':
      return 'sms:?&body=' + (encodeURIComponent(normalized_share_text) + '%20' + encodeURIComponent(url))
    case 'subscribe_youtube':
      return url + '&sub_confirmation=1'
    default:
      return url
  }
}

const getEmailSubject = (network) => {
  const social_defaults = window._kol.options.social_defaults
  let noSubject = ''
  if (network.network_options) {
    noSubject = network.network_options.subject
  }
  const subject = network.subject || network.emailSubject || noSubject || social_defaults.email_share_subject || 'Check this out!'
  return subject
}

const getEmailBody = (network) => {
  const social_defaults = window._kol.options.social_defaults
  let noBody = ''
  if (network.network_options) {
    noBody = network.network_options.body
  }
  const body = network.body || network.emailBody || noBody || social_defaults.email_share_body || 'I just found this!'
  return body
}

const addTrailingSlashForWhatsApp = (url) => {
  let new_url, url_parts
  url_parts = url.split('?')
  if (url_parts.length === 1) {
    return url
  } else if (url_parts.length === 2) {
    new_url = url_parts[0]
    if (!new_url.endsWith('/')) {
      new_url = new_url + '/'
    }
    return new_url + '?' + url_parts[1]
  }
}

export { getActivityUrl, getNetworkShareURL }
