import { kolWarn as warn, clearState, quickClearState } from './utils/debugging'
import { getQS, parse } from './utils/query_strings'
import { VERSION } from './utils/version'
import { extend } from './utils/objects'
import KOL from './kol'

const output = (message) => {
  const messageFN = warn || console.log
  messageFN(message)
}

const isDebuggingEnabled = (kolDebug) => {
  return (kolDebug === 'true') ||
      (getQS('koldebug') === 'true') ||
      (window.kolOptions ? window.kolOptions.debug : false)
}

const kolInstanceOptions = () => {
  const kolScriptBlock = KOL.fetchKolScriptBlock()
  if (kolScriptBlock?.dataset?.campaignId) {
    return extend({}, kolScriptBlock.dataset, { kolSrc: kolScriptBlock.src })
  } else if (window?.kolOptions?.campaignId) {
    return window.kolOptions
  } else {
    output('No KOL script block could be found')
    return {}
  }
}

if (!window._kol?.instance) {
  window.KOL = KOL
  window._kol = window._kol || {}
  window._kol.container = window._kol.container || window
  window._kol.version = VERSION
  window._kol.queryString = parse()

  const { campaignId, env, cookieDomain, kolSrc, debug: kolDebug } = kolInstanceOptions()
  if (campaignId || (window?.kolOptions?.useEnv)) {
    window._kol.src = kolSrc
    window._kol.env = env
    window._kol.cookieDomain = cookieDomain
    window._kolDebuggingEnabled = isDebuggingEnabled(kolDebug)
    window._kol.instance = new KOL(campaignId, window.kolOptions)
    window._kol.clearState = clearState

    if (env === 'development' || env === 'staging') {
      quickClearState()
    }
  } else {
    const message =
      'KOL.js did not create _kol variable. Element with id koljs and attribute data-campaign-id were not found'
    output(message)
  }
} else {
  output('KOL.js instance already exists')
}
