const debugLoader = (shouldDebug = false) => {
  if (shouldDebug) {
    return (message, data) => {
      if (data) {
        console.log(message, data)
      } else {
        console.log(message)
      }
    }
  }
  return () => {}
}

export const kolDebug = (message, data) => {
  if (window._kol.container._kolDebuggingEnabled) {
    if (data) {
      console.log(message, data)
    } else {
      console.log(message)
    }
  }
}

export const kolWarn = (message, data) => {
  if (data) {
    console.warn(message, data)
  } else {
    console.warn(message)
  }
}

export const kolDebugTable = (data) => {
  if (window._kol.container._kolDebuggingEnabled) {
    if (console.table) {
      console.table(data)
    } else {
      console.log(data)
    }
  }
}

export const clearState = () => {
  localStorage.clear()
  sessionStorage.clear()

  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const spcook = cookies[i].split('=')
    document.cookie = spcook[0] + '=;expires=Thu, 21 Sep 1979 00:00:01 UTC;path=/'
  }

  kolDebug('State Cleared')
  window.location.reload()
}

export const quickClearState = () => {
  const buttonContainer = document.createElement('div')
  buttonContainer.id = 'floatingButtonContainer'
  document.body.appendChild(buttonContainer)

  // Create the button
  const button = document.createElement('button')
  button.id = 'floatingButton'
  button.textContent = 'Clear State'
  button.onclick = _kol.clearState
  buttonContainer.appendChild(button)

  // Create and append the styles
  const style = document.createElement('style')
  style.textContent = `
  #floatingButtonContainer {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
  }

  #floatingButton {
    /* Additional styles for the button */
  }
`
  document.head.appendChild(style)
}

export default debugLoader
