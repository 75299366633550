export const oauthUrl = (url, network) => {
  // Set default value if url is null or empty
  if (!url) {
    url = 'https://app.kickofflabs.com/auth/'
  }

  // Check if url ends with the network parameter
  if (!url.endsWith(network)) {
    // If url doesn't end with '/', append it
    if (!url.endsWith('/')) {
      url += '/'
    }
    // Append the network parameter
    url += network
  }

  // Return the modified url
  return url
}
