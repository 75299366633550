import { kolWarn as warn, kolDebug as debug } from './utils/debugging'

const computeComponentName = (component) => {
  return typeof (component) === 'string' ? component : (component.name || component.constructor.name)
}

const eqSet = (as, bs) => {
  if (as.size !== bs.size) return false
  for (const a of as) if (!bs.has(a)) return false
  return true
}

export default class ComponentRegister {
  static component_ready (component) {
    const componentName = computeComponentName(component)
    debug(`Component Ready: ${componentName}`)
    const evt = new CustomEvent('kol:register:component', { detail: { component } })
    window.dispatchEvent(evt)
  }

  constructor (components = []) {
    this.required_components = new Set()
    this.registered_components = new Set()
    this.ready = false
    window.addEventListener('kol:register:component', (event) => {
      const componentName = computeComponentName(event.detail.component)
      debug(`Component Received: ${componentName}`)
      if (this.required_components.has(componentName)) {
        this.registered_components.add(componentName)
        if (!this.ready && eqSet(this.required_components, this.registered_components)) {
          this.ready = true
          const evt = new CustomEvent('kol:ready', { detail: true })
          window.dispatchEvent(evt)
          debug('KOL:Ready fired!')
        }
      } else {
        warn(`Component Received ${componentName} but was not registered`)
      }
    })

    components.forEach(component => {
      const componentName = computeComponentName(component)
      debug(`Registering ${componentName}`)
      this.required_components.add(componentName)
    })
  }
}
