import Cookies from "./utils/cookies";
import { postData } from "./utils/data";
import { extend } from "./utils/objects";
import { dispatchLeadChangeEvents, triggerContestBox } from "./utils/events";
import { kolDebug as debug } from "./utils/debugging";
const addCustomFieldsToCart = (campaignId) => {
  if (campaignId && window.location.pathname === "/cart") {
    const form = document.querySelector("form.cart");
    if (form) {
      const campaignIdInput = document.createElement("input");
      campaignIdInput.setAttribute("type", "hidden");
      campaignIdInput.setAttribute("name", "attributes[campaignId]");
      campaignIdInput.setAttribute("value", campaignId);
      form.appendChild(campaignIdInput);

      const kid = Cookies.get(`kola.${campaignId}.social_id`);
      if (kid) {
        const kidInput = document.createElement("input");
        kidInput.setAttribute("type", "hidden");
        kidInput.setAttribute("name", "attributes[kid]");
        kidInput.setAttribute("value", kid);
        form.appendChild(kidInput);
      }
    }
  }
};

const captureShopifyCheckout = () => {
  if (!window.Shopify || !window.Shopify.checkout) {
    debug("No Shopify data found");
    return;
  }
  debug("Processing Shopify Checkout");

  const defaultOptions = {
    shopify: {
      url: "https://app.kickofflabs.com/__shopify",
    },
  };

  const options = extend(
    {},
    defaultOptions,
    window._kol.options,
    window.kol_shopify_options || {}
  );

  const kid = Cookies.get(`kola.${options.campaignId}.social_id`);
  const {
    email,
    phone: phone_number,
    order_id,
    customer_id,
    total_price,
    subtotal_price,
  } = Shopify.checkout;

  //credit_card: { first_name, last_name }
  const shopifyDemoData =
    Shopify.checkout.credit_card ||
    Shopify.checkout.shipping_address ||
    Shopify.checkout.billing_address;
  const first_name = shopifyDemoData && shopifyDemoData.first_name;
  const last_name = shopifyDemoData && shopifyDemoData.last_name;
  const kolData = {
    kid,
    campaignId: options.campaignId,
    email,
    phone_number,
    order_id,
    customer_id,
    total_price,
    subtotal_price,
    first_name,
    last_name,
  };

  try {
    debug("Sending data to KickoffLabs");
    return postData(options.shopify.url, kolData)
      .then(dispatchLeadChangeEvents)
      .then((lead) => {
        if (_kol.options.onPageActions.contestBox.mode !== "off") {
          triggerContestBox(lead, "From Shopify");
        }
        return lead;
      });
  } catch (error) {
    console.error(error);
  }
};

export { captureShopifyCheckout, addCustomFieldsToCart };
