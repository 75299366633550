export default class NetworkScripts {
  constructor (doc) {
    if (!window._kol.mode.isDesignerPreview) {
      this.writeToDoc = doc
      const networksNeeded = this.getNetworksRequired()
      const networks = Array.isArray(networksNeeded)
        ? networksNeeded
        : Array.from(networksNeeded)
      const self = this
      if (Array.isArray(networks)) {
        networks.forEach((network) => {
          if (network in self) {
            this[network]()
          }
        })
      }
    }
  }

  getNetworksRequired () {
    const actions = window?.kolOptions?.actions || window._kol.options.actions
    const networks = new Set()
    if (Array.isArray(actions)) {
      actions.forEach((activity) => {
        if (activity.enabled !== false) {
          switch (activity.type) {
            case 'retweet_twitter':
            case 'like_twitter':
            case 'follow_twitter':
              networks.add('twitter')
              break
            case 'view_post_facebook':
              networks.add('facebook')
              break
            case 'view_post_instagram':
              networks.add('instagram')
              break
            case 'view_tiktok':
              networks.add('tiktok')
              break
            case 'follow_linkedin':
              networks.add('linkedin')
              break
            case 'upload_file':
            case 'upload_instagram':
            case 'upload_webcam':
              networks.add('filestack')
              break
            default:
              break
          }
        }
      })
    }
    return networks
  }

  pushActivityDoneEvent (activity) {
    const evt = new CustomEvent('kol:activity-done', { bubbles: true, detail: { activity } })
    activity.dispatchEvent(evt)
  }

  filestack () {
    (function (d) {
      const id = 'filestack'
      const ref = d.getElementsByTagName('script')[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement('script')
      js.id = id
      js.async = true
      js.src = 'https://static.filestackapi.com/filestack-js/3.x.x/filestack.min.js'
      ref.parentNode.insertBefore(js, ref)
    })(this.writeToDoc)
  }

  tiktok () {
    (function (d) {
      const id = 'tiktok-embed'
      const ref = d.getElementsByTagName('script')[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement('script')
      js.id = id
      js.async = true
      js.src = 'https://www.tiktok.com/embed.js'
      ref.parentNode.insertBefore(js, ref)
    })(this.writeToDoc)
  }

  linkedin () {
    (function (d) {
      const id = 'linkedin-jssdk'
      if (d.getElementById(id)) {
        return
      }
      const ref = d.getElementsByTagName('script')[0]
      const js = d.createElement('script')
      js.id = id
      js.async = true
      js.src = 'https://platform.linkedin.com/in.js'
      ref.parentNode.insertBefore(js, ref)
    })(this.writeToDoc)
    if (window.IN) {
      window.IN.parse()
    }
  }

  instagram () {
    (function (d) {
      const id = 'instagram-jssdk'
      const ref = d.getElementsByTagName('script')[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement('script')
      js.id = id
      js.async = true
      js.src = '//www.instagram.com/embed.js'
      ref.parentNode.insertBefore(js, ref)
    })(this.writeToDoc)
    // Re-initialize Instagram embeds
    if (window.instgrm) {
      window.instgrm.Embeds.process()
    }
  }

  facebook () {
    (function (d) {
      const id = 'facebook-jssdk'
      const ref = d.getElementsByTagName('script')[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement('script')
      js.id = id
      js.async = true
      js.src =
        'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0&appId=117393808353070&autoLogAppEvents=1'
      ref.parentNode.insertBefore(js, ref)
    })(this.writeToDoc)
  }

  twitter () {
    const felement = this.writeToDoc.body.children[0]
    const doc = felement.ownerDocument
    const win = doc.defaultView || doc.parentWindow

    win.twttr = (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      const t = win.twttr || {}
      if (d.getElementById('twitter-wjs')) return t
      const js = d.createElement(s)
      js.id = id
      js.src = 'https://platform.twitter.com/widgets.js'
      fjs.parentNode.insertBefore(js, fjs)

      t._e = []
      t.ready = function (f) {
        t._e.push(f)
      }

      return t
    })(this.writeToDoc, 'script', 'twitter-wjs')
    // Wait for the asynchronous resources to load
    const activityObject = this

    win.twttr.ready(function (twttr) {
      // Now bind our custom intent events
      // twttr.events.bind('click', clickEventToAnalytics);
      twttr.widgets.load()
      twttr.events.bind('tweet', (event) => {
        const activity = event.target.closest('.kol-activity')

        activityObject.pushActivityDoneEvent(activity)
      })

      twttr.events.bind('retweet', (event) => {
        const activity = event.target.closest('.kol-activity')
        activityObject.pushActivityDoneEvent(activity)
      })

      twttr.events.bind('like', (event) => {
        const activity = event.target.closest('.kol-activity')
        activityObject.pushActivityDoneEvent(activity)
      })

      twttr.events.bind('follow', (event) => {
        const activity = event.target.closest('.kol-activity')
        activityObject.pushActivityDoneEvent(activity)
      })
    })
  }
}
