import uuid from './utils/uuid'
import Cookies from './utils/cookies'
import { getQS } from './utils/query_strings'

const uniqueId = (campaignId) => {
  const cookieKey = `kola.${campaignId}`
  let uid = getQS('__uid') || Cookies.get(cookieKey)
  if (!uid) {
    uid = uuid()
    Cookies.set(cookieKey, uid, { sameSite: 'strict' })
  }
  return uid
}

const sessionId = (campaignId) => {
  const cookieKey = `kola.${campaignId}.session`
  let sid = getQS('__sid') || Cookies.get(cookieKey)
  if (!sid) {
    sid = uuid()
    Cookies.session(cookieKey, sid)
  }
  return sid
}

const conversionId = (campaignId) => {
  const cookieKey = `kola.${campaignId}.cid`
  const cid = getQS('kolid') || Cookies.get(cookieKey)
  if (cid) {
    Cookies.set(cookieKey, cid)
  }
  return cid
}

const socialId = (campaignId, defaultDomain) => {
  const cookieKey = `kola.${campaignId}.social_id`
  const sid =
    getQS('kid') ||
    Cookies.get(cookieKey) ||
    (defaultDomain && socialIdFromPath())
  if (sid) {
    Cookies.set(cookieKey, sid)
  }
  return sid
}

const currentUrl = () => getQS('__url') || window.location.toString()

const currentReferral = () => getQS('__ref') || document.referrer

const isIframe = () => (window._kol.container === window ? 0 : 1)

const socialIdFromPath = () => window?.location?.pathname?.substring(1)

const createUser = (options) => {
  const { campaignId, source } = options
  const { custom = {}, defaultDomain = false } = options.analytics
  const user = {
    rid: uuid(),
    uid: uniqueId(campaignId),
    sid: sessionId(campaignId),
    cid: conversionId(campaignId),
    kid: socialId(campaignId, defaultDomain),
    url: currentUrl(),
    ref: currentReferral(),
    lid: campaignId,
    language: navigator && navigator.language,
    custom,
    source,
    if: isIframe()
  }
  return user
}

export default createUser
