import { tryJSON } from "../utils/objects";

export default class KOLOneBox {
  constructor(writeDoc) {
    let myself = this;
    this.doc = writeDoc;
    this.handleBuildByEvent = function (event) {
      myself.build();
    };

    this.build();
    this.setupEventListeners();
  }

  setupEventListeners() {
    this.doc.removeEventListener(
      "kol:snippets:build:onebox",
      this.handleBuildByEvent.bind(this),
      true
    );
    window.addEventListener(
      "kol:snippets:build:onebox",
      this.handleBuildByEvent.bind(this)
    );

    window.removeEventListener(
      "kol:activity-marked-done",
      this.handleBuildByEvent.bind(this),
      true
    );
    window.addEventListener(
      "kol:activity-marked-done",
      this.handleBuildByEvent.bind(this)
    );

    window._kol.container.removeEventListener(
      "kol:leadchange",
      this.handleBuildByEvent,
      true
    );
    window._kol.container.addEventListener(
      "kol:leadchange",
      this.handleBuildByEvent
    );
  }

  build() {
    let snippets = this.doc.querySelectorAll("[data-kol-snippet='onebox']");

    snippets.forEach((snippet, index) => {
      let innerWrapper = snippet.querySelector(".kol-one-box-wrapper-inner"); // or .kol-copy-and-paste-sharelink
      let options = tryJSON(snippet.getAttribute("data-settings"), {});
      if (typeof _kol.lead[options.boxData] !== "undefined") {
        options.number = _kol.lead[options.boxData];
      } else {
        options.number = "-";
      }

      if (options.style && this["layout_" + options.style]) {
        if (innerWrapper) {
          innerWrapper.innerHTML = this["layout_" + options.style](options);
        } else {
          snippet.innerHTML = this["layout_" + options.style](options);
        }
      }
    });
  }

  layout_circular(options) {
    return `
      <section class="kol-score-counter kol-one-box-wrapper circular" style="border-color: ${options.borderColor}; border-width: ${options.borderWidth}px; background: ${options.bgColor};">
        <div class="score">
          <div class="score-points kol-no-save-js font-96-px" style="color: ${options.textColor};">${options.number}</div>
          <div class="score-text font-22-px" style="color: ${options.textColor};">${options.points_word}</div>
        </div>
      </section>
    `;
  }

  layout_square(options) {
    return `
      <section class="kol-score-counter kol-one-box-wrapper square" style="border-color: ${options.borderColor}; border-width: ${options.borderWidth}px; background: ${options.bgColor};">
        <div class="score">
          <div class="score-points kol-no-save-js font-96-px" style="color: ${options.textColor};">${options.number}</div>
          <div class="score-text font-22-px" style="color: ${options.textColor};">${options.points_word}</div>
        </div>
      </section>
    `;
  }

  layout_rounded(options) {
    return `
      <section class="kol-score-counter  kol-one-box-wrapper rounded" style="border-color: ${options.borderColor}; border-width: ${options.borderWidth}px; background: ${options.bgColor};">
        <div class="score">
          <div class="score-points kol-no-save-js font-96-px" style="color: ${options.textColor};">${options.number}</div>
          <div class="score-text font-22-px" style="color: ${options.textColor};">${options.points_word}</div>
        </div>
      </section>
    `;
  }
}
